import { FC, useState } from 'react'

import { useWatch } from 'react-hook-form'
import { styled } from 'styled-components'

import { Select } from 'core/components/legacy'
import { SelectInput, SelectOption } from 'core/components/lib/Select'
import { R } from 'core/helpers'
import { operations } from 'core/types'

import { SectionTitle } from './Section'

const APIMocksSection: FC = () => {
  const enabled = useWatch({ name: 'enableApiMocking' })

  const [endpoint, setEndpoint] = useState<SelectOption<keyof typeof operations> | null>(null)

  return enabled ?
      <>
        <StyledSectionTitle>Endpoints</StyledSectionTitle>
        <Select
          controlledValue={endpoint}
          getSelectionOption={setEndpoint}
          isSearchable
          menuPlacement='top'
          options={R.pipe(
            operations,
            R.values,
            R.map((data) => ({
              label: data.summary,
              sublabel: <Path>{data.path}</Path>,
              value: data.name,
            })),
          )}
        />
        <StyledSectionTitle>Response</StyledSectionTitle>
        {endpoint ?
          <SelectInput
            menuPlacement='top'
            name={endpoint.value}
            options={[
              { label: 'Disabled', value: null },
              ...R.pipe(
                {
                  'Bad Request': 400,
                  Forbidden: 403,
                  'Internal Server Error': 500,
                  'Service Unavailable': 503,
                  Timeout: 504,
                },
                R.toPairs,
                R.map(([label, status]) => ({ label, value: { status, message: `Mock ${status} response` } })),
              ),
            ]}
          />
        : 'Please select an endpoint.'}
      </>
    : null
}

export default APIMocksSection

const Path = styled.span`
  font-size: 12px;
`

const StyledSectionTitle = styled(SectionTitle)`
  margin-top: 16px;
`
