import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { isLoggedIn } from 'core/actions/helpers/tokenHelpers'
import useOnMount from 'core/hooks/useOnMount'
import { useStoredState } from 'core/hooks/useStoredState'
import { useUserType } from 'core/stores'

import { reconcileResourceList, _setMode } from './helpers'

const OpenResourceContext = createContext({})

const OpenResourceContextProvider = ({ children }) => {
  // User type
  const userType = useUserType()
  const isAgent = userType === 'agent'
  const isBorrower = userType === 'borrower'

  // Local state
  const [storedResourceList, setStoredResourceList] = useStoredState('storedResourceList')
  const [resourceList, setResourceList] = useState([])

  // Sorted resource list by user type
  const allowedResourceListByUserType = _.filter(storedResourceList, (resource) => resource.userType === userType)

  // Path identification
  const { pathname } = useLocation()
  const isCRM = pathname.includes('/crm')
  const isManagerDashboard = pathname.includes('/manager-dashboard')
  const isLoanApp = !isCRM

  // Resource display logic by user type and app location
  const canShowResources = (isAgent && isCRM && !isManagerDashboard) || (isBorrower && isLoanApp)

  useOnMount(() => {
    if (!_.isEmpty(allowedResourceListByUserType) && resourceList?.length === 0 && isLoggedIn() && canShowResources) {
      setResourceList(storedResourceList)
    }
  }, [resourceList, storedResourceList, setResourceList])

  const setMode = useCallback(
    ({ id, mode, type }) => {
      const prevResourceList = resourceList
      const newList = _setMode({ prevResourceList, id, mode, type })
      setResourceList(newList)
      setStoredResourceList(newList)
    },
    [resourceList, setStoredResourceList],
  )
  const onClose = useCallback(
    (id, type) => {
      const newResourceList = _.filter(resourceList, (item) => item.id !== id || item.type !== type)
      const resource = _.find(resourceList, (item) => item.id === id && item.type === type)
      if (resource?.onClose) {
        resource.onClose()
      }
      const newList = reconcileResourceList(newResourceList)
      setResourceList(newList)
      setStoredResourceList(newList)
    },
    [resourceList, setResourceList, setStoredResourceList],
  )

  /** id should be the id of the resource unique to its type, e.g. the database id.
   * type should be "interaction", "document", or "draftInteraction".
   * _onClose gets stored on the resource as onClose and is an optional function to
   * run when closing out the resource, e.g. removing a draftInteraction from
   * localStorage.
   */
  const openResource = useCallback(
    ({ _onClose, borrowerId, id, options, supercaseId, type }) => {
      if (!id) return
      const _openResource = (prevResourceList) => {
        const index = prevResourceList.findIndex((item) => item.id === id && item.type === type)
        const newResourceList = [...prevResourceList]
        if (index === -1) {
          newResourceList.push({
            borrowerId,
            id,
            mode: 'open',
            onClose: _onClose,
            options,
            supercaseId,
            type,
            userType,
          })
          return reconcileResourceList(newResourceList, newResourceList.length - 1)
        } else {
          return _setMode({ id, mode: 'open', type, prevResourceList })
        }
      }
      const prevResourceList = resourceList
      const reconciledList = _openResource(prevResourceList)
      setResourceList(reconciledList)
      setStoredResourceList(reconciledList)
    },
    [resourceList, setResourceList, setStoredResourceList, userType],
  )

  const updateResource = useCallback(
    ({ id, type, updated }) => {
      if (!id) return
      const index = resourceList.findIndex((item) => item.id === id && item.type === type)
      const newResourceList = [...resourceList]
      newResourceList[index] = { ...newResourceList[index], ...updated }
      setResourceList(newResourceList)
      setStoredResourceList(newResourceList)
    },
    [resourceList, setStoredResourceList],
  )

  /** channel must be one of "email", "text", "mail", or "voice" */
  const openDraftInteraction = useCallback(
    ({ associatedCase, borrowerId, channel, companyId, id, previousInteractions, supercaseId }) => {
      openResource({
        id,
        type: 'draftInteraction',
        borrowerId,
        supercaseId,
        associatedCase,
        channel,
        companyId,
        previousInteractions,
      })
    },
    [openResource],
  )

  const value = useMemo(
    () => ({
      openResource,
      openDraftInteraction,
      resourceList,
      setMode,
      onClose,
      updateResource,
    }),
    [openResource, openDraftInteraction, resourceList, setMode, onClose, updateResource],
  )

  return <OpenResourceContext.Provider value={value}>{children}</OpenResourceContext.Provider>
}

OpenResourceContextProvider.propTypes = {
  children: PropTypes.node,
}

export { OpenResourceContext }
export default OpenResourceContextProvider

export const useOpenResourceContext = () => useContext(OpenResourceContext)
