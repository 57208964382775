import { checkTimeordate } from 'core/time/internal/types'
import mtz from 'core/time/internal/utils/mtz'

export default function makeGetYear(context = {}) {
  const { timezone }: any = context

  return (timeordate: string) => {
    checkTimeordate(timeordate)
    return mtz(timeordate, timezone).year()
  }
}
