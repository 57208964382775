import { useState } from 'react'

import { getQueryParams } from 'core/helpers/queryParams'

export default function useSamlParams() {
  const [queryParams] = useState(() => getQueryParams() || {})
  const { samlUsername, samlPassword, errorType: samlErrorType, errorMessage: samlErrorMessage, redirect } = queryParams

  const immediatelyRedirect = redirect === 'immediate'
  const hasSamlCreds = samlUsername && samlPassword
  const hasSamlError = samlErrorType || samlErrorMessage

  return {
    hasSamlCreds,
    hasSamlError,
    immediatelyRedirect,
    samlErrorMessage,
    samlErrorType,
    samlUsername,
    samlPassword,
  }
}
