import { FC, PropsWithChildren, createContext, useContext } from 'react'

import { combine } from './storedStateHelpers'

const StoredStateNamespaceContext = createContext('')

const useStoredStateNamespace = () => useContext(StoredStateNamespaceContext)

type StoredStateNamespaceProps = PropsWithChildren<{
  namespace?: string
}>

const StoredStateNamespace: FC<StoredStateNamespaceProps> = ({ namespace = 'storageNamespace', children }) => {
  const existingNamespace = useStoredStateNamespace()
  const newNamespace = combine(existingNamespace, namespace)

  return <StoredStateNamespaceContext.Provider value={newNamespace}>{children}</StoredStateNamespaceContext.Provider>
}

export { useStoredStateNamespace, StoredStateNamespace }
