import qs from 'qs'

import { APIendpoint, GET_DOCUMENT_DESCRIPTORS, LOADING, getStoredToken } from 'core/actions/constants'
import { GET } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const getDocumentDescriptors = ({ key, filters = {} } = { filters: {} }) => {
  const { personId, uploadedByUserType } = filters

  return async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    const query = {
      includeArchived: true,
    }

    if (uploadedByUserType) {
      query.uploadedBy = uploadedByUserType
    }

    const endpoint = `${APIendpoint}/people/${personId}/documents?${qs.stringify(query)}`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: GET_DOCUMENT_DESCRIPTORS,
        payload: responseBody?.data ?? responseBody,
        personId,
        uploadedByUserType,
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }
}

export default getDocumentDescriptors
