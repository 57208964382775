// Third party ------------------------

import PropTypes from 'prop-types'
import { styled } from 'styled-components'

// Components -------------------------
import { useSetMobileTitle, useScrollToTop, useHasPermission } from 'core/hooks'

import InfoCard from './internal/InfoCard'
import PermissionMessage from './PermissionMessage'

const Wrapper = styled.div``

const Title = styled.h2`
  margin-top: 0;
  font-size: var(--fontSize28);
  font-weight: 400;
`

const ContentWrapper = styled.div``

const ActionPageWrapper = ({ title, url, permissions, backUrl, children }) => {
  useScrollToTop()

  useSetMobileTitle({ title, url })

  const hasPermission = useHasPermission(permissions)

  return (
    <Wrapper>
      <Title>{title}</Title>
      <ContentWrapper>
        {!hasPermission ?
          <InfoCard title={title}>
            <PermissionMessage backUrl={backUrl} />
          </InfoCard>
        : children}
      </ContentWrapper>
    </Wrapper>
  )
}

ActionPageWrapper.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  permissions: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.func]),
  backUrl: PropTypes.string,
  children: PropTypes.node,
}

export default ActionPageWrapper
