import { FC } from 'react'

import { styled, css, keyframes } from 'styled-components'

type SpinnerProps = {
  className?: string
  width?: string
  height?: string
  position?: 'absolute' | 'relative'
}

const Spinner: FC<SpinnerProps> = ({ className, width, height, position }) => (
  <SpinnerContainer className={className} data-testid='spinner-container' height={height} width={width}>
    <StyledSpinner className='spinner' position={position} />
  </SpinnerContainer>
)

export default Spinner

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const spinAnimation = css`
  animation: ${rotate} 1s infinite linear;
`
export const SpinnerContainer = styled.div<{ width?: string; height?: string }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.width ?? 'inherit'};
  height: ${(p) => p.height ?? '100%'};
`

const StyledSpinner = styled.div<{ position?: string }>`
  position: ${(p) => p.position ?? 'absolute'};
  border: 3px solid #ccc;
  border-radius: 50%;
  border-right-color: #888;
  padding: 6px;
  ${spinAnimation}
`
