import { useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import getContacts from 'core/actions/contacts/getContacts'
import getDocumentDescriptors from 'core/actions/documents/getDocumentDescriptors'
import getEmployees from 'core/actions/employees/getEmployees'
import getLegalRepresentatives from 'core/actions/legalRepresentatives/getLegalRepresentatives'
import getLoans from 'core/actions/loans/getLoans'
import getLoanTypes from 'core/actions/loanTypes/getLoanTypes'
import getPeople from 'core/actions/people/getPeople'
import getTeams from 'core/actions/teams/getTeams'
import getVerifications from 'core/actions/verifications/getVerifications'
import { useAppDispatch } from 'core/hooks/useAppStore'
import { makeGetLoadingStatus } from 'core/selectors/loading'

/** We store loading keys as keys in this object (value will be true always)
 * in order to synchronously track which fetches we have already begun and
 * avoid redundant fetches. The `loading` redux state doesn't get updated
 * immediately once dispatch is called in useEffect or a redux thunk. And we
 * aren't supposed to call dispatch synchronously in a component because it's
 * kind of the same as setting state, which shouldn't be done in the middle
 * of a render chain. All state updates and redux updates should be done at the
 * end of a render chain, which is why we always put them in useEffect or call
 * the provided setState or put them in an event handler.
 *
 * So when the first call to fetch data happens, and we're still in the middle
 * of a render chain while several others call it (and the actual fetching
 * hasn't begun yet and redux hasn't been updated yet), the several other components
 * calling to fetch the same data will queue up an api fetch as well, and we end up
 * with like 10 redundant api fetches before `loading` is set.
 *
 * This cache can be set immediately to signal to other call instances of the
 * function that they don't need to trigger an api fetch as well.
 */
const loadingCache = {}

/**
 *
 * @param {String} collectionString
 * @param {Object} filters
 * @returns {Object} collection
 * @returns {Object} collection.byId
 * @returns {String} collection.loadingStatus
 *
 * A note regarding the filters parameter:
 *
 * `filters` serves two functions. 1) It's needed to provide the required
 * params used in the action a collectionString is associated. 2) It's
 * used to filter out the data in the redux store pertaining to a
 * particular collection, which will only return each byId result of a
 * collection that matches each key/value pair in filters. If the returned
 * data from the action does not include the key/value pairs then you must
 * add them to each object that is returned from the action in order for
 * this hook to correctly filter out the data that it is used to fetch or
 * find for a particular collection.
 */
const useCollection = (collectionString, filters = {}) => {
  const { actions, useDispatch, useSelector } = useCollection.dependencies

  const dispatch = useDispatch()
  const filtersString = Object.entries(filters)
    .sort(([key1], [key2]) => key1.localeCompare(key2))
    .map(([k, v]) => `${k}=${v}`)
    .join(',')
  const loadingKey =
    filtersString.length > 0 ? `${collectionString}.byId(${filtersString})` : `${collectionString}.byId`

  const getLoadingStatus = useMemo(() => makeGetLoadingStatus(loadingKey), [loadingKey])

  const getCollection = useMemo(
    () =>
      createSelector(
        (state) => state[collectionString].byId,
        (byId) =>
          Object.entries(byId).reduce((filteredCollection, [k, v]) => {
            if (Object.entries(filters).every(([f, fVal]) => v[f] === fVal)) {
              filteredCollection[k] = v
            }
            return filteredCollection
          }, {}),
      ),
    [collectionString, filters],
  )

  const collection = {
    byId: useSelector(getCollection),
    loadingStatus: useSelector(getLoadingStatus),
  }

  const isLoadingNow = !!loadingCache[loadingKey]
  if (!collection.loadingStatus && !isLoadingNow) {
    loadingCache[loadingKey] = true
  }

  const hasRequiredFilters = collectionString === 'contacts' ? !!filters.personId : true

  // Populate the collection in redux if it doesn't exist yet
  useEffect(() => {
    void (async () => {
      if (!collection.loadingStatus && !isLoadingNow && hasRequiredFilters) {
        try {
          await dispatch(actions[collectionString]({ filters, key: loadingKey }))
        } finally {
          delete loadingCache[loadingKey]
        }
      }
    })()
  }, [
    collection.loadingStatus,
    dispatch,
    loadingKey,
    collectionString,
    filters,
    actions,
    isLoadingNow,
    hasRequiredFilters,
  ])

  return collection
}

useCollection.dependencies = {
  actions: {
    contacts: getContacts,
    documents: getDocumentDescriptors,
    employees: getEmployees,
    legalRepresentatives: getLegalRepresentatives,
    loanTypes: getLoanTypes,
    loans: getLoans,
    people: getPeople,
    teams: getTeams,
    verifications: getVerifications,
  },
  useDispatch: useAppDispatch,
  useSelector,
}

export default useCollection
