import PropTypes from 'prop-types'

import { useBorrowerBaseUrl } from 'core/hooks'

import InfoCard from './internal/InfoCard'

const AccountInfoCard = ({ completeUrl, ...rest }) => {
  const baseUrl = useBorrowerBaseUrl()

  const $completeUrl = completeUrl ? `${baseUrl}${completeUrl}` : undefined

  return <InfoCard completeUrl={$completeUrl} {...rest} />
}

AccountInfoCard.propTypes = {
  completeUrl: PropTypes.string,
}

export default AccountInfoCard
