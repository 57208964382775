import { FC } from 'react'

import { R } from 'core/helpers'

import { BoxContent, ErrorBox, ErrorMessage, ErrorTitle, FallbackProps } from './defaultErrorBoundaries'

const DefaultErrorFallback: FC<FallbackProps> = ({ className, error }) => (
  <ErrorBox $type={R.isObject(error) && error.status === 403 ? 'warning' : 'error'} className={className}>
    <ErrorTitle>Something went wrong</ErrorTitle>
    <BoxContent>
      <ErrorMessage error={error} />
    </BoxContent>
  </ErrorBox>
)

export default DefaultErrorFallback
