import React, { Component } from 'react'

import PropTypes from 'prop-types'
import InputMaskBase from 'react-input-mask'
import { styled, css } from 'styled-components'

import { CURRENCY_SYMBOL, R, formatCurrency } from 'core/helpers'
import disableTrailingSpaces from 'core/helpers/disableTrailingSpaces'
import variables from 'core/styles/variables'

const containerStyles = css`
  display: infline-flex;
  flex: 1 0 0;
  width: ${(p) => (p.width ? `${p.width}px` : '100%')};

  &:focus-visible {
    outline: none;
  }
`

const Amount = styled.div`
  ${containerStyles}
  display: flex;

  input {
    border-bottom: 1px solid ${variables.colorBlack30};
    padding-left: 4px;
  }
`

const Percentage = styled.div`
  ${containerStyles}
`

const NumberInput = styled.input`
  ${containerStyles}
`

const UnderlineInput = styled.input`
  ${containerStyles}
  border-bottom: ${(props) => (props.disableUnderline ? 'none' : `1px solid ${variables.colorBlack30}`)};
  text-overflow: ${(props) => (props.withEllipsis ? 'ellipsis' : 'inherit')};
  ${(p) => (p.fullWidth ? 'width: 100%;' : '')}
`

const AmountPrefix = styled.span`
  align-self: center;
  color: var(--colorBlack60);
`

const InputWrapper = styled.input`
  ${containerStyles}
  text-overflow: ${(props) => (props.withEllipsis ? 'ellipsis' : 'inherit')};
`

const InputMask = styled(InputMaskBase)`
  ${containerStyles}
`

class MaskedInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cardNumberMask: '9999 9999 9999 9999',
      cvcMask: '9999',
      amountValue: this.props.useValueForAmount ? this.props.value : '',
    }

    this.userInput = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { value, inputType, disabled, autoFocus } = this.props

    if (!disabled && autoFocus) {
      this.userInput.current.focus()
    }

    // Hack to force amount input type to update the state if
    // the prop value has changed.
    if (inputType === 'amount' && value && prevProps.value !== value) {
      this.handleAmountOnchange({ target: { value } })
    }

    // TODO: have autofocus property instead of the following
    if (this.prop?.autoFocus) {
      this.userInput.current.focus()
    }
  }

  handleAmountOnchange = (e) => {
    this.props.onChange(e)
    const value = e.target.value
    const pattern = /^(\d+)?([.]?\d{0,2})?$/

    if (pattern.test(value) && (R.isNil(this.props.maxAmount) || Number.parseFloat(value) <= this.props.maxAmount)) {
      this.setState({
        amountValue: value,
      })
    } else {
      return false
    }
  }

  // TODO: add input patterns during validaitons
  // NOTE: keys are subject to change depending on real data
  _getInputType = (type) => {
    const { customStyle, placeholder, width, color, withBottomBorder, maxLength, unstyled, inputType } = this.props

    const baseStyle = {
      display: 'inline-flex',
      border: 'none',
      borderBottom: withBottomBorder ? `1px solid ${variables.colorBlack30}` : 'none',
      backgroundColor: 'transparent',
      // TODO: do we want this as a base style?
      // disabling this for now, as it's breaking certain areas
      // padding: '0',
      width: width ? `${width}px` : undefined,
      fontFamily: 'inherit',
      fontSize: '1.6rem',
      color: color ? `${color}` : 'inherit',
    }

    const style = { ...baseStyle, ...customStyle }

    const styleOrUnstyled = unstyled ? undefined : style

    const handleCardOnchange = (e) => {
      this.props.onChange(e)

      const newState = {
        cardNumberMask: '9999 9999 9999 9999',
        cvcMask: '9999',
      }

      if (inputType === 'cardNumber') {
        const value = e.target.value

        // formatted for amex, if it starts with 34 or 37
        // supports 3 or 4 security code
        if (/^3[47]/.test(value)) {
          newState.cardNumberMask = '9999 999999 99999'
          newState.cvcMask = '9999'
        } else {
          newState.cardNumberMask = '9999 9999 9999 9999'
          newState.cvcMask = '999'
        }
        this.setState(newState)
      }
    }

    const inputTypes = {
      tel: () => (
        <InputMask
          autoFocus={this.props.autoFocus}
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          mask='(999) 999 - 9999'
          // alwaysShowMask
          maskPlaceholder={null}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => this.props.onChange(e)}
          onFocus={this.props.onFocus}
          placeholder={this.props.placeholder}
          ref={this.props.customInputRef || this.userInput}
          style={styleOrUnstyled}
          value={this.props.value}
        />
      ),
      bankCodes: () => (
        <InputMask
          alwaysShowMask
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          mask={formatCurrency('0.99')}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => this.props.onChange(e)}
          onFocus={this.props.onFocus}
          style={styleOrUnstyled}
          value={this.props.value || ''}
        />
      ),
      sixDigitVerificationCodes: () => (
        <InputMask
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          mask='999999'
          // alwaysShowMask
          maskPlaceholder={null}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => this.props.onChange(e)}
          onFocus={this.props.onFocus}
          placeholder={this.props.placeholder}
          style={styleOrUnstyled}
          value={this.props.value}
        />
      ),
      email: () => (
        <InputWrapper
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => this.props.onChange(e)}
          onFocus={this.props.onFocus}
          placeholder={this.props.placeholder}
          ref={this.props.customRef || this.userInput}
          style={styleOrUnstyled}
          type='email'
          value={this.props.value}
          withEllipsis={this.props.withEllipsis}
        />
      ),
      password: () => (
        <input
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => this.props.onChange(e)}
          onFocus={this.props.onFocus}
          placeholder={this.props.placeholder}
          ref={this.props.customRef || this.userInput}
          style={styleOrUnstyled}
          type='password'
          value={this.props.value}
        />
      ),
      text: () => (
        <UnderlineInput
          data-testid={this.props.testId || null}
          disableUnderline={this.props.disableUnderline}
          disabled={this.props.disabled}
          fullWidth={this.props.fullWidth}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => this.props.onChange(e)}
          onFocus={this.props.onFocus}
          onKeyDown={(e) => [this.props.onChange(e), disableTrailingSpaces(e)]}
          placeholder={this.props.placeholder}
          ref={this.props.customRef || this.userInput}
          style={styleOrUnstyled}
          type='text'
          value={this.props.value}
          withEllipsis={this.props.withEllipsis}
        />
      ),
      number: () => (
        <NumberInput
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => this.props.onChange(e)}
          onFocus={this.props.onFocus}
          placeholder={placeholder}
          ref={this.userInput}
          style={styleOrUnstyled}
          type='number'
          value={this.props.value}
        />
      ),
      routingNumber: () => (
        <InputMask
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          mask='999999999'
          maskPlaceholder={null}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => this.props.onChange(e)}
          onFocus={this.props.onFocus}
          onKeyUp={(e) => this.props.onKeyUp(e)}
          placeholder='Enter routing number'
          style={styleOrUnstyled}
          value={this.props.value}
        />
      ),
      amount: () => (
        <Amount className='amount-input-wrapper'>
          <AmountPrefix>{CURRENCY_SYMBOL.USD}</AmountPrefix>
          <InputMask
            className={this.props.className}
            data-testid={this.props.testId || null}
            disabled={this.props.disabled}
            maxLength={maxLength}
            onBlur={this.props.onBlur}
            onChange={this.handleAmountOnchange}
            onFocus={this.props.onFocus}
            placeholder={placeholder}
            ref={this.props.customInputRef}
            style={styleOrUnstyled}
            value={this.state.amountValue}
          />
        </Amount>
      ),
      percent: () => (
        <Percentage>
          <UnderlineInput
            className={this.props.className}
            data-testid={this.props.testId || null}
            disabled={this.props.disabled}
            maxLength={maxLength}
            onBlur={this.props.onBlur}
            onChange={(e) => this.props.onChange(e)}
            onFocus={this.props.onFocus}
            placeholder='00.00'
            ref={this.props.customInputRef}
            size={this.props.value.length || 5}
            style={{ ...styleOrUnstyled, width: 'auto' }}
            type='text'
            value={this.props.value}
          />
          <AmountPrefix>%</AmountPrefix>
        </Percentage>
      ),
      cardNumber: () => (
        <InputMask
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          mask={this.state.cardNumberMask}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => handleCardOnchange(e)}
          onFocus={this.props.onFocus}
          placeholder={placeholder}
          style={styleOrUnstyled}
          value={this.props.value}
        />
      ),
      zipCode: () => (
        <InputMask
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          mask='99999'
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          // TODO(Wilcox): the `onChange` handler should always callback with
          // either `value` or `event`, but not arbitrarily one or the other
          onChange={(e) => this.props.onChange(e.target.value)}
          onFocus={this.props.onFocus}
          placeholder={placeholder}
          style={styleOrUnstyled}
          value={this.props.value}
        />
      ),
      expDate: () => (
        <InputMask
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          mask='99/99'
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => this.props.onChange(e.target.value)}
          onFocus={this.props.onFocus}
          placeholder={placeholder}
          style={styleOrUnstyled}
          value={this.props.value}
        />
      ),
      cvc: () => (
        <InputMask
          data-testid={this.props.testId || null}
          disabled={this.props.disabled}
          mask={this.state.cvcMask}
          maskPlaceholder={null}
          maxLength={maxLength}
          onBlur={this.props.onBlur}
          onChange={(e) => handleCardOnchange(e)}
          onFocus={this.props.onFocus}
          placeholder={placeholder}
          style={styleOrUnstyled}
          value={this.props.value}
        />
      ),
    }
    return (inputTypes[type] || inputTypes.text)()
  }

  render() {
    const rendered = this._getInputType(this.props.inputType)
    return rendered
  }
}

MaskedInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  customInputRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  customRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  customStyle: PropTypes.object,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  inputType: PropTypes.oneOf([
    'tel',
    'bankCodes',
    'sixDigitVerificationCodes',
    'password',
    'text',
    'amount',
    'cardNumber',
    'zipCode',
    'expDate',
    'cvc',
    'routingNumber',
    'percent',
    'email',
  ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  useValueForAmount: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
  withEllipsis: PropTypes.bool,
  withBottomBorder: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxLength: PropTypes.number,
  unstyled: PropTypes.bool,
  maxAmount: PropTypes.number,
}

export default MaskedInput
