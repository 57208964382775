import { SvgIcon } from './SvgIcon'

const MastercardIcon = () => (
  <SvgIcon>
    <path
      d='M40 31.428a2.365 2.365 0 0 1-2.364 2.365H2.364A2.365 2.365 0 0 1 0 31.428V8.572a2.365 2.365 0 0 1 2.364-2.365h35.272A2.364 2.364 0 0 1 40 8.572v22.856Z'
      fill='#0099DF'
    />
    <path
      d='M17.725 20.138c0-1.926.887-3.645 2.275-4.77a6.137 6.137 0 1 0 0 9.539 6.124 6.124 0 0 1-2.275-4.77Z'
      fill='#EA001B'
    />
    <path
      d='M23.862 14A6.113 6.113 0 0 0 20 15.368a6.125 6.125 0 0 1 2.275 4.77A6.124 6.124 0 0 1 20 24.908 6.137 6.137 0 1 0 23.863 14Z'
      fill='#F79F1A'
    />
    <path
      d='M22.275 20.138A6.125 6.125 0 0 0 20 15.368a6.126 6.126 0 0 0-2.275 4.77c0 1.926.887 3.644 2.275 4.77a6.124 6.124 0 0 0 2.275-4.77Z'
      fill='#FF5F01'
    />
  </SvgIcon>
)

export default MastercardIcon
