import { FC, useState } from 'react'

import { PDFViewer } from 'pdfjs-dist/web/pdf_viewer.mjs'
import { createPortal } from 'react-dom'
import { createGlobalStyle } from 'styled-components'

import IconButton from 'core/components/lib/IconButton'
import { R, wait } from 'core/helpers'

import { renderPages } from './ThumbnailsButton'

type PrintButtonProps = {
  pdfViewer: PDFViewer
}

const PrintButton: FC<PrintButtonProps> = ({ pdfViewer }) => {
  const [pages, setPages] = useState<Array<string>>([])

  return (
    <>
      <IconButton
        color='inherit'
        fontSize='18px'
        name='print'
        onClick={async () => {
          const pages = await renderPages(pdfViewer, 'print', 150 / 72)
          if (pages.length === 0) return

          setPages(pages)
          await wait(1000)
          window.print()
          setTimeout(() => setPages([]), 10000)
        }}
      />
      {pages.length > 0 &&
        createPortal(
          <>
            <GlobalStyle
              $size={pdfViewer.pagesRotation === 90 || pdfViewer.pagesRotation === 270 ? 'landscape' : 'portrait'}
            />
            <div className='print-container'>
              {R.map.indexed(pages, (page, i) => (
                <div className='printed-page' key={i}>
                  <img alt={'Page ' + (i + 1)} src={page} />
                </div>
              ))}
            </div>
          </>,
          document.body,
        )}
    </>
  )
}

export default PrintButton

const GlobalStyle = createGlobalStyle<{ $size: 'portrait' | 'landscape' }>`
  .print-container {
    display: none;
  }

  @page {
    margin: 0;
    size: ${(p) => p.$size}
  }

  @media print {
    body > * {
      display: none;
    }

    body > .print-container {
      display: block;

      .printed-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        page-break-after: always;
        page-break-inside: avoid;

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          direction: ltr;
        }
      }
    }
  }
`
