import { useState, useEffect } from 'react'

import { useTime } from 'core/time'

const useNow = (internval = 1000) => {
  const { getNow } = useTime()

  const [now, setNow] = useState(getNow)

  useEffect(() => {
    const id = setInterval(() => setNow(getNow()), internval)
    return () => clearTimeout(id)
  }, [getNow, internval])

  return now
}

export default useNow
