import { QueryKey } from '@tanstack/react-query'
import _ from 'lodash'

const getIsEnabled = (config: {
  queryKey?: QueryKey
  forceEnabled?: boolean
  disabled?: boolean
  enabled?: boolean
}) => {
  // forceEnabled ignores the queryKey and will be the only thing that determines if the query is enabled
  if (_.has(config, 'forceEnabled')) {
    return !!config.forceEnabled
  }

  // by default, all values of the queryKey must not be `null` or `undefined` for the query to be enbabled
  if (_.some(config.queryKey, (key) => key === undefined || key === null)) {
    return false
  }

  // if all keys are truthy, then enabled or disabled can still disable the query if present
  if (_.has(config, 'disabled')) {
    return !config.disabled
  }

  if (_.has(config, 'enabled')) {
    return !!config.enabled
  }

  // if all queryKeys are truthy and no other property has been specified, then the query is is enabled
  return true
}

export default getIsEnabled
