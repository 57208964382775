import * as R from 'core/helpers/remeda'
import { featureFlags } from 'core/types/generated/featureFlags'
import * as operations from 'core/types/operations'

type PeachOverride = {
  requires?: {
    name: PeachOverrideName
    value: any
  }
  resets?: Array<PeachOverrideName>
  requiresAuth?: boolean
  section: 'apiEndpoint' | 'appTypeAndCompany' | 'misc' | 'apiMockOptions' | 'apiMockEndpoints' | 'featureFlags'
  type: string
}

type PeachOverrideText = PeachOverride & {
  type: 'text'
  defaultValue: string
  placeholder?: string
}

type PeachOverrideToggle = PeachOverride & {
  type: 'toggle'
  defaultValue: boolean
}

type PeachOverrideSelect = PeachOverride & {
  type: 'select'
  defaultValue: string
  options: Array<string>
}

type PeachOverrideResponse = PeachOverride & {
  type: 'response'
  defaultValue: null | object
}

export type PeachOverrideVariant = PeachOverrideText | PeachOverrideToggle | PeachOverrideSelect | PeachOverrideResponse

export type PeachOverrideName =
  | keyof typeof operations
  | keyof typeof featureFlags
  | 'enableFeatureFlagMocking'
  | 'enableApiMocking'
  | 'responseDelay'
  | 'appDomainType'
  | 'appTypeMode'
  | 'companyId'
  | 'contactUsPage'
  | 'customEndpoint'
  | 'domain'
  | 'enableCustomEndpoint'
  | 'forceCarrierViolation'
  | 'forceUnhandledException'
  | 'forceUnknownTaskTypes'
  | 'microdepositVerification'
  | 'plaidBalanceCheck'
  | 'plaidVerification'
  | 'showCaseId'
  | 'userType'

const overrideOptions: { [key in PeachOverrideName]: PeachOverrideVariant } = {
  enableApiMocking: {
    section: 'apiMockOptions',
    type: 'toggle',
    defaultValue: false,
  },

  responseDelay: {
    section: 'apiMockOptions',
    type: 'text',
    defaultValue: '',
    placeholder: '0',
    requires: {
      name: 'enableApiMocking',
      value: true,
    },
  },

  ...R.mapValues(
    operations,
    () =>
      ({
        section: 'apiEndpoint',
        type: 'response',
        defaultValue: null,
      }) as const,
  ),

  enableFeatureFlagMocking: {
    section: 'featureFlags',
    type: 'toggle',
    defaultValue: false,
  },

  ...R.mapValues(
    featureFlags,
    () =>
      ({
        section: 'featureFlags',
        type: 'toggle',
        defaultValue: false,
        requires: {
          name: 'enableFeatureFlagMocking',
          value: true,
        },
      }) as const,
  ),

  enableCustomEndpoint: {
    requiresAuth: true,
    section: 'apiEndpoint',
    type: 'toggle',
    defaultValue: false,
    resets: ['customEndpoint'],
  },

  customEndpoint: {
    requires: {
      name: 'enableCustomEndpoint',
      value: true,
    },
    requiresAuth: true,
    section: 'apiEndpoint',
    type: 'text',
    defaultValue: '',
    placeholder: 'https://devapi.peach.finance/api',
  },

  appTypeMode: {
    section: 'appTypeAndCompany',
    type: 'select',
    defaultValue: 'default',
    options: ['default', 'manual', 'domain'],
    resets: ['appDomainType', 'companyId', 'domain'],
  },

  appDomainType: {
    requires: {
      name: 'appTypeMode',
      value: 'manual',
    },
    section: 'appTypeAndCompany',
    type: 'select',
    defaultValue: 'default',
    options: ['default', 'borrower', 'agent', 'admin'],
  },

  companyId: {
    requires: {
      name: 'appTypeMode',
      value: 'manual',
    },
    section: 'appTypeAndCompany',
    type: 'text',
    defaultValue: '',
    placeholder: 'CP-L9BN-5J52',
  },

  domain: {
    requires: {
      name: 'appTypeMode',
      value: 'domain',
    },
    section: 'appTypeAndCompany',
    type: 'text',
    defaultValue: '',
    placeholder: 'service.globex.capital',
  },

  userType: {
    section: 'misc',
    type: 'select',
    defaultValue: 'default',
    options: ['default', 'borrower', 'agent'],
  },

  showCaseId: {
    section: 'misc',
    type: 'toggle',
    defaultValue: false,
  },

  plaidBalanceCheck: {
    section: 'misc',
    type: 'toggle',
    defaultValue: false,
  },

  plaidVerification: {
    section: 'misc',
    type: 'toggle',
    defaultValue: false,
  },

  microdepositVerification: {
    section: 'misc',
    type: 'toggle',
    defaultValue: false,
  },

  contactUsPage: {
    section: 'misc',
    type: 'toggle',
    defaultValue: false,
  },

  forceUnknownTaskTypes: {
    section: 'misc',
    type: 'toggle',
    defaultValue: false,
  },

  forceUnhandledException: {
    section: 'misc',
    type: 'toggle',
    defaultValue: false,
  },

  forceCarrierViolation: {
    section: 'misc',
    type: 'toggle',
    defaultValue: false,
  },
}
export default overrideOptions
