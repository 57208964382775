const MINUTE = 60 * 1000

const defaultQueryConfig = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
  retry: 0,
  staleTime: 2 * MINUTE,
  gcTime: 2 * MINUTE,
}

export default defaultQueryConfig
