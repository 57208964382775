import React, { FC, PropsWithChildren, ReactNode } from 'react'

import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'

import Button from 'core/components/lib/Button'
import { PendingEventHandler } from 'core/components/lib/Button/Button'
import ConfirmDialog from 'core/components/lib/ConfirmDialog'
import Link from 'core/components/lib/Link'
import PermissionDeniedDialog from 'core/components/lib/PermissionDeniedDialog/PermissionDeniedDialog'
import { R } from 'core/helpers'
import { useHasPermission, useBorrowerUrl } from 'core/hooks'
import { Permission } from 'core/types'

import { useFormDisabled } from 'loan/components/actionCards/shared/DisabledContext'

import CardSection from './CardSection'

type BackButtonProps = PropsWithChildren<{
  color?: string
}>

export const BackButton: FC<BackButtonProps> = ({ color, children = 'Back' }) => {
  const { loanId } = useParams<{ loanId: string }>()
  const url = useBorrowerUrl(loanId ? `/loans/${loanId}/activity` : '/loans')

  return (
    <StyledLink color={color} to={url}>
      {children}
    </StyledLink>
  )
}

type CardSectionSubmitProps = {
  submitText?: string
  cancelText?: string
  onSubmit?: PendingEventHandler<React.MouseEvent<HTMLButtonElement, MouseEvent>>
  children?: ReactNode
  disabled?: boolean
  loading?: boolean
  success?: boolean
  skipConfirm?: boolean
  permissions?: Permission | Array<Permission>
}

const CardSectionSubmit: FC<CardSectionSubmitProps> = ({
  submitText = 'Submit',
  cancelText,
  onSubmit = R.noop,
  children,
  disabled = false,
  loading = false,
  success = false,
  skipConfirm,
  permissions,
}) => {
  const isDisabled = useFormDisabled(disabled)

  const hasPermission = useHasPermission(permissions)

  const common = {
    readOnly: isDisabled || loading,
    secondary: true,
    children: loading ? 'Processing' : submitText,
  }

  return (
    <CardSection>
      <SubmitButtonsContainer>
        {!success && <BackButton color='black'>{cancelText}</BackButton>}

        {children ??
          (!hasPermission ?
            <PermissionDeniedDialog title={submitText}>
              <Button {...common} />
            </PermissionDeniedDialog>
          : success ? <BackButton>Done</BackButton>
          : skipConfirm ? <Button {...common} type='submit' />
          : <ConfirmDialog
              content={`Are you sure you want to ${submitText.toLowerCase()}?`}
              onConfirm={onSubmit}
              title={submitText}
            >
              <Button {...common} />
            </ConfirmDialog>)}
      </SubmitButtonsContainer>
    </CardSection>
  )
}

export default CardSectionSubmit

export const SubmitButtonsContainer = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: flex-end;
  margin: ${(p) => p.margin ?? '16px 0 0 0'};
`

const StyledLink = styled(Link)<{ color?: string }>`
  && {
    color: ${(p) => p.color};

    &:hover {
      text-decoration: none;
    }
  }
`
