import { APIendpoint, GET_LEGAL_REPRESENTATIVES, LOADING, getStoredToken } from 'core/actions/constants'
import { GET } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const getInteractions = ({ key, filters }) => {
  const { personId } = filters

  return async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    const endpoint = `${APIendpoint}/people/${personId}/legal-representatives`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: GET_LEGAL_REPRESENTATIVES,
        payload: responseBody.data,
        personId,
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }
}

export default getInteractions
