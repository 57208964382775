import { FC } from 'react'

import { LDProvider } from 'launchdarkly-react-client-sdk'

import App from 'app/App'
import UnhandledExceptions from 'app/UnhandledExceptions'
import DraftContextProvider from 'contexts/DraftContext'
import FileUploadContextProvider from 'contexts/FileUploadContext/provider'

import { PeachOverrides, Spinner } from 'core/components'
import ErrorBoundary from 'core/components/lib/ErrorBoundary/ErrorBoundary'
import { fetchBootstrap, useBootstrapStore } from 'core/stores'
import { useErrorLoggingScope } from 'core/system/useErrorLoggingScope'
import { TimeProvider } from 'core/time'

import CommonProviders from './CommonProvider'
import useActivityRenewal from './session/useActivityRenewal'
import ToastProvider from './ToastProvider'

void fetchBootstrap()

const Root: FC = () => {
  const bootstrap = useBootstrapStore()
  const { appDomainType, userType, company } = bootstrap ?? {}

  useErrorLoggingScope({ company, appDomainType, userType })

  useActivityRenewal()

  return (
    <ErrorBoundary ident='AppRoot' isAppRoot>
      <ToastProvider>
        <UnhandledExceptions>
          {bootstrap === null ?
            <Spinner height='100vh' />
          : <LDProvider
              clientSideID={import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID}
              context={{ key: company?.id ?? 'unknown' }}
            >
              <CommonProviders>
                <TimeProvider timezone={company?.timezone}>
                  <DraftContextProvider>
                    <FileUploadContextProvider>
                      <App />
                      <PeachOverrides />
                    </FileUploadContextProvider>
                  </DraftContextProvider>
                </TimeProvider>
              </CommonProviders>
            </LDProvider>
          }
        </UnhandledExceptions>
      </ToastProvider>
    </ErrorBoundary>
  )
}

export default Root
