/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import makeMutationHook from 'core/types/makeMutationHook'
import makeQueryHook from 'core/types/makeQueryHook'
import * as operations from 'core/types/operations'

export const usePeachy = makeQueryHook(operations.peachy)

export const usePeachyReady = makeQueryHook(operations.peachyReady)

export const usePit = makeQueryHook(operations.pit)

export const useInternalGetEvents = makeQueryHook(operations.internalGetEvents)

export const useInternalCreateEvent = makeMutationHook(operations.internalCreateEvent)

export const useInternalCreateEventsBulk = makeMutationHook(operations.internalCreateEventsBulk)

export const useInternalUpdateEvent = makeMutationHook(operations.internalUpdateEvent)

export const useInternalLoansGetByCompany = makeQueryHook(operations.internalLoansGetByCompany)

export const useInternalLoansGetCount = makeQueryHook(operations.internalLoansGetCount)

export const useCompanyLoanConfigurationsGetAll = makeQueryHook(operations.companyLoanConfigurationsGetAll)

export const useCompanyLoanConfigurationsAdd = makeMutationHook(operations.companyLoanConfigurationsAdd)

export const useCompanyLoanConfigurationsGet = makeQueryHook(operations.companyLoanConfigurationsGet)

export const useCompanyLoanConfigurationsUpdate = makeMutationHook(operations.companyLoanConfigurationsUpdate)

export const useCompanyLoanConfigurationsDelete = makeMutationHook(operations.companyLoanConfigurationsDelete)

export const useLoansGetByLoanType = makeQueryHook(operations.loansGetByLoanType)

export const useInternalBorrowerPaymentInstrumentGetAll = makeQueryHook(
  operations.internalBorrowerPaymentInstrumentGetAll,
)

export const useInternalBorrowerPaymentInstrumentCreate = makeMutationHook(
  operations.internalBorrowerPaymentInstrumentCreate,
)

export const useInternalBorrowerPaymentInstrumentGet = makeQueryHook(operations.internalBorrowerPaymentInstrumentGet)

export const useInternalBorrowerPaymentInstrumentUpdate = makeMutationHook(
  operations.internalBorrowerPaymentInstrumentUpdate,
)

export const useInternalBorrowerPaymentInstrumentDelete = makeMutationHook(
  operations.internalBorrowerPaymentInstrumentDelete,
)

export const useInternalAccountLinkPost = makeMutationHook(operations.internalAccountLinkPost)

export const useInternalAccountLinkDelete = makeMutationHook(operations.internalAccountLinkDelete)

export const useInternalBalancesGet = makeQueryHook(operations.internalBalancesGet)

export const useInternalBalancesPost = makeMutationHook(operations.internalBalancesPost)

export const useInternalLoansGet = makeQueryHook(operations.internalLoansGet)

export const useInternalLoansAdd = makeMutationHook(operations.internalLoansAdd)

export const useInternalLoanGet = makeQueryHook(operations.internalLoanGet)

export const useInternalLoanUpdate = makeMutationHook(operations.internalLoanUpdate)

export const useInternalSyncPayoffStatus = makeMutationHook(operations.internalSyncPayoffStatus)

export const useLoggingDebugger = makeMutationHook(operations.loggingDebugger)

export const useLedgerDebugger = makeQueryHook(operations.ledgerDebugger)

export const useInternalLoanRatesDelete = makeMutationHook(operations.internalLoanRatesDelete)

export const useInternalForecastInterest = makeQueryHook(operations.internalForecastInterest)

export const useInternalLoanRatesGet = makeQueryHook(operations.internalLoanRatesGet)

export const useInternalLoanRatesAdd = makeMutationHook(operations.internalLoanRatesAdd)

export const useInternalGetLoanPeriods = makeQueryHook(operations.internalGetLoanPeriods)

export const useInternalUpdateLoanPeriod = makeMutationHook(operations.internalUpdateLoanPeriod)

export const useInternalCreateNextLoanPeriod = makeMutationHook(operations.internalCreateNextLoanPeriod)

export const useInternalLoanExpectedPaymentsGet = makeQueryHook(operations.internalLoanExpectedPaymentsGet)

export const useInternalLoanExpectedPaymentsPost = makeMutationHook(operations.internalLoanExpectedPaymentsPost)

export const useInternalLoanExpectedPaymentsGetOne = makeQueryHook(operations.internalLoanExpectedPaymentsGetOne)

export const useInternalLoanAutopayGet = makeQueryHook(operations.internalLoanAutopayGet)

export const useInternalLoanAutopayPut = makeMutationHook(operations.internalLoanAutopayPut)

export const useInternalLoanAutopayPost = makeMutationHook(operations.internalLoanAutopayPost)

export const useInternalLoanAutopayDelete = makeMutationHook(operations.internalLoanAutopayDelete)

export const useInternalLoanAutopayCancelPayments = makeMutationHook(operations.internalLoanAutopayCancelPayments)

export const useInternalLoanAutopayReschedulePayments = makeMutationHook(
  operations.internalLoanAutopayReschedulePayments,
)

export const useInternalLoanAutopayCreateNextAutopay = makeMutationHook(operations.internalLoanAutopayCreateNextAutopay)

export const useInternalLoanAutopayUpdateExpectedPaymentAmount = makeMutationHook(
  operations.internalLoanAutopayUpdateExpectedPaymentAmount,
)

export const useInternalLoanBalancesGet = makeQueryHook(operations.internalLoanBalancesGet)

export const useInternalLoanFutureBalancesGet = makeQueryHook(operations.internalLoanFutureBalancesGet)

export const useInternalLoanTypeIndexRateGetAll = makeQueryHook(operations.internalLoanTypeIndexRateGetAll)

export const useInternalLoanTypeIndexRateAdd = makeMutationHook(operations.internalLoanTypeIndexRateAdd)

export const useInternalLoanTypeIndexRateGet = makeQueryHook(operations.internalLoanTypeIndexRateGet)

export const useInternalLoanTypeIndexRateUpdate = makeMutationHook(operations.internalLoanTypeIndexRateUpdate)

export const useInternalLoanTypeIndexRateDelete = makeMutationHook(operations.internalLoanTypeIndexRateDelete)

export const useInternalObligationsGetAll = makeQueryHook(operations.internalObligationsGetAll)

export const useInternalObligationCreate = makeMutationHook(operations.internalObligationCreate)

export const useGetLoan = makeQueryHook(operations.getLoan)

export const useGetCompanyLoanObligations = makeQueryHook(operations.getCompanyLoanObligations)

export const useObligationsRefreshPreviewForTimestamp = makeQueryHook(operations.obligationsRefreshPreviewForTimestamp)

export const useInternalObligationsRefresh = makeMutationHook(operations.internalObligationsRefresh)

export const useInternalLoanAgentDashboardToolsGet = makeQueryHook(operations.internalLoanAgentDashboardToolsGet)

export const useInternalLoanCustomPaymentPlanRefresh = makeMutationHook(operations.internalLoanCustomPaymentPlanRefresh)

export const useObligationsRefresh = makeMutationHook(operations.obligationsRefresh)

export const useInternalLoanTransactionsGetAll = makeQueryHook(operations.internalLoanTransactionsGetAll)

export const useInternalTransactionsCreate = makeMutationHook(operations.internalTransactionsCreate)

export const useInternalTransactionsGetById = makeQueryHook(operations.internalTransactionsGetById)

export const useInternalTransactionsUpdate = makeMutationHook(operations.internalTransactionsUpdate)

export const useInternalTransactionCancel = makeMutationHook(operations.internalTransactionCancel)

export const useInternalTransactionsDeleteChildren = makeMutationHook(operations.internalTransactionsDeleteChildren)

export const useInternalTransactionsZeroSplits = makeMutationHook(operations.internalTransactionsZeroSplits)

export const useInternalTransactionReverse = makeMutationHook(operations.internalTransactionReverse)

export const useInternalGetChargebacks = makeQueryHook(operations.internalGetChargebacks)

export const useInternalTransactionChargeback = makeMutationHook(operations.internalTransactionChargeback)

export const useInternalGetChargebackById = makeQueryHook(operations.internalGetChargebackById)

export const useInternalUpdateChargeback = makeMutationHook(operations.internalUpdateChargeback)

export const useLoanTypeDynamicFeeGetAll = makeQueryHook(operations.loanTypeDynamicFeeGetAll)

export const useLoanTypeDynamicFeeTypeAdd = makeMutationHook(operations.loanTypeDynamicFeeTypeAdd)

export const useInternalLoanChargeOff = makeMutationHook(operations.internalLoanChargeOff)

export const useInternalLoanDueDateDefer = makeMutationHook(operations.internalLoanDueDateDefer)

export const useInternalLoanScheduleDefer = makeMutationHook(operations.internalLoanScheduleDefer)

export const useInternalLoanChangeTermsScra = makeMutationHook(operations.internalLoanChangeTermsScra)

export const useInternalLoanAccelerate = makeMutationHook(operations.internalLoanAccelerate)

export const useInternalLoanRefresh = makeMutationHook(operations.internalLoanRefresh)

export const useInternalLoanScheduleOptionsGet = makeQueryHook(operations.internalLoanScheduleOptionsGet)

export const useLoanTypeDynamicFeeTypeGet = makeQueryHook(operations.loanTypeDynamicFeeTypeGet)

export const useInternalLoanLateFeeCharge = makeMutationHook(operations.internalLoanLateFeeCharge)

export const useInternalLoanServiceFeeCharge = makeMutationHook(operations.internalLoanServiceFeeCharge)

export const useInternalLoanNsfFeesCharge = makeMutationHook(operations.internalLoanNsfFeesCharge)

export const useInternalFeeTypesGet = makeQueryHook(operations.internalFeeTypesGet)

export const useInternalDynamicFeeGet = makeQueryHook(operations.internalDynamicFeeGet)

export const useInternalDynamicFeeUpdate = makeMutationHook(operations.internalDynamicFeeUpdate)

export const useInternalMarkFeeBooked = makeMutationHook(operations.internalMarkFeeBooked)

export const useInternalFeesGet = makeQueryHook(operations.internalFeesGet)

export const useInternalFeesPost = makeMutationHook(operations.internalFeesPost)

export const useInternalFeesCancelOne = makeMutationHook(operations.internalFeesCancelOne)

export const useInternalFeesCancel = makeMutationHook(operations.internalFeesCancel)

export const useInternalLoanAccrue = makeMutationHook(operations.internalLoanAccrue)

export const useLoanPayoff = makeMutationHook(operations.loanPayoff)

export const useInternalLoanPayoff = makeMutationHook(operations.internalLoanPayoff)

export const useInternalLoanPayoffReverse = makeMutationHook(operations.internalLoanPayoffReverse)

export const useLoanPayoffReverse = makeMutationHook(operations.loanPayoffReverse)

export const useInternalLoanExpectedPaymentsRefresh = makeMutationHook(operations.internalLoanExpectedPaymentsRefresh)

export const useLoanExpectedPaymentsRefresh = makeMutationHook(operations.loanExpectedPaymentsRefresh)

export const useInternalLoanExpectedPaymentOverride = makeMutationHook(operations.internalLoanExpectedPaymentOverride)

export const useInternalFeeAdjustForLoc = makeMutationHook(operations.internalFeeAdjustForLoc)

export const useInternalFinalizeMinPaymentsForLoc = makeMutationHook(operations.internalFinalizeMinPaymentsForLoc)

export const useInternalLoanExpectedPaymentsOverLimit = makeMutationHook(
  operations.internalLoanExpectedPaymentsOverLimit,
)

export const useEmitScheduledInteractionEvents = makeMutationHook(operations.emitScheduledInteractionEvents)

export const useEmitExpiredCaseSnoozeEvents = makeMutationHook(operations.emitExpiredCaseSnoozeEvents)

export const useEmitExpiredCaseDniEvents = makeMutationHook(operations.emitExpiredCaseDniEvents)

export const useServicesGetAll = makeQueryHook(operations.servicesGetAll)

export const useServiceCreate = makeMutationHook(operations.serviceCreate)

export const useServiceCreateBulk = makeMutationHook(operations.serviceCreateBulk)

export const useServicesGet = makeQueryHook(operations.servicesGet)

export const useDueStatuses = makeQueryHook(operations.dueStatuses)

export const useAllStatuses = makeQueryHook(operations.allStatuses)

export const useUpdateStatuses = makeMutationHook(operations.updateStatuses)

export const useInternalSnoozeCase = makeMutationHook(operations.internalSnoozeCase)

export const useGetAddressesInFipsCode = makeQueryHook(operations.getAddressesInFipsCode)

export const useInternalStatusGetAll = makeQueryHook(operations.internalStatusGetAll)

export const useInternalStatusUpdate = makeMutationHook(operations.internalStatusUpdate)

export const useStatusGetAll = makeQueryHook(operations.statusGetAll)

export const useStatusCreate = makeMutationHook(operations.statusCreate)

export const useStatusGet = makeQueryHook(operations.statusGet)

export const useStatusUpdate = makeMutationHook(operations.statusUpdate)

export const useGetAllDisasters = makeQueryHook(operations.getAllDisasters)

export const useCreateDisaster = makeMutationHook(operations.createDisaster)

export const useGetDisasterImpactedAreasCsv = makeQueryHook(operations.getDisasterImpactedAreasCsv)

export const useFindNewUpdatedDisasters = makeMutationHook(operations.findNewUpdatedDisasters)

export const useCompanyRoleGetAll = makeQueryHook(operations.companyRoleGetAll)

export const useCompanyRoleAdd = makeMutationHook(operations.companyRoleAdd)

export const useCompanyRoleGet = makeQueryHook(operations.companyRoleGet)

export const useCompanyRoleUpdate = makeMutationHook(operations.companyRoleUpdate)

export const useCompanyRoleDelete = makeMutationHook(operations.companyRoleDelete)

export const useCompanyRolePermissionsElevate = makeMutationHook(operations.companyRolePermissionsElevate)

export const useCompanyRolePermissionsRevoke = makeMutationHook(operations.companyRolePermissionsRevoke)

export const useCompanyRolePermissionsRevokeOne = makeMutationHook(operations.companyRolePermissionsRevokeOne)

export const useCompanyPermissionsGet = makeQueryHook(operations.companyPermissionsGet)

export const useInternalUsersGet = makeQueryHook(operations.internalUsersGet)

export const useInternalUserCreate = makeMutationHook(operations.internalUserCreate)

export const useInternalUserGet = makeQueryHook(operations.internalUserGet)

export const useInternalUserUpdate = makeMutationHook(operations.internalUserUpdate)

export const useInternalUserGetPermissions = makeQueryHook(operations.internalUserGetPermissions)

export const useInternalUserRoleGet = makeQueryHook(operations.internalUserRoleGet)

export const useInternalUserRoleUpdate = makeMutationHook(operations.internalUserRoleUpdate)

export const useInternalUserRoleDelete = makeMutationHook(operations.internalUserRoleDelete)

export const useInvestorGetAll = makeQueryHook(operations.investorGetAll)

export const useInvestorCreate = makeMutationHook(operations.investorCreate)

export const useInternalLoanInvestorsGetAll = makeQueryHook(operations.internalLoanInvestorsGetAll)

export const useInvestorGet = makeQueryHook(operations.investorGet)

export const useInvestorUpdate = makeMutationHook(operations.investorUpdate)

export const useSettlementInstrumentGetAll = makeQueryHook(operations.settlementInstrumentGetAll)

export const useSettlementInstrumentCreate = makeMutationHook(operations.settlementInstrumentCreate)

export const useSettlementInstrumentGet = makeQueryHook(operations.settlementInstrumentGet)

export const useSettlementInstrumentUpdate = makeMutationHook(operations.settlementInstrumentUpdate)

export const useFundingInstrumentGetAll = makeQueryHook(operations.fundingInstrumentGetAll)

export const useFundingInstrumentCreate = makeMutationHook(operations.fundingInstrumentCreate)

export const useFundingInstrumentGet = makeQueryHook(operations.fundingInstrumentGet)

export const useFundingInstrumentUpdate = makeMutationHook(operations.fundingInstrumentUpdate)

export const useGetRecentFundingAccountBalances = makeQueryHook(operations.getRecentFundingAccountBalances)

export const useFundingInstrumentCreateBalance = makeMutationHook(operations.fundingInstrumentCreateBalance)

export const useGetAllTransferSettlementsForAllCompanies = makeQueryHook(
  operations.getAllTransferSettlementsForAllCompanies,
)

export const useGetAllTransferSettlements = makeQueryHook(operations.getAllTransferSettlements)

export const useCreateTransferSettlements = makeMutationHook(operations.createTransferSettlements)

export const useGetTransferSettlement = makeQueryHook(operations.getTransferSettlement)

export const useUpdateTransferSettlement = makeMutationHook(operations.updateTransferSettlement)

export const useUpdateTransfersExpectingFunding = makeMutationHook(operations.updateTransfersExpectingFunding)

export const useEndSession = makeMutationHook(operations.endSession)

export const useRenewJwtToken = makeMutationHook(operations.renewJwtToken)

export const useValidateAndSendToken = makeMutationHook(operations.validateAndSendToken)

export const useLogout = makeMutationHook(operations.logout)

export const useShortLivedToken = makeMutationHook(operations.shortLivedToken)

export const usePasswordPost = makeMutationHook(operations.passwordPost)

export const useUserPermissions_get = makeQueryHook(operations.userPermissions_get)

export const useIdpInitiated = makeMutationHook(operations.idpInitiated)

export const useSpInitiated = makeQueryHook(operations.spInitiated)

export const useSpMetadataXml = makeQueryHook(operations.spMetadataXml)

export const useIdpSetupHelp = makeQueryHook(operations.idpSetupHelp)

export const useUserInfoGet = makeQueryHook(operations.userInfoGet)

export const usePlaidAuthLinkToken = makeMutationHook(operations.plaidAuthLinkToken)

export const useCodeGetAll = makeQueryHook(operations.codeGetAll)

export const useCodeCreate = makeMutationHook(operations.codeCreate)

export const useExportCodes = makeQueryHook(operations.exportCodes)

export const useImportCodes = makeMutationHook(operations.importCodes)

export const useInternalImportCodes = makeMutationHook(operations.internalImportCodes)

export const useInternalUpdateComplianceRule = makeMutationHook(operations.internalUpdateComplianceRule)

export const useCodeGet = makeQueryHook(operations.codeGet)

export const useCodeUpdate = makeMutationHook(operations.codeUpdate)

export const useCodeExecute = makeMutationHook(operations.codeExecute)

export const useTransactionsGetInternal = makeQueryHook(operations.transactionsGetInternal)

export const useTransactionsUpdateInternal = makeMutationHook(operations.transactionsUpdateInternal)

export const useTransactionsGetInitiatedAchInternal = makeQueryHook(operations.transactionsGetInitiatedAchInternal)

export const useTransactionsUpdateInitiatedAchInternal = makeMutationHook(
  operations.transactionsUpdateInitiatedAchInternal,
)

export const useTransactionsCreateRefundServiceCreditInternal = makeMutationHook(
  operations.transactionsCreateRefundServiceCreditInternal,
)

export const useMicrodepositsGetScheduledInternal = makeQueryHook(operations.microdepositsGetScheduledInternal)

export const useTransactionsUpdateMicrodepositsInternal = makeMutationHook(
  operations.transactionsUpdateMicrodepositsInternal,
)

export const useMicrodepositsSendReminders = makeMutationHook(operations.microdepositsSendReminders)

export const useInternalTransactionsGetLoanIds = makeMutationHook(operations.internalTransactionsGetLoanIds)

export const useTransactionsCancelScheduledInternal = makeMutationHook(operations.transactionsCancelScheduledInternal)

export const useTransactionsGetLoansWithScheduledReversals = makeQueryHook(
  operations.transactionsGetLoansWithScheduledReversals,
)

export const useProcessScheduledReversalsForLoan = makeMutationHook(operations.processScheduledReversalsForLoan)

export const useTransactionsGetRawInternalHandler = makeQueryHook(operations.transactionsGetRawInternalHandler)

export const useGetReconciliationTransactionsByCompany = makeQueryHook(
  operations.getReconciliationTransactionsByCompany,
)

export const useCreateReconciliation = makeMutationHook(operations.createReconciliation)

export const useCompanyTransactionsGetTransitional = makeQueryHook(operations.companyTransactionsGetTransitional)

export const useTransactionsGetAllExpiredHoldDays = makeQueryHook(operations.transactionsGetAllExpiredHoldDays)

export const useGetRoutingNumber = makeQueryHook(operations.getRoutingNumber)

export const useGetBinNumber = makeMutationHook(operations.getBinNumber)

export const useAutopayGetAllInternalCompany = makeQueryHook(operations.autopayGetAllInternalCompany)

export const useAutopayGetAllInternal = makeQueryHook(operations.autopayGetAllInternal)

export const useGetRepaymentEngineInfo = makeQueryHook(operations.getRepaymentEngineInfo)

export const useHasher = makeMutationHook(operations.hasher)

export const useGetPeriods = makeQueryHook(operations.getPeriods)

export const useCreateStatement = makeMutationHook(operations.createStatement)

export const useInternalSaveAutopay = makeMutationHook(operations.internalSaveAutopay)

export const useInternalGenerateStatement = makeMutationHook(operations.internalGenerateStatement)

export const useRevokeOrReinstateGrace = makeMutationHook(operations.revokeOrReinstateGrace)

export const useInternalComputeBalancesForGrace = makeMutationHook(operations.internalComputeBalancesForGrace)

export const useInternalConsentGet = makeQueryHook(operations.internalConsentGet)

export const useInternalConsentUpdate = makeMutationHook(operations.internalConsentUpdate)

export const useTransactionsCreateVirtual = makeMutationHook(operations.transactionsCreateVirtual)

export const useInternalLoanRefundsGet = makeQueryHook(operations.internalLoanRefundsGet)

export const useInternalLoanRefundsPost = makeMutationHook(operations.internalLoanRefundsPost)

export const useInternalLoanRefundsGetOne = makeQueryHook(operations.internalLoanRefundsGetOne)

export const useInternalInstallmentRefundUpdate = makeMutationHook(operations.internalInstallmentRefundUpdate)

export const useInternalLoanRefundsCancel = makeMutationHook(operations.internalLoanRefundsCancel)

export const useInternalLoanRefundsV2Post = makeMutationHook(operations.internalLoanRefundsV2Post)

export const useInternalLoanActivate = makeMutationHook(operations.internalLoanActivate)

export const useLoanUpdateMigrationStatus = makeMutationHook(operations.loanUpdateMigrationStatus)

export const useExpandUrl = makeQueryHook(operations.expandUrl)

export const useInternalGetStatements = makeQueryHook(operations.internalGetStatements)

export const useDeleteStatement = makeMutationHook(operations.deleteStatement)

export const useInternalGetCompanyStatementsWithoutPdf = makeQueryHook(
  operations.internalGetCompanyStatementsWithoutPdf,
)

export const useInternalLoanDrawLocIdGet = makeQueryHook(operations.internalLoanDrawLocIdGet)

export const useInternalLoanDrawsGet = makeQueryHook(operations.internalLoanDrawsGet)

export const useInternalLoanDrawsPost = makeMutationHook(operations.internalLoanDrawsPost)

export const useInternalDrawActivate = makeMutationHook(operations.internalDrawActivate)

export const useInternalLoanDrawsGetWithoutBorrower = makeQueryHook(operations.internalLoanDrawsGetWithoutBorrower)

export const useInternalCloseLocIfInactive = makeMutationHook(operations.internalCloseLocIfInactive)

export const useInternalDrawChargeVariableDrawFee = makeMutationHook(operations.internalDrawChargeVariableDrawFee)

export const useInternalClearMinPayments = makeMutationHook(operations.internalClearMinPayments)

export const useRefreshAmortizedDraw = makeMutationHook(operations.refreshAmortizedDraw)

export const useInternalDrawPurchasesGet = makeQueryHook(operations.internalDrawPurchasesGet)

export const useInternalDrawPurchasesCreate = makeMutationHook(operations.internalDrawPurchasesCreate)

export const useInternalDrawPurchaseGetOne = makeQueryHook(operations.internalDrawPurchaseGetOne)

export const useInternalDrawPurchaseUpdate = makeMutationHook(operations.internalDrawPurchaseUpdate)

export const useLocPurchaseGetOne = makeQueryHook(operations.locPurchaseGetOne)

export const useInternalDrawPurchaseChargeFees = makeMutationHook(operations.internalDrawPurchaseChargeFees)

export const useLoanStateGet = makeQueryHook(operations.loanStateGet)

export const useLoanStatePost = makeMutationHook(operations.loanStatePost)

export const useCompanyRotateTokenizationKey = makeMutationHook(operations.companyRotateTokenizationKey)

export const useCleanPaymentInstruments = makeMutationHook(operations.cleanPaymentInstruments)

export const useTestSpawnTask = makeQueryHook(operations.testSpawnTask)

export const useChargebackReportPost = makeMutationHook(operations.chargebackReportPost)

export const useInternalOperationStatusGet = makeQueryHook(operations.internalOperationStatusGet)

export const useGetMissingSnapshots = makeQueryHook(operations.getMissingSnapshots)

export const useInternalGetPaymentProcessors = makeQueryHook(operations.internalGetPaymentProcessors)

export const useInternalCreatePaymentProcessor = makeMutationHook(operations.internalCreatePaymentProcessor)

export const useInternalGetOnePaymentProcessor = makeQueryHook(operations.internalGetOnePaymentProcessor)

export const useInternalUpdatePaymentProcessor = makeMutationHook(operations.internalUpdatePaymentProcessor)

export const useInternalDeletePaymentProcessor = makeMutationHook(operations.internalDeletePaymentProcessor)

export const useInternalGetAchFiles = makeQueryHook(operations.internalGetAchFiles)

export const useInternalCreateAchFile = makeMutationHook(operations.internalCreateAchFile)

export const useInternalGetOneAchFile = makeQueryHook(operations.internalGetOneAchFile)

export const useInternalUpdateAchFile = makeMutationHook(operations.internalUpdateAchFile)

export const useGetLoanData = makeQueryHook(operations.getLoanData)

export const useGetSsoToken = makeQueryHook(operations.getSsoToken)

export const useSetSsoSharedSecret = makeMutationHook(operations.setSsoSharedSecret)

export const usePublishMessage = makeMutationHook(operations.publishMessage)
