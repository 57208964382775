import { styled } from 'styled-components'

import {
  errorContainer,
  onErrorContainer,
  onPrimaryContainer,
  onSuccessContainer,
  onSurface,
  onSurfaceDisabled,
  onWarningContainer,
  outline,
  primaryContainer,
  successContainer,
  surfaceBright,
  surfaceDim,
  surfaceDisabled,
  warningContainer,
} from 'core/styles'

export type ChipProps = {
  $variant?: 'primary' | 'success' | 'error' | 'warning' | 'dim' | 'disabled' | 'bright'
  $size?: 'sm' | 'md' | 'lg' | 'xl'
}

const Chip = styled.div<ChipProps>`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 0 8px;

  ${(p) =>
    p.$variant === 'primary' ?
      `
      background-color: ${primaryContainer};
      color: ${onPrimaryContainer};
      `
    : p.$variant === 'success' ?
      `
      background-color: ${successContainer};
      color: ${onSuccessContainer};
      `
    : p.$variant === 'error' ?
      `
      background-color: ${errorContainer};
      color: ${onErrorContainer};
      `
    : p.$variant === 'warning' ?
      `
      background-color: ${warningContainer};
      color: ${onWarningContainer};
      `
    : p.$variant === 'dim' ?
      `
      background-color: ${surfaceDim};
      color: ${onSurface};
      `
    : p.$variant === 'disabled' ?
      `
      background-color: ${surfaceDisabled};
      color: ${onSurfaceDisabled};
      `
    : `
      background-color: ${surfaceBright};
      color: ${onSurface};
      border: 1px solid ${outline};
      `}

  ${(p) =>
    p.$size === 'sm' ?
      `
      height: 16px;
      font-size: 10px;
      `
    : p.$size === 'lg' ?
      `
      height: 24px;
      font-size: 14px;
      `
    : p.$size === 'xl' ?
      `
      height: 28px;
      font-size: 16px;
      `
    : `
      height: 20px;
      font-size: 12px;
      `}
`

export default Chip
