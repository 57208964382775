import { createContext, useContext } from 'react'

const SessionPersonIdContext = createContext('')
export const useSessionPersonId = () => useContext(SessionPersonIdContext)

export const BorrowerIdContext = createContext('')
export const useBorrowerId = () => useContext(BorrowerIdContext)

export const BorrowerBasePathContext = createContext('') // this is a route path with the :wildcard, for use in routes
export const useBorrowerBasePath = () => useContext(BorrowerBasePathContext)

export const BorrowerBaseUrlContext = createContext('') // this is a route url with the actual ID for use in links, etc
export const useBorrowerBaseUrl = () => useContext(BorrowerBaseUrlContext)

export const BorrowerIsMobileContext = createContext(false)
export const useBorrowerIsMobile = () => useContext(BorrowerIsMobileContext)
