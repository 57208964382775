import { useState } from 'react'

import _ from 'lodash'
import { styled } from 'styled-components'

import Icon from 'core/components/Icon'
import Button from 'core/components/lib/Button'
import { useCompanyInfo } from 'core/stores'
import { variables } from 'core/styles'
import { useTime } from 'core/time'
import { SingleDayHours, Hours } from 'core/types/lenderConfig'

import formatDayOfWeekHours from './helpers/formatDayOfWeekHours'

type SingleDayHoursProps = {
  isBold?: boolean
}

const HoursSection = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${variables.colorBlack70};
`
const SingleDay = styled.div<SingleDayHoursProps>`
  display: flex;
  margin-bottom: 8px;
  font-weight: ${(p) => (p.isBold ? '500' : 'normal')};
`
const Day = styled.div`
  width: 120px;
`

const sortedDaysOfWeek = (dayOfWeek: number, hours?: Array<SingleDayHours>) => {
  // Shouldn't be falsy but placed here just in case,
  // and also to prevent TS error
  if (!hours || _.isEmpty(hours)) return null

  // Early return if dayOfWeek is Monday
  if (dayOfWeek === 0) return hours

  const arrayStart = hours.slice(dayOfWeek)
  const arrayEnd = hours.slice(0, dayOfWeek)
  return [...arrayStart, ...arrayEnd]
}

const DayOfWeekHoursList = ({ expanded = true }: { expanded?: boolean }) => {
  const [showHours, setShowHours] = useState(expanded)
  const { getDay, getToday } = useTime()
  const today = getToday()
  const day = getDay(today)
  const { timezone, openHours }: any = useCompanyInfo()
  const hours = openHours?.businessHours

  const { holidays, normal: businessHours }: Hours = hours || {}
  const holidaysByDate = _.keyBy(holidays, 'date')

  const reIndexedDay = day === 0 ? 6 : day - 1
  const sortedHours = sortedDaysOfWeek(reIndexedDay, businessHours)

  if (!sortedHours) return null

  return (
    <HoursSection>
      <div>
        {_.map(sortedHours, (singleDay: any, index: number) => {
          const { dayString, hoursString } = formatDayOfWeekHours(singleDay, holidaysByDate, timezone)
          if (!showHours && index > 0) return

          return (
            <SingleDay data-testid={`hours-for-${singleDay.day}`} isBold={index === 0} key={singleDay.day}>
              <Day>{dayString}</Day>
              <div>{hoursString}</div>
            </SingleDay>
          )
        })}
      </div>
      {showHours ?
        <Button data-testid='button-arrow-up' fontSize='24px' onClick={() => setShowHours(false)}>
          <Icon name='arrow_drop_up' />
        </Button>
      : <Button data-testid='button-arrow-down' fontSize='24px' onClick={() => setShowHours(true)}>
          <Icon name='arrow_drop_down' />
        </Button>
      }
    </HoursSection>
  )
}

export { SingleDay, sortedDaysOfWeek }
export default DayOfWeekHoursList
