import { FC, PropsWithChildren, useEffect } from 'react'

import { Scope } from '@sentry/react'

import { ErrorMessage } from 'core/components/lib/ErrorBoundary/defaultErrorBoundaries'
import { useToast } from 'core/hooks'
import ErrorLogging from 'core/system/ErrorLogging'

const UnhandledExceptions: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const toast = useToast()

  useEffect(() => {
    const listener = (event: PromiseRejectionEvent) => {
      ErrorLogging.withScope((scope: Scope) => {
        scope.setTag('ident', 'UnhandledExceptions')
        ErrorLogging.captureException(event.reason)
      })
      toast.error(<ErrorMessage error={event.reason} />)
    }

    window.addEventListener('unhandledrejection', listener)
    return () => window.removeEventListener('unhandledrejection', listener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export default UnhandledExceptions
