import { styled } from 'styled-components'

import type { CheckboxProps } from 'core/components/legacy/Checkbox/Checkbox'
import variables from 'core/styles/variables'

const OuterContainer = styled.div<{ margin: CheckboxProps['margin'] }>`
  /* This defines a block-level element for the
  ** component, meaning it will take up the
  ** same amount of width space as an inlin-block
  ** element but also start on a new line */
  display: table;
  margin: ${(p) => p.margin};
`
const InnerContainer = styled.div<{
  flexReverse: CheckboxProps['flexReverse']
  labelPosition: CheckboxProps['labelPosition']
}>`
  position: relative;
  align-items: center;
  ${(p) => (p.flexReverse ? 'flex-direction: row-reverse;' : '')}
  ${(p) => (p.labelPosition === 'top' ? 'padding: 0 0 32px;' : '')}

  .spinner {
    top: 0;
    left: 2px;
  }
`
const CheckboxLabel = styled.label<
  Pick<CheckboxProps, 'padding' | 'flexReverse' | 'labelPosition' | 'color' | 'fontSize'>
>`
  display: block;
  position: relative;
  cursor: pointer;
  padding: ${(p) =>
    p.padding ? p.padding
    : p.flexReverse ? '0 32px 0 0'
    : '0 0 0 32px'};
  ${(p) => (p.labelPosition === 'top' ? 'padding: 0;' : '')}
  line-height: 20px;
  color: ${(p) => p.color || variables.colorBlack90};
  font-size: ${(p) => p.fontSize || '16px'};

  &::before,
  &::after {
    box-sizing: border-box;
    position: absolute;
    border-radius: 10%;
    content: '';
  }

  &::before {
    ${(p) =>
      p.labelPosition === 'top' ?
        `
          left: 50%;
          transform: translateX(-50%);
          bottom: -24px;
        `
      : p.flexReverse ? 'top: 0; right: 0;'
      : 'top: 0; left: 0;'}
    width: 20px;
    height: 20px;
  }

  &::after {
    ${(p) =>
      p.labelPosition === 'top' ?
        `
          left: calc(50% - 5px);
          transform: translateX(-50%);
          bottom: -16px;`
      : p.flexReverse ? 'top: 9px; right: 13px;'
      : 'top: 9px; left: 5px;'}
    width: 2px;
    height: 2px;
  }
`
const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  &:checked + ${CheckboxLabel}::before {
    border: 2px solid ${(p) => p.theme.primary};
    background-color: ${(p) => p.theme.secondary};
  }

  &:not(:checked) + ${CheckboxLabel}::before {
    border: 2px solid ${variables.colorBlack70};
    background-color: ${variables.colorWhite};
  }

  &:checked + ${CheckboxLabel}::after {
    transform: rotate(45deg);
    transition: all 0.2s ease-in;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    background: white;
  }
  &:not(:checked):not(:indeterminate) + ${CheckboxLabel}::after {
    transform: scale(0);
    opacity: 0;
  }

  &:indeterminate + ${CheckboxLabel}::after {
    transition: all 0.2s ease-in;
    background: ${variables.colorBlack70};
    width: 10px;
  }
  &:disabled:indeterminate + ${CheckboxLabel}::after {
    background: white;
  }

  &:checked + ${CheckboxLabel}:after, &:indeterminate + ${CheckboxLabel}::after {
    opacity: 1;
  }

  &:disabled + ${CheckboxLabel} {
    cursor: not-allowed;
  }

  &:disabled + ${CheckboxLabel}::before {
    border: 2px solid ${variables.colorBlack50};
    background-color: ${variables.colorBlack50};
  }
`

export { InnerContainer, CheckboxInput, CheckboxLabel, OuterContainer }
