const headerWithToken = (token) => ({
  Accept: 'application/json',
  'Content-type': 'application/x-www.form-urlencoded',
  Authorization: `Bearer ${token}`,
})

export const GET = (token) => ({
  method: 'GET',
  headers: headerWithToken(token),
})

export const POST = (bodyData) => ({
  method: 'POST',
  headers: {
    'Content-type': 'application/json',
  },
  body: JSON.stringify(bodyData),
})

export const PUTwithToken = (token, bodyData) => ({
  method: 'PUT',
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(bodyData),
})

export const POSTwithToken = (token, bodyData, formData = false) => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  })

  if (!formData) {
    headers.append('Content-Type', 'application/json')
  }

  return {
    method: 'POST',
    headers,
    body: formData ? bodyData : JSON.stringify(bodyData),
  }
}

export const DELETE = (token) => ({
  method: 'DELETE',
  headers: headerWithToken(token),
})
