import { useEffect, FC } from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'

import AppRoutes from 'app/AppRoutes'
import DocumentUploader from 'noauth/pages/DocumentUploader'
import LoginLayout from 'noauth/pages/Login/LoginLayout'
import LoginManager from 'noauth/pages/Login/LoginManager'

import {
  getStoredToken,
  decodeToken,
  isTokenValid,
  getQueryUTM,
  removeUTMQueryParams,
  PEACH_UTM,
} from 'core/actions/helpers/tokenHelpers'
import getLoanTypes from 'core/actions/loanTypes/getLoanTypes'
import getPermissions from 'core/actions/permissions/getPermissions'
import { useSetGlobalValue } from 'core/badGlobalDoNotUse'
import { getOverride } from 'core/components/PeachOverrides/storage'
import { R } from 'core/helpers'
import { storeSession } from 'core/hooks'
import { useAppDispatch } from 'core/hooks/useAppStore'
import { useCompanyId } from 'core/stores'

import LoginRedirect from './loginRedirect/LoginRedirect'

const App: FC & {
  dependencies: {
    useDispatch: typeof useAppDispatch
    useEffect: typeof useEffect
    isTokenValid: typeof isTokenValid
  }
} = () => {
  const companyId = useCompanyId()

  const utm = getQueryUTM()
  const token = getStoredToken()
  const { personId: sessionPersonId } = decodeToken(token)

  const { useEffect, isTokenValid } = App.dependencies

  const dispatch = useAppDispatch()
  const tokenIsValid = isTokenValid(token)
  const forceUnhandledException = getOverride('forceUnhandledException')

  // this is a hack to allow reducers and other functions access to
  // current user's personId
  useSetGlobalValue('sessionPersonId', sessionPersonId)

  useEffect(() => {
    // The clearSavedSession() function in loginRedirectHelpers.js
    // will automatically clear the stored UTM values if the user has
    // an invalid session so we only need to store the provided UTM if the
    // user arrives with a valid session.
    if (!R.isNil(utm) && !R.isEmpty(utm) && tokenIsValid) {
      storeSession(PEACH_UTM, utm)
      removeUTMQueryParams()
    }
  }, [utm, tokenIsValid])

  /**
   * Fetching all loan types here since it only needs to loaded once.
   */
  useEffect(() => {
    if (companyId && tokenIsValid) {
      void dispatch(getLoanTypes({ companyId, key: 'GetLoanTypes' }))
      void dispatch(getPermissions({ key: 'GetPermissions' }))
    }
  }, [companyId, dispatch, tokenIsValid])

  // istanbul ignore next
  if (forceUnhandledException) throw Error('forceUnhandledException')

  return (
    <Switch>
      {/* routes where it doesn't matter if you're logged in */}
      <Route path='/login'>
        <LoginLayout>
          <LoginManager />
        </LoginLayout>
      </Route>

      {/*
      this route enables customers to link to our app and opt-into sending the user directly into the SSO flow.
      we don't do this for all /login routes to avoid getting the user into a redirect loop of
      in-app -> click sign-out -> redirect to /login -> redirect to SSO -> redirected back into app -> oops we're signed in again
      */}

      <Route path='/sso-login'>
        <Redirect to='/login?redirect=immediate' />
      </Route>

      <Route path='/document-uploader/:uploadDocumentLinkKey'>
        <DocumentUploader />
      </Route>

      <Route>
        {tokenIsValid ?
          <AppRoutes sessionPersonId={sessionPersonId} />
        : <LoginRedirect />}
      </Route>
    </Switch>
  )
}

App.dependencies = {
  isTokenValid,
  useDispatch: useAppDispatch,
  useEffect,
}

export default App
