/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const internalTeamsGet: OperationData<Types.InternalTeamsGet> = {
  method: 'get',
  name: 'internalTeamsGet',
  summary: 'Get teams',
  path: '/companies/{companyId}/teams',
  queryKey: ({ companyId }) => ['companies', companyId, 'teams'],
}

export const internalTeamsCreate: OperationData<Types.InternalTeamsCreate> = {
  method: 'post',
  name: 'internalTeamsCreate',
  summary: 'Create team',
  path: '/companies/{companyId}/teams',
  queryKey: ({ companyId }) => ['companies', companyId, 'teams'],
}

export const internalTeamGet: OperationData<Types.InternalTeamGet> = {
  method: 'get',
  name: 'internalTeamGet',
  summary: 'Get team by ID',
  path: '/companies/{companyId}/teams/{teamId}',
  queryKey: ({ companyId, teamId }) => ['companies', companyId, 'teams', teamId],
}

export const internalTeamUpdate: OperationData<Types.InternalTeamUpdate> = {
  method: 'put',
  name: 'internalTeamUpdate',
  summary: 'Update',
  path: '/companies/{companyId}/teams/{teamId}',
  queryKey: ({ companyId, teamId }) => ['companies', companyId, 'teams', teamId],
}

export const internalTeamDelete: OperationData<Types.InternalTeamDelete> = {
  method: 'delete',
  name: 'internalTeamDelete',
  summary: 'Delete team',
  path: '/companies/{companyId}/teams/{teamId}',
  queryKey: ({ companyId, teamId }) => ['companies', companyId, 'teams', teamId],
}

export const internalEmployeesGet: OperationData<Types.InternalEmployeesGet> = {
  method: 'get',
  name: 'internalEmployeesGet',
  summary: 'Get employees',
  path: '/companies/{companyId}/employees',
  queryKey: ({ companyId }) => ['companies', companyId, 'employees'],
}

export const internalEmployeeCreate: OperationData<Types.InternalEmployeeCreate> = {
  method: 'post',
  name: 'internalEmployeeCreate',
  summary: 'Create employee',
  path: '/companies/{companyId}/employees',
  queryKey: ({ companyId }) => ['companies', companyId, 'employees'],
}

export const internalEmployeeGetById: OperationData<Types.InternalEmployeeGetById> = {
  method: 'get',
  name: 'internalEmployeeGetById',
  summary: 'Get employee by ID',
  path: '/companies/{companyId}/employees/{employeeId}',
  queryKey: ({ companyId, employeeId }) => ['companies', companyId, 'employees', employeeId],
}

export const internalEmployeeUpdate: OperationData<Types.InternalEmployeeUpdate> = {
  method: 'put',
  name: 'internalEmployeeUpdate',
  summary: 'Update employee',
  path: '/companies/{companyId}/employees/{employeeId}',
  queryKey: ({ companyId, employeeId }) => ['companies', companyId, 'employees', employeeId],
}

export const internalSyncTwilioWorkerByEmployeeId: OperationData<Types.InternalSyncTwilioWorkerByEmployeeId> = {
  method: 'post',
  name: 'internalSyncTwilioWorkerByEmployeeId',
  summary: 'Sync employee to Twilio',
  path: '/companies/{companyId}/employees/{employeeId}/sync-twilio-worker',
  queryKey: ({ companyId, employeeId }) => ['companies', companyId, 'employees', employeeId, 'sync-twilio-worker'],
}

export const internalEmployeeAddToTeam: OperationData<Types.InternalEmployeeAddToTeam> = {
  method: 'put',
  name: 'internalEmployeeAddToTeam',
  summary: 'Add employee to team',
  path: '/companies/{companyId}/employees/{employeeId}/teams/{teamId}',
  queryKey: ({ companyId, employeeId, teamId }) => ['companies', companyId, 'employees', employeeId, 'teams', teamId],
}

export const internalEmployeeRemoveFromTeam: OperationData<Types.InternalEmployeeRemoveFromTeam> = {
  method: 'delete',
  name: 'internalEmployeeRemoveFromTeam',
  summary: 'Remove employee from team',
  path: '/companies/{companyId}/employees/{employeeId}/teams/{teamId}',
  queryKey: ({ companyId, employeeId, teamId }) => ['companies', companyId, 'employees', employeeId, 'teams', teamId],
}

export const teamsGet: OperationData<Types.TeamsGet> = {
  method: 'get',
  name: 'teamsGet',
  summary: 'Get teams',
  path: '/teams',
  queryKey: () => ['teams'],
}

export const teamsCreate: OperationData<Types.TeamsCreate> = {
  method: 'post',
  name: 'teamsCreate',
  summary: 'Create team',
  path: '/teams',
  queryKey: () => ['teams'],
}

export const teamGet: OperationData<Types.TeamGet> = {
  method: 'get',
  name: 'teamGet',
  summary: 'Get team by ID',
  path: '/teams/{teamId}',
  queryKey: ({ teamId }) => ['teams', teamId],
}

export const teamUpdate: OperationData<Types.TeamUpdate> = {
  method: 'put',
  name: 'teamUpdate',
  summary: 'Update',
  path: '/teams/{teamId}',
  queryKey: ({ teamId }) => ['teams', teamId],
}

export const teamDelete: OperationData<Types.TeamDelete> = {
  method: 'delete',
  name: 'teamDelete',
  summary: 'Delete team',
  path: '/teams/{teamId}',
  queryKey: ({ teamId }) => ['teams', teamId],
}

export const employeesGet: OperationData<Types.EmployeesGet> = {
  method: 'get',
  name: 'employeesGet',
  summary: 'Get employees',
  path: '/employees',
  queryKey: () => ['employees'],
}

export const employeeCreate: OperationData<Types.EmployeeCreate> = {
  method: 'post',
  name: 'employeeCreate',
  summary: 'Create employee',
  path: '/employees',
  queryKey: () => ['employees'],
}

export const employeeGetById: OperationData<Types.EmployeeGetById> = {
  method: 'get',
  name: 'employeeGetById',
  summary: 'Get employee by ID',
  path: '/employees/{employeeId}',
  queryKey: ({ employeeId }) => ['employees', employeeId],
}

export const employeeUpdate: OperationData<Types.EmployeeUpdate> = {
  method: 'put',
  name: 'employeeUpdate',
  summary: 'Update employee',
  path: '/employees/{employeeId}',
  queryKey: ({ employeeId }) => ['employees', employeeId],
}

export const employeeAddToTeam: OperationData<Types.EmployeeAddToTeam> = {
  method: 'put',
  name: 'employeeAddToTeam',
  summary: 'Add employee to team',
  path: '/employees/{employeeId}/teams/{teamId}',
  queryKey: ({ employeeId, teamId }) => ['employees', employeeId, 'teams', teamId],
}

export const employeeRemoveFromTeam: OperationData<Types.EmployeeRemoveFromTeam> = {
  method: 'delete',
  name: 'employeeRemoveFromTeam',
  summary: 'Remove employee from team',
  path: '/employees/{employeeId}/teams/{teamId}',
  queryKey: ({ employeeId, teamId }) => ['employees', employeeId, 'teams', teamId],
}
