import { styled } from 'styled-components'

import { useBootstrapStore } from 'core/stores'
import variables from 'core/styles/variables'

const Consents = styled.div`
  color: ${variables.colorBlack70};
`

const Content = styled.div`
  width: 100%;
  white-space: pre-wrap;
  color: ${variables.colorBlack70};

  a {
    text-decoration: none;
    color: ${variables.colorBluePrimary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const FooterConsents = () => {
  const { consent } = useBootstrapStore((data) => data?.company?.brandAssets?.copies ?? {})

  return <Consents>{consent && <Content dangerouslySetInnerHTML={{ __html: consent }} />}</Consents>
}

export default FooterConsents
