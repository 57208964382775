import _ from 'lodash'

const dayAbbreviationToFullDayStringMap = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
}

const getDayFromAbbrev = (abbrev) => dayAbbreviationToFullDayStringMap[_.lowerCase(abbrev)]

export default getDayFromAbbrev
