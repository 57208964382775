import { ChangeEvent, ChangeEventHandler, PropsWithChildren, ReactNode, Ref, forwardRef } from 'react'

import { Amount, CustomMaskedInput, Container, SubLabel, RadioInput, RadioLabel } from './RadioStyles'

type RadioProps = PropsWithChildren<{
  amount?: string | number
  bare?: boolean
  checked?: boolean
  customField?: boolean
  customFieldPlaceholder?: string | number
  customInputRef?: Ref<HTMLInputElement>
  disabled?: boolean
  getCustomAmount?: (value: string) => void
  id?: string
  label?: string
  name?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  subLabel?: ReactNode
  value?: string | number
  width?: string
}>

/** @deprecated Use Radio from core/components */
const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      amount,
      bare,
      checked,
      children,
      customField,
      customFieldPlaceholder,
      customInputRef,
      disabled,
      getCustomAmount,
      id,
      label,
      name,
      onChange,
      subLabel,
      value,
      width,
    },
    ref,
  ) => (
    <Container className='radio'>
      <RadioInput
        checked={checked}
        disabled={disabled}
        id={id || label}
        name={name || label}
        onChange={onChange}
        ref={ref}
        value={value}
      />
      {bare ?
        <RadioLabel data-testid='label-bare' htmlFor={id || label} width={width}>
          {'\u00A0'}
        </RadioLabel>
      : children ?
        <RadioLabel data-testid='label-with-children' htmlFor={id || label} width={width}>
          {children}
        </RadioLabel>
      : !customField ?
        <>
          <RadioLabel data-testid='label-no-custom-field' htmlFor={id || label} width={width}>
            {label}
          </RadioLabel>
          {!!subLabel && <SubLabel>{subLabel}</SubLabel>}
          {!!amount && <Amount>{amount}</Amount>}
        </>
      : <>
          <RadioLabel htmlFor={id || label} width={width}>
            {label}
          </RadioLabel>
          <CustomMaskedInput
            customInputRef={customInputRef}
            inputType='amount'
            onChange={(e: ChangeEvent<HTMLInputElement>) => getCustomAmount?.(e.target.value)}
            placeholder={customFieldPlaceholder}
          />
        </>
      }
    </Container>
  ),
)

Radio.displayName = 'Radio'

export default Radio
