import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { useCompanyInfo } from 'core/stores'
import { UserType } from 'core/types'

import useTitle from './useTitle'

const getPage = (pathname: string) => {
  const lastSlash = pathname.lastIndexOf('/')
  const page = pathname.substring(lastSlash + 1)
  return page
    .split('-')
    .join(' ')
    .replace(/\b\w/g, (l) => l.toUpperCase())
}

const useSetPageTitle = ({ userType }: { userType: UserType }) => {
  const [title, setTitle] = useState('')

  const { brandName }: any = useCompanyInfo()
  const { pathname } = useLocation()

  const isLogin = pathname.includes('login')
  const isLoanApp = pathname.includes('loans') || pathname.includes('account-settings')
  const isCRM = pathname.includes('crm')
  const isBorrower = userType === 'borrower'
  const isAgent = userType === 'agent'

  useEffect(() => {
    const brandLabel = brandName ? `${brandName} -` : ''

    if (isLogin) {
      const formType =
        isBorrower ? ' Account'
        : isAgent ? ' Agent'
        : ''
      setTitle(`${brandLabel}${formType} Login`)
    } else if (isCRM) {
      const isManagerDashboard = pathname.includes('manager-dashboard')
      const isBorrowerView = pathname.includes('borrowers')
      const isCompanyView = pathname.includes('companies')

      if (isManagerDashboard) {
        setTitle(`${brandLabel} Agent Portal - Manager Dashboard`)
      } else if (isBorrowerView) {
        setTitle(`${brandLabel} Agent Portal - Borrower CRM`)
      } else if (isCompanyView) {
        setTitle(`${brandLabel} Agent Portal - Company CRM`)
      } else {
        setTitle(`${brandLabel} Agent Portal`)
      }
    } else if (isLoanApp) {
      const page = getPage(pathname)
      setTitle(`${brandLabel} ${isAgent ? 'Viewing ' : ''}Customer Portal - ${page}`)
    } else {
      setTitle(brandName || '')
    }
  }, [brandName, isAgent, isBorrower, isCRM, isLoanApp, isLogin, pathname, userType])

  useTitle(title.trim())
}

export default useSetPageTitle
