import { useMemo } from 'react'

import { useLatest } from 'react-use'

import { R } from 'core/helpers'

const useDebounce = <F extends (...args: any) => any>(fn: F, waitMs = 1000) => {
  const latestFn = useLatest(fn)

  return useMemo(
    () => R.debounce((...args) => latestFn.current.call(undefined, ...args), { waitMs }).call,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [latestFn],
  )
}

export default useDebounce
