/**
 * Prevent trailing double spaces
 */
export default function disableTrailingSpaces(e) {
  const input = e.target
  const val = input.value
  const end = input.selectionEnd
  if (e.keyCode === 32 && (val[end - 1] === ' ' || val[end] === ' ')) {
    e.preventDefault()
    return false
  }
}
