import { useEffect } from 'react'

import { signOut } from 'app/loginRedirect/loginRedirectHelpers'

import logout from 'core/api/logout'

const signOutAndRedirect = async () => {
  try {
    await logout()
  } catch {
    // Ignore error
  }

  signOut()
}

const SignOutRoute = () => {
  useEffect(() => {
    void signOutAndRedirect()
  }, [])

  return null
}

export default SignOutRoute
