import { checkTimeordate, isTimestring } from 'core/time/internal/types'
import mtz from 'core/time/internal/utils/mtz'

const makeAddDays = (context = {}) => {
  const { timezone } = context

  const addDays = (timeordate, days) => {
    checkTimeordate(timeordate)

    const mDate = mtz(timeordate, timezone).add(days, 'days')

    return isTimestring(timeordate) ? mDate.toISOString() : mDate.format('Y-MM-DD')
  }

  return addDays
}

export default makeAddDays
