import React, { useState } from 'react'

import { R } from 'core/helpers'

export type PendingEventHandler<T = React.MouseEvent<HTMLButtonElement, MouseEvent>> = (
  event: T,
) => Promise<unknown> | void

const usePendingCallback = <T extends Array<unknown>, R>(cb?: (...args: T) => R) => {
  const [isPending, setIsPending] = useState(false)

  return [
    isPending,
    (...args: T) => {
      const res = cb?.(...args)

      if (res instanceof Promise) {
        setIsPending(true)
        res.finally(() => setIsPending(false)).catch(R.noop)
      }

      return res
    },
  ] as const
}

export default usePendingCallback
