// Third party --------------
import _ from 'lodash'

// Rest ---------------------
import getDayFromAbbrev from 'core/helpers/getDayFromAbbrev'
import { makeTime } from 'core/time'

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const dayOfWeekString = (date, timezone) => {
  const { format } = makeTime({ timezone })
  return format(date, 'dddd')
}

const getDateByDayOfWeek = (dayOfWeek, timezone) => {
  const { addDays, format, getToday } = makeTime({ timezone })
  const today = getToday()
  const todayDOW = _.toLower(dayOfWeekString(today))
  const argDOW = _.toLower(dayOfWeek)
  const todayIndex = _.indexOf(daysOfWeek, todayDOW)
  const argIndex = _.indexOf(daysOfWeek, argDOW)

  if (todayIndex === argIndex) {
    return format(today, 'YYYY-MM-DD')
  } else {
    const argDate = addDays(today, argIndex - todayIndex)
    return format(argDate, 'YYYY-MM-DD')
  }
}

const formatDayOfWeekHours = (singleDay, holidaysByDate, timezone) => {
  const { format, parse } = makeTime({ timezone })

  // We need to keep this method inside formatDayOfWeekHours
  // because the timzeone value becomes undefined inside
  // the hoursCollection map below.
  const formatHours = (hours) => {
    const parsedTime = parse(hours, 'HH:mm')
    return format(parsedTime, 'h:mmA')
  }

  const { closed, day, hoursList } = singleDay
  const dayString = getDayFromAbbrev(day)
  const thisDate = getDateByDayOfWeek(dayString, timezone)

  let hoursString

  if (holidaysByDate[thisDate]) {
    const { name } = holidaysByDate[thisDate]
    hoursString = `Closed${name ? ` due to ${name}` : ''}`
  } else if (singleDay['24hours']) {
    hoursString = 'Open 24 hours'
  } else if (closed) {
    hoursString = 'Closed'
  } else {
    const hoursCollection = _.map(hoursList, (set) => `${formatHours(set[0])}-${formatHours(set[1])}`)
    hoursString = _.join(hoursCollection, ', ')
  }

  return { dayString, hoursString }
}

export default formatDayOfWeekHours
