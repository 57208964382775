import keyBy from 'lodash/keyBy'
import pickBy from 'lodash/pickBy'

import { GET_CONSENTS } from 'core/actions/constants'

const initialState = {
  byId: {},
  allIds: [],
}

const consents = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSENTS: {
      const byId = {
        ...pickBy(state.byId, (item) => item),
        ...keyBy(action.payload, 'id'),
      }
      return {
        ...state,
        byId,
        allIds: Object.keys(byId),
      }
    }
    default:
      return state
  }
}

export default consents
