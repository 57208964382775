import { checkTimeordate, isTimestring } from 'core/time/internal/types'
import mtz from 'core/time/internal/utils/mtz'

const makeSubtract = (context = {}) => {
  const { timezone } = context

  const subtract = (timeordate, duration) => {
    checkTimeordate(timeordate)

    const { years, months, weeks, days, hours, minutes, seconds } = duration
    const mDate = mtz(timeordate, timezone)
    if (years) {
      mDate.subtract(years, 'years')
    }
    if (months) {
      mDate.subtract(months, 'months')
    }
    if (weeks) {
      mDate.subtract(weeks, 'weeks')
    }
    if (days) {
      mDate.subtract(days, 'days')
    }
    if (hours) {
      mDate.subtract(hours, 'hours')
    }
    if (minutes) {
      mDate.subtract(minutes, 'minutes')
    }
    if (seconds) {
      mDate.subtract(seconds, 'seconds')
    }

    return isTimestring(timeordate) ? mDate.toISOString() : mDate.format('Y-MM-DD')
  }

  return subtract
}

export default makeSubtract
