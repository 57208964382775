/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const getPromiseToPayPlansByCompany: OperationData<Types.GetPromiseToPayPlansByCompany> = {
  method: 'get',
  name: 'getPromiseToPayPlansByCompany',
  summary: 'Get Promise to Pay Plans for a company',
  path: '/promise-to-pay-plans',
  queryKey: () => ['promise-to-pay-plans'],
}

export const getPromiseToPayPlansByLoan: OperationData<Types.GetPromiseToPayPlansByLoan> = {
  method: 'get',
  name: 'getPromiseToPayPlansByLoan',
  summary: 'Get Promise to Pay Plans for a Loan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'promise-to-pay-plans'],
}

export const createPromiseToPayPlan: OperationData<Types.CreatePromiseToPayPlan> = {
  method: 'post',
  name: 'createPromiseToPayPlan',
  summary: 'Create Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'promise-to-pay-plans'],
}

export const getPromiseToPayPlan: OperationData<Types.GetPromiseToPayPlan> = {
  method: 'get',
  name: 'getPromiseToPayPlan',
  summary: 'Get Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans/{promiseToPayPlanId}',
  queryKey: ({ personId, loanId, promiseToPayPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promise-to-pay-plans',
    promiseToPayPlanId,
  ],
}

export const cancelPromiseToPayPlan: OperationData<Types.CancelPromiseToPayPlan> = {
  method: 'put',
  name: 'cancelPromiseToPayPlan',
  summary: 'Cancel Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans/{promiseToPayPlanId}/cancel',
  queryKey: ({ personId, loanId, promiseToPayPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promise-to-pay-plans',
    promiseToPayPlanId,
    'cancel',
  ],
}

export const evaluatePromiseToPayPlan: OperationData<Types.EvaluatePromiseToPayPlan> = {
  method: 'put',
  name: 'evaluatePromiseToPayPlan',
  summary: 'Evaluate Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans/{promiseToPayPlanId}/evaluate',
  queryKey: ({ personId, loanId, promiseToPayPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promise-to-pay-plans',
    promiseToPayPlanId,
    'evaluate',
  ],
}

export const internalGetPromiseToPayPlansByCompany: OperationData<Types.InternalGetPromiseToPayPlansByCompany> = {
  method: 'get',
  name: 'internalGetPromiseToPayPlansByCompany',
  summary: 'Get Promise to Pay Plans for a company',
  path: '/companies/{companyId}/promise-to-pay-plans',
  queryKey: ({ companyId }) => ['companies', companyId, 'promise-to-pay-plans'],
}

export const internalEvaluatePromiseToPayPlan: OperationData<Types.InternalEvaluatePromiseToPayPlan> = {
  method: 'put',
  name: 'internalEvaluatePromiseToPayPlan',
  summary: 'Evaluate Promise to Pay Plan, does not require personId',
  path: '/companies/{companyId}/loans/{loanId}/promise-to-pay-plans/{promiseToPayPlanId}/evaluate',
  queryKey: ({ companyId, loanId, promiseToPayPlanId }) => [
    'companies',
    companyId,
    'loans',
    loanId,
    'promise-to-pay-plans',
    promiseToPayPlanId,
    'evaluate',
  ],
}

export const internalCreateBackdatedPromiseToPayPlan: OperationData<Types.InternalCreateBackdatedPromiseToPayPlan> = {
  method: 'post',
  name: 'internalCreateBackdatedPromiseToPayPlan',
  summary: 'Internal - Create Backdated Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/internal-backdated-promise-to-pay-plans',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'internal-backdated-promise-to-pay-plans'],
}
