import { BaseException } from 'pdfjs-dist'

import * as R from './remeda'
import * as S from './string'

const parseError = (error: unknown, fallback = 'Unknown error', companyId?: string) => {
  if (R.isObject(error) && error.status === 403) {
    const permissions =
      'data' in error && R.isArray(error.data) ?
        R.pipe(
          error.data,
          R.filter(
            (tuple): tuple is [string, string] =>
              R.isArray(tuple) && tuple.length === 2 && tuple[0] === companyId && R.isString(tuple[1]),
          ),
          R.map(R.prop(1)),
        )
      : []

    return permissions.length > 0 ?
        `You do not have the ${R.join(permissions, ', ')} permission(s).`
      : 'You do not have the required permissions.'
  }

  return (
    error instanceof BaseException ? 'Unable to load the PDF.'
    : !error || (R.isEmpty(error as any) && !(error instanceof Error)) ? undefined
    : S.capitalize(
        R.isString(error) ? error
        : typeof error !== 'object' ? fallback
        : 'detail' in error && typeof error.detail === 'string' ? error.detail
        : 'message' in error && typeof error.message === 'string' ? error.message
        : fallback,
      )
  )
}

export default parseError
