import { R } from 'core/helpers'
import { PendingEventHandler } from 'core/hooks'
import { NotUndefined } from 'core/types'

import BaseSelect, { CommonProps } from './BaseSelect'
import { findByValue } from './components'

export type SelectProps<Value> = Omit<CommonProps<false, Value>, 'value' | 'isMulti'> & {
  value: Value | null
  onChange?: PendingEventHandler<Value | null>
}

export const Select = <Value extends NotUndefined = string>({ value, onChange, ...props }: SelectProps<Value>) => (
  <BaseSelect
    onChange={(option) => onChange?.(R.isNil(option) ? option : option.value)}
    value={R.isNil(value) ? value : findByValue(props.options, value)} // Workaround for JedWatson/react-select#5729
    {...props}
  />
)

export type MultiSelectProps<Value> = Omit<CommonProps<true, Value>, 'value' | 'isMulti'> & {
  value: Array<Value> | null
  onChange?: PendingEventHandler<Array<Value> | null>
}

export const MultiSelect = <Value extends NotUndefined = string>({
  value,
  onChange,
  ...props
}: MultiSelectProps<Value>) => (
  <BaseSelect
    onChange={(option) => onChange?.(R.isNil(option) ? option : R.map(option, R.prop('value')))}
    value={
      R.isNil(value) ? value : (
        R.pipe(
          value,
          R.map((v) => findByValue(props.options, v)),
          R.compact,
        )
      )
    } // Workaround for JedWatson/react-select#5729
    {...props}
    isMulti
  />
)
