import { FC } from 'react'

import { Route, Switch } from 'react-router-dom'

import { AgentRoutes, BorrowerRoutes } from 'app/routes'
import AdminWrapper from 'app/routes/AdminWrapper'
import SignOutRoute from 'app/routes/SignOutRoute'

import { useBootstrapStore } from 'core/stores'

import LoginRedirect from './loginRedirect/LoginRedirect'

type AppRoutesProp = {
  sessionPersonId: string
}

const AppRoutes: FC<AppRoutesProp> = ({ sessionPersonId }) => {
  const { appDomainType, authType } = useBootstrapStore() ?? {}

  return (
    <Switch>
      <Route path='/sign-out'>
        <SignOutRoute />
      </Route>

      <Route
        render={() => {
          switch (appDomainType) {
            case 'agent':
              return <AgentRoutes authType={authType} />

            case 'borrower':
              return (
                <BorrowerRoutes
                  authType={authType}
                  basePath=''
                  baseUrl=''
                  borrowerId={sessionPersonId} // the user is the borrower
                  key={sessionPersonId}
                  userType='borrower'
                />
              )

            case 'admin':
              return <AdminWrapper />

            default:
              return <LoginRedirect />
          }
        }}
      />
    </Switch>
  )
}

export default AppRoutes
