import moment from 'moment-timezone'

/** This is so that we don't have to worry about when
 * timezone is falsy. It will just default to local
 * time. Easier to do this once in a util function than
 * remember to do it over and over when using moment
 * directly. If you want "now" but in a timezone, just
 * pass in (null, timezone).
 */

/**
 * moment-timezone takes the same args as moment. The
 * difference is moment-timezone will use the last arg
 * as the timezone param. In our current use case we
 * will assume if arg3 is given, then arg2 is either a
 * string or an array of strings that determines the format
 * of timeordate as it is, not what the output should be.
 */
const mtz = (timeordate, arg2, arg3) => {
  let format
  let timezone
  if (!arg3) {
    timezone = arg2
  } else {
    format = arg2
    timezone = arg3
  }
  // This is to avoid passing null into moment. It prefers undefined.
  const _timeordate = timeordate ?? undefined

  return (
    timezone ?
      arg3 ? moment.tz(_timeordate, format, timezone)
      : moment.tz(_timeordate, timezone)
    : moment(_timeordate)
  )
}

export default mtz
