import { APIendpoint, LOADING, GET_LOAN_BY_ID, getStoredToken } from 'core/actions/constants'
import { GET } from 'core/api/legacyApi'
import { getGlobalValue } from 'core/badGlobalDoNotUse'
import { handleErrors } from 'core/helpers/handleErrors'

const GET_EXPECTED_PAYMENTS = 'GET_EXPECTED_PAYMENTS'
const GET_AUTOPAY_DATA = 'GET_AUTOPAY_DATA'
const CLEAR_AUTOPAY_DATA = 'CLEAR_AUTOPAY_DATA'
const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
const GET_BALANCES = 'GET_BALANCES'
const GET_PERIODS = 'GET_PERIODS'
const GET_RATES = 'GET_RATES'
const GET_OBLIGATIONS = 'GET_OBLIGATIONS'

const getPersonId = () => getGlobalValue('borrowerPersonId')

const getLoanEndpoint = (loanId, suffix = '') => `${APIendpoint}/people/${getPersonId()}/loans/${loanId}${suffix}`

const onLoading = (dispatch, key) => {
  if (key) dispatch({ type: LOADING, key, status: 'loading' })
}
const onSuccess = (dispatch, key) => {
  if (key) dispatch({ type: LOADING, key, status: 'success' })
}
const onError = (dispatch, key) => {
  if (key) dispatch({ type: LOADING, key, status: 'error' })
}

const getLoanById =
  ({ loanId, key }) =>
  async (dispatch) => {
    onLoading(dispatch, key)

    const endpoint = getLoanEndpoint(loanId, '?includeAdditionalBorrowers=true')
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299
    const loan = responseBody.data

    if (isSuccess) {
      dispatch({ type: GET_LOAN_BY_ID, payload: { loanId, data: loan } })
    }

    if (isSuccess) {
      onSuccess(dispatch, key)
    } else {
      onError(dispatch, key)
    }

    return loan
  }

const getExpectedPayments = (loanId) => async (dispatch) => {
  const endpoint = getLoanEndpoint(loanId, '/expected-payments')

  const response = await fetch(endpoint, GET(getStoredToken())).then(handleErrors)

  // get all data
  dispatch({
    type: GET_EXPECTED_PAYMENTS,
    payload: { loanId, data: response.data },
  })
}

const getAutopay = (loanId) => async (dispatch) => {
  const endpoint = getLoanEndpoint(loanId, '/autopay')

  const response = await fetch(endpoint, GET(getStoredToken())).then(handleErrors)

  dispatch({
    type: GET_AUTOPAY_DATA,
    payload: { loanId, data: response.data },
  })
}

const getTransactions =
  ({ loanId, key, fromEffectiveDate, toEffectiveDate }) =>
  async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    let endpoint
    if (fromEffectiveDate && toEffectiveDate) {
      endpoint = getLoanEndpoint(
        loanId,
        `/transactions?serviceCreditTypes=badDebt,bankruptcy,deceased,interestAdjustment,fraud,usuryCap,serviceAgent,serviceSupervisor,settlementOfDebt&serviceCreditTypes=serviceSupervisor&serviceCreditTypes=settlementOfDebt&isVirtual=false&fromEffectiveDate=${fromEffectiveDate}&toEffectiveDate=${toEffectiveDate}&limit=100`,
      )
    } else {
      endpoint = getLoanEndpoint(
        loanId,
        '/transactions?serviceCreditTypes=badDebt,bankruptcy,deceased,interestAdjustment,fraud,usuryCap,serviceAgent,serviceSupervisor,settlementOfDebt&serviceCreditTypes=serviceSupervisor&serviceCreditTypes=settlementOfDebt&isVirtual=false&limit=100',
      )
    }
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: GET_TRANSACTIONS,
        payload: {
          loanId,
          data: responseBody.data,
        },
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }

const getBalances = (loanId) => async (dispatch) => {
  const endpoint = getLoanEndpoint(loanId, '/balances?isRounded=true')

  const response = await fetch(endpoint, GET(getStoredToken())).then(handleErrors)

  if (response.status === 200) {
    dispatch({
      type: GET_BALANCES,
      payload: { loanId, data: response.data },
    })
  }
}

const getPeriods = (loanId) => async (dispatch) => {
  const endpoint = getLoanEndpoint(loanId, '/periods')

  const response = await fetch(endpoint, GET(getStoredToken())).then(handleErrors)

  if (response.status === 200) {
    dispatch({
      type: GET_PERIODS,
      payload: { loanId, data: response.data },
    })
  }
}

const getRates = (loanId) => async (dispatch) => {
  const endpoint = getLoanEndpoint(loanId, '/rates')

  const response = await fetch(endpoint, GET(getStoredToken())).then(handleErrors)

  if (response.status === 200) {
    dispatch({
      type: GET_RATES,
      payload: { loanId, data: response.data },
    })
  }
}

export const getObligations = (loanId) => (dispatch) => {
  const endpoint = getLoanEndpoint(loanId, '/obligations')

  return fetch(endpoint, GET(getStoredToken()))
    .then(handleErrors)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_OBLIGATIONS,
          payload: {
            loanId,
            periods: response.data.obligations,
            global: {
              daysOverdue: response.data.daysOverdue,
              isOverdue: response.data.isOverdue,
              overdueAmount: response.data.overdueAmount,
            },
          },
        })
      }
    })
}

const clearAutopayData = (loanId) => (dispatch) => dispatch({ type: CLEAR_AUTOPAY_DATA, loanId })

const refreshLoanAndRelatedData = (loanId, options = {}) => {
  const { key } = options
  return async (dispatch) => {
    const loanPromise = dispatch(getLoanById({ loanId, key }))

    dispatch(getExpectedPayments(loanId))
    dispatch(getTransactions({ loanId, key: 'getTransactions' }))
    dispatch(getBalances(loanId))
    dispatch(getPeriods(loanId))
    dispatch(getRates(loanId))
    dispatch(getObligations(loanId))

    const loan = await loanPromise

    if (loan?.current?.autopayEnabled) {
      dispatch(getAutopay(loanId))
    } else {
      dispatch(clearAutopayData(loanId))
    }
  }
}

export {
  GET_EXPECTED_PAYMENTS,
  GET_AUTOPAY_DATA,
  CLEAR_AUTOPAY_DATA,
  GET_TRANSACTIONS,
  GET_BALANCES,
  GET_PERIODS,
  GET_RATES,
  GET_OBLIGATIONS,
  getLoanById,
  getExpectedPayments,
  getTransactions,
  getBalances,
  getPeriods,
  getRates,
  getAutopay,
  clearAutopayData,
  refreshLoanAndRelatedData,
}
