import { useState } from 'react'

import PropTypes from 'prop-types'

import { minDelay } from 'noauth/pages/Login/loginHelpers'

import { useFlags, useWrite } from 'core/hooks'

import OneTimeCodeForm from './OneTimeCodeForm'
import OneTimeCodeOverlay from './OneTimeCodeOverlay'
import { formatOtcError } from './otcHelpers'

const OneTimeCodeModal = (props) => {
  const { onOneTimeCode, onRequestCodeToChannel, onClose, userType, initialChannel } = props

  const { borrowerDisableSmsLoginOption } = useFlags()

  const [channel, setChannel] = useState(initialChannel || 'email')

  const [loginWithCode, loggingIn, , loginError, clearLoginRequest] = useWrite((code) =>
    onOneTimeCode(code, channel === 'email' ? 'oneTimeCodeEmail' : 'oneTimeCodeText'),
  )

  const [sendRequestCode, requestingCode, codeResp, codeError, clearCodeRequest] = useWrite((channel) => {
    const promise = onRequestCodeToChannel(channel)
    return minDelay(promise, 750)
  })

  const error = loginError || codeError

  const errorMessage = formatOtcError(error)

  const handleSwitchChannel = (channel) => {
    setChannel(channel)
    sendRequestCode(channel)
    clearLoginRequest()
    clearCodeRequest()
  }

  const sending = loggingIn || requestingCode

  const formContents = (() => {
    const sentMessage = !requestingCode && codeResp && (channel === 'voice' ? 'Call made' : 'Code sent')

    const formProps = {
      key: channel,
      onOneTimeCode: loginWithCode,
      sending,
      sentMessage,
    }

    if (channel === 'email') {
      const onTryAnotherWay = userType === 'borrower' ? () => handleSwitchChannel('text') : null
      return (
        <OneTimeCodeForm
          {...formProps}
          onEmailMe={() => handleSwitchChannel('email')}
          onTryAnotherWay={borrowerDisableSmsLoginOption ? undefined : onTryAnotherWay}
          subtitle='Check your inbox for an email with the verification code and enter it here'
          tryAnotherWayLabel={borrowerDisableSmsLoginOption ? undefined : 'Try another way to login, text me'}
        />
      )
    }

    if (channel === 'text' || channel === 'voice') {
      const onTryAnotherWay = () => handleSwitchChannel('email')
      return (
        <OneTimeCodeForm
          {...formProps}
          onCallMe={() => handleSwitchChannel('voice')}
          onTextMe={() => handleSwitchChannel('text')}
          onTryAnotherWay={onTryAnotherWay}
          subtitle='Check your phone for the verification code and enter it here'
          tryAnotherWayLabel='Try another way to login, email me'
        />
      )
    }
  })()

  return (
    <OneTimeCodeOverlay errorMessage={errorMessage} onClose={onClose}>
      {formContents}
    </OneTimeCodeOverlay>
  )
}

OneTimeCodeModal.propTypes = {
  onOneTimeCode: PropTypes.func,
  userType: PropTypes.oneOf(['agent', 'borrower']),
  initialChannel: PropTypes.oneOf(['email', 'text', 'voice']),
  onRequestCodeToChannel: PropTypes.func,
  onClose: PropTypes.func,
}

export default OneTimeCodeModal
