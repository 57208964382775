import { styled } from 'styled-components'

/** @deprecated Use an "aria-label" attribute instead */
const SrOnly = styled.span`
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
`

export default SrOnly
