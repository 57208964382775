import { styled } from 'styled-components'

import { variables } from 'core/styles'

export const StyledTableWrapper = styled.div<{ $bordered?: boolean }>`
  display: inline-block;
  position: relative;
  ${(p) =>
    p.$bordered &&
    `border-radius: 8px;
  border: 1px solid ${variables.colorBlack20};`}
  max-width: 100%;
  overflow: auto;
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const StyledTableRow = styled.tr<{
  $bordered: boolean
  $summary?: boolean
  $highlight?: boolean
  $selected?: boolean
}>`
  ${(p) => p.$bordered && `border-bottom: 1px solid ${variables.colorBlack20};`}
  ${(p) => (p.$summary || p.$selected) && `background: ${variables.colorBlack10};`}

  &:last-child {
    border-bottom: none;
  }

  ${(p) =>
    p.$highlight &&
    `&:hover {
    background-color: ${variables.colorBlack10};
    cursor: pointer;
  }`}
`

export const StyledTableCell = styled.td<{
  $paddingTop?: string
  $paddingBottom?: string
  $textAlign?: 'left' | 'right' | 'center'
}>`
  position: relative;
  padding: ${(p) => p.$paddingTop || '24px'} 24px ${(p) => p.$paddingBottom || '24px'};
  width: auto;
  vertical-align: top;
  text-align: ${(p) => p.$textAlign || 'left'};

  &:first-child {
    border-left: none;
  }
`

export const StyledTableHeaderCell = styled.th<{
  onClick: unknown
  $sorted: boolean
}>`
  border-bottom: 1px solid ${variables.colorBlack20};
  padding: 8px 24px;
  text-align: left;
  color: ${variables.colorBlack90};
  font-weight: normal;

  &:first-child {
    border-left: none;
  }

  ${(p) =>
    !!p.onClick &&
    `&:hover {
    background-color: ${variables.colorBlack10};
    cursor: pointer;
  }`}

  ${(p) => p.$sorted && 'font-weight: 400;'}
`

export const StyledTableHeaderContent = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const StyledTableButtonWrapper = styled.span<{ $justifyContent?: string }>`
  display: flex;
  justify-content: ${(p) => p.$justifyContent ?? 'flex-end'};
  white-space: nowrap;
`

export const StyledTableValue = styled.span<{
  $deemphasized: boolean
  $textAlign: 'left' | 'center' | 'right'
}>`
  display: block;
  text-align: ${(p) => p.$textAlign};
  white-space: nowrap;
  ${(p) => p.$deemphasized && `color: ${variables.colorBlack50};`};
`
