import { useLayoutEffect } from 'react'

/* This is Bad™.  DO NOT USE

TODO:  may API calls were previously getting a person ID out of the token

this works fine when the actor and the subject of an API call are the same person 
but in the context of an agent acting on behalf of a borrower, this is not longer a safe assumption.  

The correct answer is likely that these API calls should be updated 
to take personId (or more accurately named borrowerPersonId), 
as an argument.  

but in leiu of making that change all at one, 
this global providers an equivelant call signature to `getPersonIDFromToken`, 
but is a bit of state that can be synchronoized as the user navigates 
to different borrowers in the CRM.  
*/

const badGlobalDoNotUse = {
  sessionPersonId: undefined,
  borrowerPersonId: undefined,
}

window.personIds = badGlobalDoNotUse

/* 
* sessionPersonId  - the person ID decoded from the JWT token 

* borrowerPersonId 
  - on an agent portal, this is set for certain routes
  - on a borrower portal, this will be the personId from the JWT 
*/

const useSetGlobalValue = (key, value) => {
  useLayoutEffect(() => {
    badGlobalDoNotUse[key] = value

    return () => {
      badGlobalDoNotUse[key] = undefined
    }
  }, [key, value])
}

const getGlobalValue = (key) => {
  const value = badGlobalDoNotUse[key]

  return value
}

export { useSetGlobalValue, getGlobalValue }
