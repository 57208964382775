import PropTypes from 'prop-types'
import { styled } from 'styled-components'

import variables from 'core/styles/variables'

import HelpText from './HelpText'

export const TitleText = styled.h2`
  margin-right: 8px;
  margin-left: 24px;
  color: ${variables.colorBlack90};
  font-size: 2.2rem;
  ${(p) =>
    p.ellipsize &&
    `
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`

const TitleWithEducationTip = (props) => {
  const {
    ellipsize,
    helpText,
    helpTextMargin,
    helpTitle,
    iconMargin,
    removeHelpTextTitle,
    statusIcon,
    subTitle,
    title,
  } = props

  return (
    <>
      {title && (
        <HelpText
          helpTextMargin={helpTextMargin}
          iconMargin={iconMargin}
          text={helpText}
          title={removeHelpTextTitle ? undefined : helpTitle}
        >
          <TitleText ellipsize={ellipsize}>
            {title} {statusIcon}
          </TitleText>
        </HelpText>
      )}

      {subTitle && <span>{subTitle}</span>}
    </>
  )
}

TitleWithEducationTip.propTypes = {
  ellipsize: PropTypes.bool,
  helpText: PropTypes.node,
  helpTextMargin: PropTypes.string,
  helpTitle: PropTypes.string,
  iconMargin: PropTypes.string,
  removeHelpTextTitle: PropTypes.bool,
  statusIcon: PropTypes.node,
  subTitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default TitleWithEducationTip
