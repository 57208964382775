import { SvgIcon } from './SvgIcon'

const VisaIcon = () => (
  <SvgIcon>
    <path
      d='M40 31.428a2.365 2.365 0 0 1-2.364 2.365H2.364A2.364 2.364 0 0 1 0 31.428V8.572a2.364 2.364 0 0 1 2.364-2.365h35.272A2.364 2.364 0 0 1 40 8.572v22.856Z'
      fill='#fff'
    />
    <path
      d='m8.878 20.159-.94-4.506c-.16-.62-.627-.806-1.21-.83H2.42l-.038.206c3.354.813 5.573 2.774 6.495 5.13Z'
      fill='#F79F1A'
    />
    <path
      d='M18.856 14.914h-2.783L14.336 25.11h2.777l1.743-10.197ZM10.574 25.1l4.376-10.18h-2.945l-2.722 6.956-.29-1.05c-.534-1.264-2.06-3.082-3.85-4.227l2.489 8.505 2.942-.003ZM26.791 21.777c.01-1.157-.733-2.04-2.335-2.77-.973-.47-1.566-.785-1.56-1.263 0-.422.503-.876 1.592-.876a5.132 5.132 0 0 1 2.08.39l.251.116.378-2.21a7.207 7.207 0 0 0-2.492-.425c-2.747 0-4.68 1.382-4.698 3.364-.017 1.466 1.382 2.283 2.436 2.771 1.082.498 1.445.817 1.438 1.264-.007.683-.862.994-1.661.994-1.114 0-1.703-.153-2.618-.534l-.356-.164-.39 2.285c.649.284 1.849.532 3.095.542 2.92 0 4.82-1.364 4.84-3.484ZM30.096 23.582l3.56.004c.084.355.339 1.528.339 1.528h2.579l-2.251-10.19h-2.147c-.666 0-1.162.181-1.455.844l-4.128 9.346h2.92s.475-1.256.583-1.532Zm1.906-4.938c-.014.028.23-.59.37-.974l.188.88s.53 2.436.642 2.945h-2.306c.23-.586 1.106-2.85 1.106-2.85ZM37.636 6.207H2.364A2.364 2.364 0 0 0 0 8.572v2.454h40V8.572a2.364 2.364 0 0 0-2.364-2.365Z'
      fill='#0E4595'
    />
    <path
      d='M37.636 33.793H2.364A2.365 2.365 0 0 1 0 31.428v-2.454h40v2.454a2.364 2.364 0 0 1-2.364 2.365Z'
      fill='#F79F1A'
    />
  </SvgIcon>
)

export default VisaIcon
