import _ from 'lodash'

import { APIendpoint, CREATE_DOCUMENT_DESCRIPTORS, LOADING, getStoredToken } from 'core/actions/constants'
import { POSTwithToken } from 'core/api/legacyApi'
import { getGlobalValue } from 'core/badGlobalDoNotUse'
import { handleErrors } from 'core/helpers/handleErrors'

/**
 * This action has a slightly different pattern compared to our other actions.
 * This action will batch multiple API calls in a single Promise so you will
 * have to provide an array of files as the POST body.
 */
const createDocumentDescriptors = ({ key, files, personId, documentUploadLinkKey }) => {
  const finalPersonId = personId || getGlobalValue('borrowerPersonId')

  return async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    let isSuccess = true
    const endpoint =
      documentUploadLinkKey ?
        `${APIendpoint}/document-uploads/${documentUploadLinkKey}/documents`
      : `${APIendpoint}/people/${finalPersonId}/documents`
    const responses = await Promise.all([
      ...files.map((file) =>
        fetch(
          endpoint,
          POSTwithToken(
            getStoredToken(),
            _.pickBy({
              type: file.type,
              status: 'draft',
              loanId: file.loanId,
              fileName: file.fileName,
            }),
          ),
        ),
      ),
    ])
    responses.forEach((response) => {
      if (response.status < 200 && response.status > 299) {
        isSuccess = false
      }
    })
    const responseBody = await Promise.all([
      ...responses.map(async (response) => {
        const res = await handleErrors(response)
        return res?.data ?? res
      }),
    ])

    if (isSuccess) {
      dispatch({
        type: CREATE_DOCUMENT_DESCRIPTORS,
        payload: responseBody,
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }
}

export default createDocumentDescriptors
