import { FC } from 'react'

import { styled } from 'styled-components'

import Link from 'core/components/lib/Link'
import { useBorrowerBaseUrl } from 'core/hooks'
import variables from 'core/styles/variables'

type InfoCardBottomCapProps = {
  status?: 'success' | 'loading' | 'error'
  successMessage?: string
  errorMessage?: string
  completeUrl?: string
  onComplete?: () => void
}

const InfoCardBottomCap: FC<InfoCardBottomCapProps> = ({
  status,
  successMessage,
  errorMessage,
  completeUrl,
  onComplete,
}) => {
  const baseUrl = useBorrowerBaseUrl()

  const url = completeUrl ? `${baseUrl}${completeUrl}` : undefined

  return (
    <BottomCap
      backgroundColor={
        status === 'success' ? variables.colorGreenLighten
        : status === 'error' ?
          variables.colorRedLighten
        : variables.colorBlack10
      }
    >
      <span>
        {status === 'success' ?
          successMessage || 'Action complete'
        : status === 'loading' ?
          'Processing...'
        : status === 'error' ?
          errorMessage || 'Something went wrong. Please refresh and try again.'
        : '???'}
      </span>

      {status === 'success' && (url || onComplete) && (
        <StyledLink onClick={onComplete} to={url}>
          Got it
        </StyledLink>
      )}
    </BottomCap>
  )
}

export default InfoCardBottomCap

const BottomCap = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${(p) => p.backgroundColor};
  padding: 4px 16px;
  min-height: 16px;
  color: ${variables.colorBlack70};
  font-size: 14px;
`

const StyledLink = styled(Link)`
  && {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
`
