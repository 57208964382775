import { FC } from 'react'

import { styled } from 'styled-components'

import Icon from 'core/components/Icon'
import { IconName } from 'core/components/Icon/iconDefinitions'
import Button from 'core/components/lib/Button'
import variables from 'core/styles/variables'

export const StyledAlert = styled.div<{
  margin: string
  bgColor: string
}>`
  display: flex;
  flex-direction: column;
  margin: ${(p) => p.margin};
  border-radius: 8px;
  background-color: ${(p) => p.bgColor};
  padding: 16px;

  svg {
    align-self: flex-start;
    min-width: 24px;
  }

  .button {
    align-self: flex-end;
    margin-top: 16px;
  }
`

const AlertMessageContainer = styled.div`
  display: flex;
`

const AlertHeader = styled.span`
  margin-left: 8px;
  font-weight: 500;
`

const Span = styled.span`
  display: block;
  margin-left: 8px;
  line-height: 24px;
  font-size: 1.6rem;
`

type AlertProps = {
  icon: IconName
  iconFill?: string
  header: string
  description: string
  bgColor?: string
  margin: string
  actionLabel?: string
  action?: () => void
}

const Alert: FC<AlertProps> = ({
  icon,
  iconFill = variables.colorBlack50,
  header,
  description,
  bgColor = 'transparent',
  margin,
  actionLabel,
  action,
}) => (
  <StyledAlert bgColor={bgColor} margin={margin}>
    <AlertMessageContainer>
      <Icon color={iconFill} fontSize='24px' name={icon} />
      <div>
        <AlertHeader>{header}</AlertHeader>
        <Span className='toast-description'>{description}</Span>
      </div>
    </AlertMessageContainer>
    {actionLabel && action && (
      <Button onClick={action} secondary>
        {actionLabel}
      </Button>
    )}
  </StyledAlert>
)

export default Alert
