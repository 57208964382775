import { checkTimeordate, isTimestring } from 'core/time/internal/types'
import mtz from 'core/time/internal/utils/mtz'

const makeStartOf = (context = {}) => {
  const { timezone } = context

  const startOf = (timeordate, duration) => {
    checkTimeordate(timeordate)

    const { year, day, month, hour } = duration
    const mDate = mtz(timeordate, timezone)
    if (year) {
      mDate.startOf('year')
    }
    if (day) {
      mDate.startOf('day')
    }
    if (month) {
      mDate.startOf('month')
    }
    if (hour) {
      mDate.startOf('hour')
    }

    return isTimestring(timeordate) ? mDate.toISOString() : mDate.format('Y-MM-DD')
  }

  return startOf
}

export default makeStartOf
