import { ReactChild, createContext, useContext } from 'react'

type ToastContextValue = {
  error: (message: ReactChild) => void
  warning: (message: ReactChild) => void
  success: (message: ReactChild) => void
}

export const ToastContext = createContext<ToastContextValue>({} as ToastContextValue)

const useToast = () => useContext(ToastContext)

export default useToast
