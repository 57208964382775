import { getName } from 'country-list'

import { AddressBase } from 'core/types'

import * as R from './remeda'
import * as S from './string'

/**
 * Takes an address object just like it appears, for example, in the contacts
 * api and returns a string version of it.
 */
const formatAddress = (
  address: Exclude<AddressBase, 'object'> | null | undefined,
  options?: {
    singleLine?: boolean
    twoLines?: boolean
    includeCountry?: boolean
  },
) => {
  const { singleLine, twoLines, includeCountry } = options ?? {}

  if (!address || R.isEmptyObject(address)) return ''

  const { addressLine1, addressLine2, city, countyOrRegion, state, postalCode } = address

  const POBox = address?.POBox && `PO Box ${address.POBox}`
  const cityState = S.join([city, countyOrRegion, state], ', ')
  const addressLine3 = S.join([cityState, postalCode])
  const country = includeCountry ? address.country && getName(address.country) : null

  if (twoLines) {
    const line1 = S.join([S.join([POBox, addressLine1]), addressLine2], ', ')
    const line2 = S.join([addressLine3, country], ' ')
    return S.join([line1, line2], '\n')
  }

  return S.join([POBox, addressLine1, addressLine2, addressLine3, country], singleLine ? ', ' : '\n')
}

export default formatAddress
