import { checkTimeordate } from 'core/time/internal/types'
import mtz from 'core/time/internal/utils/mtz'

const makeDifferenceInHours = (context = {}) => {
  const { timezone } = context

  const differenceInHours = (later, earlier) => {
    checkTimeordate(later)
    checkTimeordate(earlier)

    const laterMoment = mtz(later, timezone)
    const earlierMoment = mtz(earlier, timezone)
    return laterMoment.diff(earlierMoment, 'hours')
  }

  return differenceInHours
}

export default makeDifferenceInHours
