/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const listCaseType: OperationData<Types.ListCaseType> = {
  method: 'get',
  name: 'listCaseType',
  summary: 'Get case types',
  path: '/case-types',
  queryKey: () => ['case-types'],
}

export const createCaseType: OperationData<Types.CreateCaseType> = {
  method: 'post',
  name: 'createCaseType',
  summary: 'Create type',
  path: '/case-types',
  queryKey: () => ['case-types'],
}

export const internalListCaseTypes: OperationData<Types.InternalListCaseTypes> = {
  method: 'get',
  name: 'internalListCaseTypes',
  summary: 'Get case types for company',
  path: '/companies/{companyId}/case-types',
  queryKey: ({ companyId }) => ['companies', companyId, 'case-types'],
}

export const getCaseTypeById: OperationData<Types.GetCaseTypeById> = {
  method: 'get',
  name: 'getCaseTypeById',
  summary: 'Get type by ID',
  path: '/case-types/{caseTypeId}',
  queryKey: ({ caseTypeId }) => ['case-types', caseTypeId],
}

export const updateCaseType: OperationData<Types.UpdateCaseType> = {
  method: 'put',
  name: 'updateCaseType',
  summary: 'Update type',
  path: '/case-types/{caseTypeId}',
  queryKey: ({ caseTypeId }) => ['case-types', caseTypeId],
}

export const caseTypeAddAssociation: OperationData<Types.CaseTypeAddAssociation> = {
  method: 'post',
  name: 'caseTypeAddAssociation',
  summary: 'Add association',
  path: '/case-types/{caseTypeId}/associations',
  queryKey: ({ caseTypeId }) => ['case-types', caseTypeId, 'associations'],
}

export const caseTypeRemoveAssociation: OperationData<Types.CaseTypeRemoveAssociation> = {
  method: 'delete',
  name: 'caseTypeRemoveAssociation',
  summary: 'Remove association',
  path: '/case-types/{caseTypeId}/associations',
  queryKey: ({ caseTypeId }) => ['case-types', caseTypeId, 'associations'],
}

export const createSmartReviewTasks: OperationData<Types.CreateSmartReviewTasks> = {
  method: 'post',
  name: 'createSmartReviewTasks',
  summary: 'Create case type review tasks for ID',
  path: '/case-types/{caseTypeId}/create-smart-review-tasks',
  queryKey: ({ caseTypeId }) => ['case-types', caseTypeId, 'create-smart-review-tasks'],
}

export const internalCreateCaseTypeSmartReviewTasks: OperationData<Types.InternalCreateCaseTypeSmartReviewTasks> = {
  method: 'post',
  name: 'internalCreateCaseTypeSmartReviewTasks',
  summary: 'Create case type review settings for ID',
  path: '/companies/{companyId}/case-types/{caseTypeId}/create-smart-review-tasks',
  queryKey: ({ companyId, caseTypeId }) => [
    'companies',
    companyId,
    'case-types',
    caseTypeId,
    'create-smart-review-tasks',
  ],
}

export const listCasesByCompany: OperationData<Types.ListCasesByCompany> = {
  method: 'get',
  name: 'listCasesByCompany',
  summary: 'Get all cases',
  path: '/cases',
  queryKey: () => ['cases'],
}

export const listCasesByBorrower: OperationData<Types.ListCasesByBorrower> = {
  method: 'get',
  name: 'listCasesByBorrower',
  summary: 'Get borrower cases',
  path: '/people/{personId}/cases',
  queryKey: ({ personId }) => ['people', personId, 'cases'],
}

export const createCase: OperationData<Types.CreateCase> = {
  method: 'post',
  name: 'createCase',
  summary: 'Create borrower case',
  path: '/people/{personId}/cases',
  queryKey: ({ personId }) => ['people', personId, 'cases'],
}

export const getCaseById: OperationData<Types.GetCaseById> = {
  method: 'get',
  name: 'getCaseById',
  summary: 'Get borrower case by ID',
  path: '/people/{personId}/cases/{caseId}',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId],
}

export const updateCase: OperationData<Types.UpdateCase> = {
  method: 'put',
  name: 'updateCase',
  summary: 'Update case',
  path: '/people/{personId}/cases/{caseId}',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId],
}

export const addCaseAssociation: OperationData<Types.AddCaseAssociation> = {
  method: 'post',
  name: 'addCaseAssociation',
  summary: 'Add association',
  path: '/people/{personId}/cases/{caseId}',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId],
}

export const removeCaseAssociation: OperationData<Types.RemoveCaseAssociation> = {
  method: 'delete',
  name: 'removeCaseAssociation',
  summary: 'Remove association',
  path: '/people/{personId}/cases/{caseId}',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId],
}

export const caseEscalationsGet: OperationData<Types.CaseEscalationsGet> = {
  method: 'get',
  name: 'caseEscalationsGet',
  summary: 'List case escalations',
  path: '/people/{personId}/cases/{caseId}/escalations',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId, 'escalations'],
}

export const caseEscalationsCreate: OperationData<Types.CaseEscalationsCreate> = {
  method: 'post',
  name: 'caseEscalationsCreate',
  summary: 'Escalate or de-escalate a case',
  path: '/people/{personId}/cases/{caseId}/escalations',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId, 'escalations'],
}

export const caseEscalationGetById: OperationData<Types.CaseEscalationGetById> = {
  method: 'get',
  name: 'caseEscalationGetById',
  summary: 'List case escalation by ID',
  path: '/people/{personId}/cases/{caseId}/escalations/{caseEscalationId}',
  queryKey: ({ personId, caseId, caseEscalationId }) => [
    'people',
    personId,
    'cases',
    caseId,
    'escalations',
    caseEscalationId,
  ],
}

export const caseOpenEscalationsGetByCompany: OperationData<Types.CaseOpenEscalationsGetByCompany> = {
  method: 'get',
  name: 'caseOpenEscalationsGetByCompany',
  summary: 'List active case escalations for a company',
  path: '/active-escalations',
  queryKey: () => ['active-escalations'],
}

export const viewCase: OperationData<Types.ViewCase> = {
  method: 'post',
  name: 'viewCase',
  summary: 'Mark a case as viewed',
  path: '/people/{personId}/cases/{caseId}/views',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId, 'views'],
}

export const internalListCasesByCompany: OperationData<Types.InternalListCasesByCompany> = {
  method: 'get',
  name: 'internalListCasesByCompany',
  summary: 'Get cases for a specific company',
  path: '/companies/{companyId}/cases',
  queryKey: ({ companyId }) => ['companies', companyId, 'cases'],
}

export const internalUnboundGetCaseById: OperationData<Types.InternalUnboundGetCaseById> = {
  method: 'get',
  name: 'internalUnboundGetCaseById',
  summary: 'Get case by ID',
  path: '/companies/{companyId}/cases/{caseId}',
  queryKey: ({ companyId, caseId }) => ['companies', companyId, 'cases', caseId],
}

export const internalUnboundCaseUpdate: OperationData<Types.InternalUnboundCaseUpdate> = {
  method: 'put',
  name: 'internalUnboundCaseUpdate',
  summary: 'Update case',
  path: '/companies/{companyId}/cases/{caseId}',
  queryKey: ({ companyId, caseId }) => ['companies', companyId, 'cases', caseId],
}

export const internalUnboundAddCaseAssociation: OperationData<Types.InternalUnboundAddCaseAssociation> = {
  method: 'post',
  name: 'internalUnboundAddCaseAssociation',
  summary: 'Add association',
  path: '/companies/{companyId}/cases/{caseId}',
  queryKey: ({ companyId, caseId }) => ['companies', companyId, 'cases', caseId],
}

export const internalUnboundRemoveCaseAssociation: OperationData<Types.InternalUnboundRemoveCaseAssociation> = {
  method: 'delete',
  name: 'internalUnboundRemoveCaseAssociation',
  summary: 'Remove association',
  path: '/companies/{companyId}/cases/{caseId}',
  queryKey: ({ companyId, caseId }) => ['companies', companyId, 'cases', caseId],
}

export const internalListCasesByBorrower: OperationData<Types.InternalListCasesByBorrower> = {
  method: 'get',
  name: 'internalListCasesByBorrower',
  summary: 'Get cases',
  path: '/companies/{companyId}/people/{personId}/cases',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'cases'],
}

export const internalCreateCase: OperationData<Types.InternalCreateCase> = {
  method: 'post',
  name: 'internalCreateCase',
  summary: 'Create case',
  path: '/companies/{companyId}/people/{personId}/cases',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'cases'],
}

export const internalGetCaseById: OperationData<Types.InternalGetCaseById> = {
  method: 'get',
  name: 'internalGetCaseById',
  summary: 'Get borrower case by ID',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}',
  queryKey: ({ companyId, personId, caseId }) => ['companies', companyId, 'people', personId, 'cases', caseId],
}

export const internalUpdateCase: OperationData<Types.InternalUpdateCase> = {
  method: 'put',
  name: 'internalUpdateCase',
  summary: 'Update case',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}',
  queryKey: ({ companyId, personId, caseId }) => ['companies', companyId, 'people', personId, 'cases', caseId],
}

export const internalAddCaseAssociation: OperationData<Types.InternalAddCaseAssociation> = {
  method: 'post',
  name: 'internalAddCaseAssociation',
  summary: 'Add association',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}',
  queryKey: ({ companyId, personId, caseId }) => ['companies', companyId, 'people', personId, 'cases', caseId],
}

export const internalRemoveCaseAssociation: OperationData<Types.InternalRemoveCaseAssociation> = {
  method: 'delete',
  name: 'internalRemoveCaseAssociation',
  summary: 'Remove association',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}',
  queryKey: ({ companyId, personId, caseId }) => ['companies', companyId, 'people', personId, 'cases', caseId],
}

export const internalGetCaseEscalation: OperationData<Types.InternalGetCaseEscalation> = {
  method: 'get',
  name: 'internalGetCaseEscalation',
  summary: 'List case escalations',
  path: '/companies/{companyId}/cases/{caseId}/escalations',
  queryKey: ({ companyId, caseId }) => ['companies', companyId, 'cases', caseId, 'escalations'],
}

export const internalCreateCaseEscalation: OperationData<Types.InternalCreateCaseEscalation> = {
  method: 'post',
  name: 'internalCreateCaseEscalation',
  summary: 'Escalate or de-escalate a case',
  path: '/companies/{companyId}/cases/{caseId}/escalations',
  queryKey: ({ companyId, caseId }) => ['companies', companyId, 'cases', caseId, 'escalations'],
}

export const internalCaseOpenEscalationsGetByCompany: OperationData<Types.InternalCaseOpenEscalationsGetByCompany> = {
  method: 'get',
  name: 'internalCaseOpenEscalationsGetByCompany',
  summary: 'List active case escalations for a company',
  path: '/companies/{companyId}/active-escalations',
  queryKey: ({ companyId }) => ['companies', companyId, 'active-escalations'],
}

export const createTaskFromNote: OperationData<Types.CreateTaskFromNote> = {
  method: 'post',
  name: 'createTaskFromNote',
  summary: 'Create task from case note',
  path: '/people/{personId}/cases/{caseId}/create-task-from-note',
  queryKey: ({ personId, caseId }) => ['people', personId, 'cases', caseId, 'create-task-from-note'],
}
