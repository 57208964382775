import { css } from 'styled-components'

import { variables } from 'core/styles'

export const errorBannerCss = css`
  grid-column: 1 / -1;
  background-color: ${variables.colorRedLighten};
  color: ${variables.colorBlack70};
`

export const successBannerCss = css`
  background-color: ${variables.colorGreenLighten};
  color: ${variables.colorBlack70};
`

export const warningBannerCss = css`
  background-color: ${variables.colorOrangeLighten};
  color: ${variables.colorBlack70};
`
