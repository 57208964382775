import { useEffect } from 'react'

import PropTypes from 'prop-types'

import useOnMount from './useOnMount'
import usePrevious from './usePrevious'

const useScrollToTop = ({ scrollKey, disabled } = {}) => {
  const previousScrollKey = usePrevious(scrollKey)

  // scroll on initial mount
  useOnMount(() => {
    if (!disabled) window.scrollTo(0, 0)
  })

  // scroll on change of key (and is not be triggered on initial mount)
  useEffect(() => {
    if (!disabled && previousScrollKey !== scrollKey) {
      window.scrollTo(0, 0)
    }
  }, [disabled, previousScrollKey, scrollKey])
}

const ScrollToTop = ({ scrollKey, disabled }) => {
  useScrollToTop({ scrollKey, disabled })
  return null
}

ScrollToTop.propTypes = {
  scrollKey: PropTypes.string,
  disabled: PropTypes.bool,
}

export { ScrollToTop }

export default useScrollToTop
