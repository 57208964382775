import { FC } from 'react'

import { styled } from 'styled-components'

import { IconName, customIcons } from './iconDefinitions'
import MaterialIconRounded from './internal/MaterialIconRounded'

export const Container = styled.div<{
  $color?: string
  $fontSize?: string
  $inline?: boolean
  $margin?: string
  $opacity?: string
}>`
  /* This just makes sure we don't have some weird extra space below the icon inside Container. */
  display: ${(p) => (p.$inline ? 'inline-flex' : 'flex')};
  transform: ${(p) => (p.$inline ? 'translateY(2.5px)' : 'none')};
  opacity: ${(p) => p.$opacity};
  margin: ${(p) => p.$margin};
  width: ${(p) => (p.$fontSize !== 'inherit' ? p.$fontSize : 'auto')};
  height: ${(p) => (p.$fontSize !== 'inherit' ? p.$fontSize : 'auto')};
  color: ${(p) => p.$color};
  font-size: ${(p) => p.$fontSize};
`

/** Usage of Icon:
 * We were deliberate in our choice of not making Icon styleable. In other
 * words, you cannot do this with styled-components:
 * const StyledIcon = styled(Icon)`
 *   // css
 * `
 * Instead, just use the props fontSize, color, and margin if you want to
 * set those on the icon directly, or for setting the css of those values
 * or any other kind of css, wrap the Icon in another element and style
 * that instead.
 *
 * There is no onClick prop. If you want a button or a link for your icon,
 * you should instead actually make a button or link element and put the
 * icon inside as its content.
 *
 * Do not use any icons directly in our app. Always go through this
 * component.
 *
 * Material Design guidelines state that icons should usually have a
 * fontSize of '24px' and if a different size is needed, it should be
 * limited to one of these four sizes:
 * - '18px'
 * - '24px'
 * - '36px'
 * - '48px'
 * We decided to _not_ give a default of '24px' to Icon. Instead the default
 * is 'inherit', and you must always set the font size either with this
 * prop or with the css of some parent element. This gives more flexibility
 * and allows for sometimes having an icon inline with text at times we
 * want the icon to have the same font size as the text surrounding it. It
 * also makes it easier to set a group of icons to a font size with a single
 * setting of font size on the container of all the icons.
 */

export type IconProps = {
  /** Which icon to display. */
  name: IconName | null
  /** Display an alternative "outlined" version. */
  outlined?: boolean
  /** The color of the icon. */
  color?: string
  /** The size of the icon expressed as a CSS \<length\>. */
  fontSize?: string
  /** Display the icon as an inline element instead of block level. */
  inline?: boolean
  /** Sets the CSS margin. */
  margin?: string
  /** Sets the CSS opacity. */
  opacity?: string
}

const Icon: FC<IconProps> = ({
  name,
  fontSize = 'inherit',
  color = 'inherit',
  inline,
  margin = '0',
  outlined = false,
  opacity = '1',
}) => {
  const Component = name === null ? null : (customIcons[name] ?? MaterialIconRounded)

  return (
    <Container $color={color} $fontSize={fontSize} $inline={inline} $margin={margin} $opacity={opacity}>
      {name && Component && <Component name={name} outlined={outlined} />}
    </Container>
  )
}

export default Icon
