import { useContext, createContext } from 'react'

export const FileUploadContext = createContext({})
export const useFileUpload = () => useContext(FileUploadContext)

export const ELAPSED_TIME_TO_RETRY = 10
export const UPLOAD_SUCCESS = 'Document(s) successfully uploaded.'
export const UPLOAD_FAIL = 'Document upload failed, would you like to retry your upload?'
export const UPLOAD_TAKING_LONG = 'Document upload is taking longer than expected, would you like to retry your upload?'
export const MAX_UPLOAD_SIZE_DEFAULT = 10485760 // 10 Megabytes
export const MULTI_FILE_ERROR = 'Only one file can be uploaded at a time.'
export const MAX_FILE_NAME_CHAR_LIMIT = 50 // Currently our db limit
export const MAX_FILE_NAME_CHAR_ERROR = 'File names must be 50 characters or less'
