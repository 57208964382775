/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const getVoiceAccessToken: OperationData<Types.GetVoiceAccessToken> = {
  method: 'post',
  name: 'getVoiceAccessToken',
  summary: 'Get Twilio voice access token (DEPRECATED)',
  path: '/twilio/auth/voice-token',
  queryKey: () => ['twilio', 'auth', 'voice-token'],
}

export const getMultiAccessTokens: OperationData<Types.GetMultiAccessTokens> = {
  method: 'post',
  name: 'getMultiAccessTokens',
  summary: 'Get all Twilio access tokens',
  path: '/twilio/auth/access-tokens',
  queryKey: () => ['twilio', 'auth', 'access-tokens'],
}

export const webhookVoice: OperationData<Types.WebhookVoice> = {
  method: 'post',
  name: 'webhookVoice',
  summary: 'Twilio webhook for Twilio voice calls (outbound and inbound)',
  path: '/twilio/webhooks/voice',
  queryKey: () => ['twilio', 'webhooks', 'voice'],
}

export const webhookPlayBeepTwiml: OperationData<Types.WebhookPlayBeepTwiml> = {
  method: 'post',
  name: 'webhookPlayBeepTwiml',
  summary: 'Twilio webhook to return TwiML to play a beep',
  path: '/twilio/webhooks/webhook-play-beep-twiml',
  queryKey: () => ['twilio', 'webhooks', 'webhook-play-beep-twiml'],
}

export const webhookVoiceDupe: OperationData<Types.WebhookVoiceDupe> = {
  method: 'post',
  name: 'webhookVoiceDupe',
  summary: 'Twilio webbook for outbound voice (DEPRECATED)',
  path: '/twilio/webhooks/outbound-voice',
  queryKey: () => ['twilio', 'webhooks', 'outbound-voice'],
}

export const updateVoiceCall: OperationData<Types.UpdateVoiceCall> = {
  method: 'post',
  name: 'updateVoiceCall',
  summary: "Update an active Twilio call's state",
  path: '/twilio/update-voice-call',
  queryKey: () => ['twilio', 'update-voice-call'],
}

export const updateBorrowerHoldStatus: OperationData<Types.UpdateBorrowerHoldStatus> = {
  method: 'post',
  name: 'updateBorrowerHoldStatus',
  summary: "Update an active Twilio call's hold status",
  path: '/twilio/update-borrower-hold-status',
  queryKey: () => ['twilio', 'update-borrower-hold-status'],
}

export const playBeepOnUserConsentAndStartRecording: OperationData<Types.PlayBeepOnUserConsentAndStartRecording> = {
  method: 'post',
  name: 'playBeepOnUserConsentAndStartRecording',
  summary: 'Play a beep on user consent and start recording',
  path: '/twilio/play-beep-on-user-consent-and-start-recording',
  queryKey: () => ['twilio', 'play-beep-on-user-consent-and-start-recording'],
}

export const makeOutboundVoiceParams: OperationData<Types.MakeOutboundVoiceParams> = {
  method: 'post',
  name: 'makeOutboundVoiceParams',
  summary: 'Make params Twilio JS client outbound voice calls',
  path: '/twilio/make-outbound-voice-params',
  queryKey: () => ['twilio', 'make-outbound-voice-params'],
}

export const answerQueuedCall: OperationData<Types.AnswerQueuedCall> = {
  method: 'post',
  name: 'answerQueuedCall',
  summary: 'Answer queued (on hold) call',
  path: '/twilio/answer-queued-call',
  queryKey: () => ['twilio', 'answer-queued-call'],
}

export const webhookCallStatus: OperationData<Types.WebhookCallStatus> = {
  method: 'post',
  name: 'webhookCallStatus',
  summary: 'Twilio webhook for updating call status',
  path: '/twilio/webhooks/call-status',
  queryKey: () => ['twilio', 'webhooks', 'call-status'],
}

export const webhookConferenceStatus: OperationData<Types.WebhookConferenceStatus> = {
  method: 'post',
  name: 'webhookConferenceStatus',
  summary: 'Twilio webhook for updating conference status',
  path: '/twilio/webhooks/conference-status',
  queryKey: () => ['twilio', 'webhooks', 'conference-status'],
}

export const webhookTaskAssignment: OperationData<Types.WebhookTaskAssignment> = {
  method: 'post',
  name: 'webhookTaskAssignment',
  summary: 'Twilio webhook for the Taskrouter Task Assignment Callback',
  path: '/twilio/webhooks/task-assignment',
  queryKey: () => ['twilio', 'webhooks', 'task-assignment'],
}

export const webhookTaskrouterEvents: OperationData<Types.WebhookTaskrouterEvents> = {
  method: 'post',
  name: 'webhookTaskrouterEvents',
  summary: 'Twilio webhook for the Taskrouter Task Assignment Callback',
  path: '/twilio/webhooks/taskrouter-events',
  queryKey: () => ['twilio', 'webhooks', 'taskrouter-events'],
}

export const webhookIncomingMessage: OperationData<Types.WebhookIncomingMessage> = {
  method: 'post',
  name: 'webhookIncomingMessage',
  summary: 'Twilio webhook for incoming message',
  path: '/twilio/webhooks/incoming-message',
  queryKey: () => ['twilio', 'webhooks', 'incoming-message'],
}

export const webhookPerConversationEvent: OperationData<Types.WebhookPerConversationEvent> = {
  method: 'post',
  name: 'webhookPerConversationEvent',
  summary: 'Twilio webhook for per-conversation conversation events',
  path: '/twilio/webhooks/per-conversation-event',
  queryKey: () => ['twilio', 'webhooks', 'per-conversation-event'],
}

export const closeConvo: OperationData<Types.CloseConvo> = {
  method: 'post',
  name: 'closeConvo',
  summary: 'Close active Twilio conversation',
  path: '/twilio/close-convo',
  queryKey: () => ['twilio', 'close-convo'],
}

export const joinConvo: OperationData<Types.JoinConvo> = {
  method: 'post',
  name: 'joinConvo',
  summary: 'Join active Twilio conversation',
  path: '/twilio/join-convo',
  queryKey: () => ['twilio', 'join-convo'],
}

export const handleStartBorrowerWebchat: OperationData<Types.HandleStartBorrowerWebchat> = {
  method: 'post',
  name: 'handleStartBorrowerWebchat',
  summary: 'Start a webchat',
  path: '/twilio/borrower-start-webchat',
  queryKey: () => ['twilio', 'borrower-start-webchat'],
}

export const handleCloseBorrowerWebchat: OperationData<Types.HandleCloseBorrowerWebchat> = {
  method: 'post',
  name: 'handleCloseBorrowerWebchat',
  summary: 'Close borrower active webchat',
  path: '/twilio/borrower-close-webchat',
  queryKey: () => ['twilio', 'borrower-close-webchat'],
}

export const handleGetActiveBorrowerWebchat: OperationData<Types.HandleGetActiveBorrowerWebchat> = {
  method: 'get',
  name: 'handleGetActiveBorrowerWebchat',
  summary: 'Get active borrower webchat',
  path: '/twilio/borrower-get-active-webchat',
  queryKey: () => ['twilio', 'borrower-get-active-webchat'],
}

export const getBorrowerMultiAccessTokens: OperationData<Types.GetBorrowerMultiAccessTokens> = {
  method: 'post',
  name: 'getBorrowerMultiAccessTokens',
  summary: 'Get all Twilio access tokens for borrower',
  path: '/twilio/auth/borrower-access-tokens',
  queryKey: () => ['twilio', 'auth', 'borrower-access-tokens'],
}

export const handleBorrowerReportAgentDisconnect: OperationData<Types.HandleBorrowerReportAgentDisconnect> = {
  method: 'post',
  name: 'handleBorrowerReportAgentDisconnect',
  summary: 'Report agent disconnect',
  path: '/twilio/borrower-report-agent-disconnect',
  queryKey: () => ['twilio', 'borrower-report-agent-disconnect'],
}

export const syncTaskHistory: OperationData<Types.SyncTaskHistory> = {
  method: 'post',
  name: 'syncTaskHistory',
  summary: "Sync from Twilio Taskrouter to Peach's task history",
  path: '/twilio/sync-task-history',
  queryKey: () => ['twilio', 'sync-task-history'],
}

export const webhookRecordingStatus: OperationData<Types.WebhookRecordingStatus> = {
  method: 'post',
  name: 'webhookRecordingStatus',
  summary: 'Twilio webhook for updating call recording status',
  path: '/twilio/webhooks/recording-status',
  queryKey: () => ['twilio', 'webhooks', 'recording-status'],
}

export const webhookVoicemailStatus: OperationData<Types.WebhookVoicemailStatus> = {
  method: 'post',
  name: 'webhookVoicemailStatus',
  summary: 'Twilio webhook for updating voicemail recording status',
  path: '/twilio/webhooks/voicemail-status',
  queryKey: () => ['twilio', 'webhooks', 'voicemail-status'],
}

export const webhookVoicemailCompleted: OperationData<Types.WebhookVoicemailCompleted> = {
  method: 'post',
  name: 'webhookVoicemailCompleted',
  summary: 'Twilio webhook for receiving completed voicemail',
  path: '/twilio/webhooks/voicemail-completed',
  queryKey: () => ['twilio', 'webhooks', 'voicemail-completed'],
}
