import { redirectToLogin } from 'app/loginRedirect/loginRedirectHelpers'

import { setSessionExpiredIfRecent } from 'core/actions/helpers/tokenHelpers'

const onUnauthorized = () => {
  setSessionExpiredIfRecent()
  redirectToLogin()
}

export default onUnauthorized
