import { styled } from 'styled-components'

import Tooltip from 'core/components/lib/Tooltip'
import { useCompanyInfo } from 'core/stores'
import variables from 'core/styles/variables'

const Wrapper = styled.span`
  color: ${variables.colorBlack70};
`

const Inner = styled.span`
  display: inline-flex;
  align-items: center;
  white-space: pre-wrap;
`

const Content = styled.div`
  text-align: left;
  color: ${variables.colorBlack70};
  font-size: 16px;
`

const PoweredByPeach = () => {
  const { brandName } = useCompanyInfo()

  const starter = brandName ? `${brandName} is using` : 'Using'

  const content = (
    <Content>{`${starter} Peach technology to provide customers with online tools to manage their loans.`}</Content>
  )

  return (
    <Wrapper>
      {'Powered by '}
      <Inner>
        {'Peach '}
        <Tooltip content={content} inline style={{ display: 'inline-block' }} width={400} />
      </Inner>
    </Wrapper>
  )
}

export default PoweredByPeach
