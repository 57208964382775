import {
  init,
  captureMessage,
  setTag,
  setUser,
  addBreadcrumb,
  withScope,
  BrowserTracing,
  captureException,
} from '@sentry/react'
import { CaptureContext } from '@sentry/types'

import parseError from 'core/helpers/parseError'
import * as R from 'core/helpers/remeda'

const dsn = import.meta.env.VITE_SENTRY_DSN
const environment = import.meta.env.VITE_ENV_LABEL
const release = import.meta.env.VITE_SENTRY_RELEASE

const ignoreErrors = [
  'TypeError: cancelled',
  // ! All entries below are from the community list of commonly-ignored errors
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  `Can't find variable: ZiteReader`,
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
]
const denyUrls = [
  // ! All entries below are from the community list of commonly-ignored errors
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
]

let initialized = false

const logWarning = (...args) => {
  if (import.meta.env.VITE_ENV_LABEL !== 'test') {
    console.warn(...args)
  }
}

class ExpectedError extends Error {
  isExpectedError = true
  name = 'ExpectedError'
}

const beforeSend = (event, hint) => {
  const { isExpectedError } = hint?.originalException || {}

  // do not log expected errors
  if (isExpectedError) return null

  return event
}

if (!dsn) {
  logWarning('ErrorLogging.init(), VITE_SENTRY_DSN was not provided. Error logging will not be initialized')
} else if (!environment) {
  logWarning('ErrorLogging.init(), VITE_ENV_LABEL was not provided.  Error logging will not be initialized')
} else {
  if (!release) {
    logWarning(
      'ErrorLogging.init(), VITE_SENTRY_RELEASE was not provided. Error logging will initialize without a specified release, which means source maps will not work.',
    )
  }

  const integrations = [new BrowserTracing()]

  init({
    dsn,
    environment,
    integrations,
    release,
    tracesSampleRate: 0.5,
    beforeSend,
    ignoreErrors,
    denyUrls,
  })

  initialized = true
}

const ErrorLogging = {
  captureException: (exception: unknown, captureContext?: CaptureContext) =>
    captureException(
      exception instanceof Error ? exception : (
        (exception && typeof exception === 'object' && 'status' in exception && typeof exception.status === 'number' ?
          exception.status + ' '
        : '') + parseError(exception)
      ),
      captureContext,
    ),
  captureMessage,
  setTag,
  setUser,
  addBreadcrumb,
  withScope,
}

const wrappedErrorLogging = R.mapValues(ErrorLogging, (sentryFn, key) => (...args) => {
  if (initialized) {
    // @ts-ignore
    sentryFn(...args)
  } else {
    logWarning(`ErrorLogging.${key}()`, args)
  }
})

export { ExpectedError }

export default wrappedErrorLogging
