import { combineReducers, configureStore } from '@reduxjs/toolkit'

import crmReducers from 'crm/crmReducers'

import borrowerLoading from './borrowerLoading'
import communicator from './communicator'
import consents from './consents'
import contacts from './contacts'
import documents from './documents'
import employees from './employees'
import legalRepresentatives from './legalRepresentatives'
import loading from './loading'
import loans from './loans'
import loanTypes from './loanTypes'
import people from './people'
import permissions from './permissions'
import teams from './teams'
import verifications from './verifications'

// TODO: Incrementally move to domain-specific nested reducers
// * state specific to "admin", "crm", and "loan" applications should live in nested reducers
// * depending on how much global state we actually need, there should either be a "global" reducer or just "auth" reducer

const rootReducer = combineReducers({
  borrowerLoading,
  communicator,
  consents,
  contacts,
  documents,
  employees,
  legalRepresentatives,
  loading,
  loanTypes,
  loans,
  people,
  permissions,
  teams,
  verifications,
  crm: crmReducers,
})

const store = configureStore({
  // Includes only redux-thunk in production
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false }),
  reducer: rootReducer,
  devTools: import.meta.env.VITE_ENV_LABEL === 'local',
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
