import { format, parse, parseISO } from 'date-fns'

/** Normally we don't want to use date-fns, but the underlying Datepicker
 * has to use date objects (with timestamps) in the local time. So we trick
 * it by taking out timezoned date strings, pretend like they are in local
 * time, and parse them into a date object with date-fns's parse function.
 * Then when converting back out, we take the date object that thinks it's
 * representing local time, and we just take the date string from it, which
 * is actually according to the context timezone. Note that datestrings
 * (without timestamps) passed from the backend are assumed to be in the
 * company timezone. If you take that string you can directly pass it into
 * the props of DatePicker.
 */

export const convertInDate = (dateStr: string | null) => (dateStr ? parse(dateStr, 'yyyy-MM-dd', new Date()) : null)
export const convertInDatetime = (isoDateStr: string | null) => (isoDateStr ? parseISO(isoDateStr) : null)
export const convertOutDate = (date: Date) => format(date, 'yyyy-MM-dd')
export const convertOutDatetime = (date: Date) => date.toISOString()
