import { FC } from 'react'

import { UseFormReturn } from 'react-hook-form'
import { styled } from 'styled-components'

import { R } from 'core/helpers'

import Override from './Override'
import overrideOptions, { PeachOverrideVariant } from './overrideOptions'
import { StoredOverrides } from './storage'

type SectionProps = {
  label: string
  section: PeachOverrideVariant['section']
  form: UseFormReturn<StoredOverrides>
}

const Section: FC<SectionProps> = ({ label, section, form }) => (
  <>
    <SectionTitle>{label}</SectionTitle>
    {R.pipe(
      overrideOptions,
      R.toPairs.strict,
      R.filter(([_, o]) => o.section === section),
      R.map(([name, override]) => <Override key={name} override={override} {...form.register(name)} />),
    )}
  </>
)

export default Section

export const SectionTitle = styled.h1`
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
`
