import { checkTimeordate } from 'core/time/internal/types'
import mtz from 'core/time/internal/utils/mtz'

const makeFormatRelative = (context = {}) => {
  const { timezone } = context

  const formatRelative = (timeordate, options = {}) => {
    const { isCapitalized = true, showTime = true } = options

    checkTimeordate(timeordate)

    const mDate = mtz(timeordate, timezone)
    const now = mtz(null, timezone)
    const days = now.clone().startOf('day').diff(mDate.clone().startOf('day'), 'days')
    const years = now.clone().startOf('year').diff(mDate.clone().startOf('year'), 'years')

    if (days === 0) {
      return `${isCapitalized ? 'Today' : 'today'}${showTime ? ',' : ''} ${showTime ? mDate.format('h:mm A') : ''}`
    } else if (days === 1) {
      return `${isCapitalized ? 'Yesterday' : 'yesterday'}${showTime ? ',' : ''} ${
        showTime ? mDate.format('h:mm A') : ''
      }`
    } else if (days <= 6) {
      return mDate.format(`dddd${showTime ? ', h:mm A' : ''}`)
    } else if (years === 0) {
      return mDate.format(`D MMM${showTime ? ', h:mm A' : ''}`)
    } else {
      return mDate.format(`D MMM Y${showTime ? ', h:mm A' : ''}`)
    }
  }

  return formatRelative
}

export default makeFormatRelative
