import { checkTimeordate } from 'core/time/internal/types'
import mtz from 'core/time/internal/utils/mtz'

const makeDifferenceInCalendarDays = (context = {}) => {
  const { timezone } = context

  const differenceInCalendarDays = (later, earlier) => {
    checkTimeordate(later)
    checkTimeordate(earlier)

    const laterDay = mtz(later, timezone).startOf('day')
    const earlierDay = mtz(earlier, timezone).startOf('day')
    return laterDay.diff(earlierDay, 'days')
  }

  return differenceInCalendarDays
}

export default makeDifferenceInCalendarDays
