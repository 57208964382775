import _ from 'lodash'

import { GET_EMPLOYEE, GET_EMPLOYEES } from 'core/actions/constants'

const initialState = {
  byId: {},
}

const employees = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        byId: _.keyBy(action.payload, 'id'),
      }
    case GET_EMPLOYEE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...action.payload,
            companyId: action.companyId,
          },
        },
      }
    default:
      return state
  }
}

export default employees
