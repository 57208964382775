/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const bootstrap: OperationData<Types.Bootstrap> = {
  method: 'get',
  name: 'bootstrap',
  summary: 'Get bootstrap data',
  path: '/bootstrap',
  queryKey: () => ['bootstrap'],
}

export const getFrontendConfig: OperationData<Types.GetFrontendConfig> = {
  method: 'get',
  name: 'getFrontendConfig',
  summary: 'Get frontend configuration',
  path: '/frontend-config',
  queryKey: () => ['frontend-config'],
}
