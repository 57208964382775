import { useQuery } from '@tanstack/react-query'

import { useCompanyId } from 'core/stores'

import defaultQueryConfig from './defaultQueryConfig'
import getIsEnabled from './getIsEnabled'

/** @deprecated Use useTypedQuery */
const usePeachQuery = (options: { [key: string]: any }) => {
  const companyId = useCompanyId()

  const { queryKey, queryFn, ...restConfig } = options

  const enabled = !!(companyId && getIsEnabled(options))

  const query = useQuery({
    ...defaultQueryConfig,
    ...restConfig,
    queryKey: [...queryKey],
    queryFn,
    enabled,
  })

  const resp: any = query?.data || {}

  const result = resp?.data || resp

  return [result, query]
}

export default usePeachQuery
