// Third party ------------------------
import { parsePhoneNumberFromString } from 'libphonenumber-js'

/** Generic phone formatting */
export const formatPhone = (e164String) => {
  if (!e164String) return ''

  const phone = parsePhoneNumberFromString(e164String)
  return (
    !phone ? ''
    : phone.countryCallingCode === '1' ? phone.formatNational()
    : phone.formatInternational()
  )
}
