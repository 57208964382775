import { NoInfer, useStore } from '@tanstack/react-store'
import { Store } from '@tanstack/store'
import qs from 'qs'

import { APIendpoint } from 'core/actions/helpers/tokenHelpers'
import { rawApiCall } from 'core/api'
import { getOverride } from 'core/components/PeachOverrides/storage'
import * as R from 'core/helpers/remeda'
import { retrieve, store } from 'core/hooks/useStoredState/storage'
import type { AuthType, CompanyConfigUi, FrontendPreauthBootstrap, UserType } from 'core/types'
import { bootstrap } from 'core/types/operations'

export const isAuthType = (str: string): str is AuthType =>
  ['basic', 'google', 'oneTimeCodeEmail', 'oneTimeCodeText', 'SAML'].includes(str)

type StoreType = (FrontendPreauthBootstrap & { forceAuthType: boolean }) | null

export const bootstrapStore = new Store<StoreType>(null)

export const useBootstrapStore = <TSelected = NoInfer<StoreType>>(
  selector?: (state: NoInfer<StoreType>) => TSelected,
) => useStore(bootstrapStore, selector)

export const useUserType = () => useStore(bootstrapStore, (data) => data?.userType)

export const useCompanyId = () => useStore(bootstrapStore, (data) => data?.company?.id ?? '')

export const useCompanyInfo = () =>
  useStore(bootstrapStore, (data) => {
    const { brandAssets, ...rest } = data?.company ?? {}
    return rest
  })

export const useCompanyPaymentTypes = () =>
  useStore(bootstrapStore, (data) => {
    const { paymentTypes, ui } = data?.company ?? {}

    const hiddenTypes =
      data?.userType === 'agent' ? ui?.managePaymentMethodsPage?.agent : ui?.managePaymentMethodsPage?.borrower

    return {
      ...paymentTypes,
      check: paymentTypes?.check && hiddenTypes?.check !== 'hidden',
      creditCard: paymentTypes?.creditCard && hiddenTypes?.creditCard !== 'hidden',
      debitCard: paymentTypes?.debitCard && hiddenTypes?.debitCard !== 'hidden',
      payroll: paymentTypes?.payroll && hiddenTypes?.payroll !== 'hidden',
      paymentNetwork: paymentTypes?.check && hiddenTypes?.check !== 'hidden',
      wire: paymentTypes?.check && hiddenTypes?.check !== 'hidden',
      moneyOrder: paymentTypes?.check && hiddenTypes?.check !== 'hidden',
    }
  })

// Using a single global object for the fallback garentees that the selector
// value will maintain shallow eqaulity.
const emptyObject = {}
export const useCompanyUI = () =>
  useStore(bootstrapStore, (data) => {
    const {
      loanOptions = emptyObject as NonNullable<CompanyConfigUi['loanOptions']>,
      accountSettingsPage = emptyObject as NonNullable<CompanyConfigUi['accountSettingsPage']>,
      accountSummaryPage = emptyObject as NonNullable<CompanyConfigUi['accountSummaryPage']>,
    } = data?.company?.ui ?? {}
    return { ...data?.company?.ui, loanOptions, accountSettingsPage, accountSummaryPage }
  })

const key = 'LOCAL_BOOSTRAP_PARAMS'

const getBootstrapQuery = () => {
  if (!import.meta.env.VITE_CUSTOM_BOOTSTRAP_ENABLED) {
    return { domain: window.location.hostname }
  }

  const urlValues = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {}

  if (urlValues.domain) {
    const ret = {
      domain: urlValues.domain,
    }
    store(key, ret)
    return ret
  }

  if (urlValues.companyId && urlValues.appDomainType) {
    const ret = {
      companyId: urlValues.companyId,
      appDomainType: urlValues.appDomainType,
    }
    store(key, ret)
    return ret
  }

  const localValues = retrieve(key) || {}

  const customDomain = getOverride('domain') || localValues.domain

  if (customDomain) {
    return { domain: customDomain }
  }

  const customCompanyId = getOverride('companyId') || localValues.companyId
  const customAppDomainType = getOverride('appDomainType') || localValues.appDomainType

  if (customCompanyId && customAppDomainType) {
    return { companyId: customCompanyId, appDomainType: customAppDomainType }
  }

  if (import.meta.env.VITE_CUSTOM_DOMAIN) {
    return { domain: import.meta.env.VITE_CUSTOM_DOMAIN }
  }

  if (import.meta.env.VITE_CUSTOM_COMPANY_ID && import.meta.env.VITE_CUSTOM_APP_DOMAIN_TYPE) {
    return {
      companyId: import.meta.env.VITE_CUSTOM_COMPANY_ID,
      appDomainType: import.meta.env.VITE_CUSTOM_APP_DOMAIN_TYPE,
    }
  }

  return { domain: window.location.hostname }
}

export const fetchBootstrap = async () => {
  const data: FrontendPreauthBootstrap = await rawApiCall({
    url: APIendpoint + bootstrap.path,
    params: getBootstrapQuery(),
  })

  const authTypeOverride = qs.parse(window.location.search, { ignoreQueryPrefix: true })?.authType
  const forceAuthType = R.isString(authTypeOverride) && isAuthType(authTypeOverride)
  const { balanceCheck, achVerification } = data.company?.paymentTypes ?? {}

  bootstrapStore.setState(() => ({
    ...data,
    forceAuthType,
    authType: forceAuthType ? authTypeOverride : data.authType,
    authValueType: data.authValueType ?? 'email',
    userType:
      (getOverride('userType') as UserType) ||
      (data.appDomainType === 'agent' || data.appDomainType === 'admin' ? 'agent'
      : data.appDomainType === 'borrower' ? 'borrower'
      : data.userType) ||
      'borrower',
    company: {
      ...data.company,
      paymentTypes: {
        ...data.company?.paymentTypes,
        balanceCheck: {
          ...balanceCheck,
          plaid: (getOverride('plaidBalanceCheck') as boolean) ?? balanceCheck?.plaid,
        },
        achVerification: {
          ...achVerification,
          plaid: (getOverride('plaidVerification') as boolean) ?? achVerification?.plaid,
          microdeposit: (getOverride('microdepositVerification') as boolean) ?? achVerification?.microdeposit,
        },
      },
    },
  }))
}
