import PropTypes from 'prop-types'

import { redirectToApp } from 'app/loginRedirect/loginRedirectHelpers'
import LoginFormCard from 'noauth/pages/Login/LoginFormCard'
import LoginSubmitButton from 'noauth/pages/Login/LoginSubmitButton'

import { getStoredToken } from 'core/actions/constants'
import { isTokenExpired, setIsSamlInProgress } from 'core/actions/helpers/tokenHelpers'
import { Spinner } from 'core/components'
import { clearQueryParams } from 'core/helpers/queryParams'
import { useOnMount, useWrite } from 'core/hooks'
import { useAppDispatch } from 'core/hooks/useAppStore'
import { useBootstrapStore } from 'core/stores'

import useSamlParams from './useSamlParams'

const DEFAULT_ERROR_TEXT =
  'Your login session has expired or there was a problem with your login information. Please try again or contact support.'

// known error types `SAML_NO_LOGIN_FOUND`, `SAML_CONFIG_ERROR`

const SamlLogin = ({ onLogin }) => {
  const { ssoLoginUrl } = useBootstrapStore()
  const dispatch = useAppDispatch()
  const {
    hasSamlCreds,
    hasSamlError,
    immediatelyRedirect,
    samlErrorMessage,
    samlErrorType,
    samlUsername,
    samlPassword,
  } = useSamlParams()

  const [onSubmitSamlCreds, sending, resp, error, clear] = useWrite(() =>
    onLogin({ username: samlUsername, password: samlPassword }),
  )

  const onLoginWithSSO = () => {
    clear()
    window.location.href = ssoLoginUrl
  }

  useOnMount(() => {
    clearQueryParams()

    const token = getStoredToken()
    const isValidSession = token && !isTokenExpired(token)

    if (hasSamlCreds || immediatelyRedirect) {
      setIsSamlInProgress(true)

      if (isValidSession) {
        // SSO has very recently been completed. We can skip the SAML
        // authentication and proceed with the token in storage.
        dispatch({ type: 'GENERATE_TOKEN_SUCCESS' })
        redirectToApp()
      } else if (!hasSamlError) {
        if (immediatelyRedirect) {
          // User has been linked to /sso-login
          // Immediately redirect them to the configured SSO URL
          onLoginWithSSO()
        } else {
          // Authenticate with SAML credentials
          onSubmitSamlCreds()
        }
      }
    }
  })

  const hasError = samlErrorMessage || samlErrorType || error

  const errorMsg = hasError ? DEFAULT_ERROR_TEXT : undefined

  const disabled = !!(!ssoLoginUrl || resp || sending || hasSamlError || error || immediatelyRedirect)

  const isWorkingAutomatically = !hasError && (hasSamlCreds || immediatelyRedirect)

  if (isWorkingAutomatically) {
    return <Spinner height='120px' />
  }

  return (
    <LoginFormCard>
      <LoginSubmitButton
        disabled={disabled}
        errorMessage={errorMsg}
        onSubmit={onLoginWithSSO}
        sending={sending || immediatelyRedirect}
        submitLabel='Login'
      />
    </LoginFormCard>
  )
}

SamlLogin.propTypes = {
  onLogin: PropTypes.func,
}

export default SamlLogin
