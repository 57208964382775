import { SvgIcon } from './SvgIcon'

const DiscoverIcon = () => (
  <SvgIcon>
    <path
      d='M40 31.428a2.365 2.365 0 0 1-2.364 2.365H2.364A2.365 2.365 0 0 1 0 31.428V8.572a2.364 2.364 0 0 1 2.364-2.365h35.272A2.364 2.364 0 0 1 40 8.572v22.856Z'
      fill='#34495E'
    />
    <path
      d='M24.171 19.984a2.908 2.908 0 0 0-.847-2.074 2.907 2.907 0 0 0-2.08-.847h-.01A2.917 2.917 0 0 0 18.296 20a2.938 2.938 0 0 0 2.938 2.938c1.638 0 2.937-1.3 2.937-2.938v-.016Z'
      fill='#F26E21'
    />
    <path
      d='M17.75 21.243c-.44.44-.844.611-1.28.62-1.064-.01-1.846-.797-1.846-1.92 0-.565.214-1.028.553-1.423a1.725 1.725 0 0 1 1.198-.497c.509 0 .904.17 1.356.621v-1.3c-.452-.225-.848-.338-1.356-.338a3.273 3.273 0 0 0-1.914.797 2.82 2.82 0 0 0-.415.454A2.848 2.848 0 0 0 13.437 20c0 1.639 1.3 2.881 2.938 2.881h.057c.451 0 .847-.113 1.356-.338l-.038-1.3c-.02.019.019.037 0 .055v-.055ZM27.88 17.815 26.6 20.96l-1.468-3.728h-1.186l2.316 5.706h.621l2.43-5.706h-1.187l-.246.583ZM29.764 19.718v3.05h3.107v-.904H30.894V20.34h1.921v-.96h-1.92v-1.187h1.976v-.96h-3.107v2.486ZM4.23 17.232H2.646v5.536H4.23c.848 0 1.469-.226 1.977-.621A2.794 2.794 0 0 0 7.223 20c0-1.639-1.186-2.768-2.994-2.768Zm1.299 4.18c-.34.283-.791.452-1.47.452h-.338v-3.672h.282c.678 0 1.13.113 1.47.452.394.339.62.848.62 1.356a1.69 1.69 0 0 1-.564 1.413ZM8.805 17.231H7.732v5.537h1.073V17.23ZM11.517 19.378c-.678-.225-.848-.395-.848-.677 0-.34.34-.622.791-.622.34 0 .622.113.904.452l.565-.734a2.46 2.46 0 0 0-1.638-.622c-.96 0-1.752.678-1.752 1.582 0 .791.34 1.13 1.356 1.526.452.169.622.226.735.338a.622.622 0 0 1 .339.565c0 .452-.339.791-.848.791-.509 0-.96-.282-1.186-.734l-.678.678c.509.734 1.13 1.073 1.92 1.073 1.13 0 1.921-.734 1.921-1.808 0-.96-.338-1.355-1.581-1.808ZM35.922 20.452c.847-.17 1.3-.735 1.3-1.582 0-1.017-.735-1.638-1.978-1.638h-1.638v5.536h1.073v-2.203h.17l1.525 2.203h1.3l-1.752-2.316Zm-.904-.678h-.339v-1.695h.339c.678 0 1.074.282 1.074.848 0 .564-.396.847-1.074.847Z'
      fill='#E7E8E3'
    />
  </SvgIcon>
)

export default DiscoverIcon
