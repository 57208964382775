import { useState, useEffect } from 'react'

import { getStoredToken, decodeToken } from 'core/actions/helpers/tokenHelpers'
import ErrorLogging from 'core/system/ErrorLogging'

const useErrorLoggingTag = (tag, value) => {
  useEffect(() => {
    ErrorLogging.setTag(tag, value)
    return () => ErrorLogging.setTag(tag, undefined)
  }, [tag, value])
}

const useErrorLoggingScope = (options) => {
  const { company, appDomainType, userType } = options

  // putting this in state so it doesn't re-parse on each render
  const [token] = useState(() => decodeToken(getStoredToken()))

  useEffect(() => {
    ErrorLogging.setUser({ id: token?.sub })
  }, [token?.sub])

  useErrorLoggingTag('appDomainType', appDomainType)

  useErrorLoggingTag('company.id', company?.id)

  useErrorLoggingTag('company.name', company?.legalName | company?.brandName)

  useErrorLoggingTag('person.id', token?.personId)

  useErrorLoggingTag('employee.id', token?.employeeId)

  useErrorLoggingTag('user.type', userType)
}

export { useErrorLoggingScope, useErrorLoggingTag }
