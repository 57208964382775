export const retrieve = (key) => {
  const item = localStorage.getItem(key)

  if (!key || !item) return undefined

  try {
    return JSON.parse(item)
  } catch {
    return undefined
  }
}

export const store = (key, value) => {
  if (!key) return
  try {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.removeItem(key)
    }
  } catch {
    // Ignore error
  }
}

const sessionCache = {}

export const retrieveSession = (key) => {
  const item = sessionStorage.getItem(key)

  if (!key || !item) return undefined

  if (sessionCache[key]) {
    return sessionCache[key]
  }

  try {
    const value = JSON.parse(item)

    sessionCache[key] = value

    return value
  } catch {
    return undefined
  }
}

export const storeSession = (key, value) => {
  if (!key) return
  try {
    if (value) {
      sessionStorage.setItem(key, JSON.stringify(value))
    } else {
      sessionStorage.removeItem(key)
    }
  } catch {
    // Ignore error
  }
}
