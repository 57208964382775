import PropTypes from 'prop-types'
import { styled } from 'styled-components'

import Button from 'core/components/lib/Button'
import CardSection from 'core/components/lib/CardSection'

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: ${(p) => p.margin};
`

function Submit({
  submitPrimaryText,
  submitSecondaryText,
  cancelText,
  onSubmitPrimary,
  onSubmitSecondary,
  primaryDisabled,
  secondaryDisabled,
  onCancel,
}) {
  return (
    <CardSection>
      <ButtonsContainer margin='16px 0 0 0'>
        <Button margin='0 0 0 32px' onClick={onSubmitPrimary} readOnly={primaryDisabled} secondary>
          {submitPrimaryText}
        </Button>
        {submitSecondaryText && (
          <Button margin='0 0 0 32px' onClick={onSubmitSecondary} readOnly={secondaryDisabled} secondary>
            {submitSecondaryText}
          </Button>
        )}
        {cancelText && (
          <Button color='black' onClick={onCancel} secondary>
            {cancelText}
          </Button>
        )}
      </ButtonsContainer>
    </CardSection>
  )
}

Submit.propTypes = {
  submitPrimaryText: PropTypes.string.isRequired,
  submitSecondaryText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmitPrimary: PropTypes.func,
  onSubmitSecondary: PropTypes.func,
  onCancel: PropTypes.func,
  primaryDisabled: PropTypes.bool,
  secondaryDisabled: PropTypes.bool,
}

export default Submit
