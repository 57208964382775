/**
 * DEPRECATED! DO NOT IMPORT!
 * PLEASE IMPORT FROM `core/components` INSTEAD
 *
 * If importing from admin sub-components it should be fine, but
 * if importing from CRM or Loan app, it will cause circular
 * dependency issues. Regardless of app location, all imports of
 * <Link /> should come from `core/components` as it is a shared
 * component amongst all frontend applications.
 */
import { ReactNode, JSX } from 'react'

import { Link as RouterLink } from 'react-router-dom'
import { styled, css } from 'styled-components'

import variables from 'core/styles/variables'

const linkStyles = css`
  cursor: pointer;
  color: ${variables.colorBluePrimary};
  font-size: inherit;

  &:hover {
    text-decoration: underline;
  }
`

const StyledRouterLink = styled(RouterLink)`
  ${linkStyles};
`

const StyledExternalLink = styled.a`
  ${linkStyles};
`

const StyledButton = styled.button`
  display: inline;
  margin: 0;
  border: none;
  background-color: transparent;
  padding: 0;

  ${linkStyles};
`

const DisabledLink = styled.span``

type Props = {
  href?: string
  to?: string
  disabled?: boolean
  onClick?: () => void
  children: ReactNode
  // ...rest
  // ideally, this should be separate components: Button and Link
  // Link would extend HTMLAnchorAttributes and Button would extend HTMLButtonAttributes
  [key: string]: any
}

const Link = ({ children, href, to, disabled = false, onClick, ...rest }: Props): JSX.Element => {
  if (to && !disabled) {
    return (
      <StyledRouterLink role='link' to={to} {...rest} onClick={onClick}>
        {children}
      </StyledRouterLink>
    )
  } else if (href && !disabled) {
    return (
      <StyledExternalLink href={href} onClick={onClick} rel='noopener noreferrer' role='link' target='_blank' {...rest}>
        {children}
      </StyledExternalLink>
    )
  } else if (onClick && !disabled) {
    return (
      <StyledButton onClick={onClick} {...rest}>
        {children}
      </StyledButton>
    )
  }
  return <DisabledLink {...rest}>{children}</DisabledLink>
}

export default Link
