import { styled } from 'styled-components'

import { PoweredByPeach } from 'core/components'
import { useCompanyInfo, useBootstrapStore } from 'core/stores'
import variables from 'core/styles/variables'

// this is styling using old school CSS
// so that it will pick  up anything custom links supplied
// for a company
const Wrapper = styled.div`
  color: ${variables.colorBlack70};

  a {
    color: ${variables.colorBluePrimary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Link = styled.a``

const termsLink = 'https://www.peachfinance.com/terms-conditions.html'

const privacyPolicyLink = 'https://www.peachfinance.com/privacy-policy.html'

const privacyNoticeLink = 'https://www.peachfinance.com/documents/Privacy-Notice.pdf'

const TermsAndConditions = () => {
  const { borrowerLoginConsent } = useBootstrapStore((data) => data?.company?.brandAssets?.copies ?? {})
  const { showPoweredByPeach } = useCompanyInfo()

  if (borrowerLoginConsent === '') {
    // empty string signals that this company has
    // specifically opted out of showing this content all
    return null
  }

  if (borrowerLoginConsent) {
    // this value being present signals that the company
    // is overriding the consent copy and will supply their own
    return (
      <Wrapper>
        <span dangerouslySetInnerHTML={{ __html: borrowerLoginConsent }} />

        {showPoweredByPeach && <PoweredByPeach />}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {'By Continuing, I agree to these '}
      <Link href={termsLink} rel='noopener noreferrer' target='_blank'>
        Terms of Use
      </Link>
      {', '}
      <Link href={privacyPolicyLink} rel='noopener noreferrer' target='_blank'>
        Privacy Policy
      </Link>
      {', and '}
      <Link href={privacyNoticeLink} rel='noopener noreferrer' target='_blank'>
        Privacy Notice
      </Link>
      {'. '}
      <PoweredByPeach />
    </Wrapper>
  )
}

export default TermsAndConditions
