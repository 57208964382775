import qs from 'qs'

import { APIendpoint, PEACH_UTM, getStoredToken } from 'core/actions/helpers/tokenHelpers'
import { retrieveSession } from 'core/hooks/useStoredState/storage'

import onUnauthorized from './onUnauthorized'
import rawApiCall from './rawApiCall'

const peachApiCall = async (options) => {
  const { url, fullUrl, query, headers, ...restOptions } = options

  const callOptions = {
    url: APIendpoint + url + qs.stringify(query, { addQueryPrefix: true, arrayFormat: 'comma' }),
    fullUrl,
    headers: {
      Authorization: `Bearer ${getStoredToken()}`,
      ...(retrieveSession(PEACH_UTM) ?? {}),
      ...headers,
    },
    ...restOptions,
  }

  try {
    const resp = await rawApiCall(callOptions)
    return resp
  } catch (error) {
    if (error.status === 401) {
      onUnauthorized()
    }
    throw error
  }
}

export default peachApiCall
