/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const cardIssuersList: OperationData<Types.CardIssuersList> = {
  method: 'get',
  name: 'cardIssuersList',
  summary: 'Get card issuers',
  path: '/card-issuers',
  queryKey: () => ['card-issuers'],
}

export const cardIssuerCreate: OperationData<Types.CardIssuerCreate> = {
  method: 'post',
  name: 'cardIssuerCreate',
  summary: 'Create card issuer',
  path: '/card-issuers',
  queryKey: () => ['card-issuers'],
}

export const cardIssuerGet: OperationData<Types.CardIssuerGet> = {
  method: 'get',
  name: 'cardIssuerGet',
  summary: 'Get card issuer by ID',
  path: '/card-issuers/{cardIssuerId}',
  queryKey: ({ cardIssuerId }) => ['card-issuers', cardIssuerId],
}

export const cardIssuerUpdate: OperationData<Types.CardIssuerUpdate> = {
  method: 'put',
  name: 'cardIssuerUpdate',
  summary: 'Update card issuer',
  path: '/card-issuers/{cardIssuerId}',
  queryKey: ({ cardIssuerId }) => ['card-issuers', cardIssuerId],
}

export const cardIssuerDelete: OperationData<Types.CardIssuerDelete> = {
  method: 'delete',
  name: 'cardIssuerDelete',
  summary: 'Delete card issuer',
  path: '/card-issuers/{cardIssuerId}',
  queryKey: ({ cardIssuerId }) => ['card-issuers', cardIssuerId],
}

export const cardsList: OperationData<Types.CardsList> = {
  method: 'get',
  name: 'cardsList',
  summary: 'Get cards',
  path: '/people/{personId}/loans/{loanId}/cards',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'cards'],
}

export const cardCreate: OperationData<Types.CardCreate> = {
  method: 'post',
  name: 'cardCreate',
  summary: 'Create card',
  path: '/people/{personId}/loans/{loanId}/cards',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'cards'],
}

export const cardGet: OperationData<Types.CardGet> = {
  method: 'get',
  name: 'cardGet',
  summary: 'Get card',
  path: '/people/{personId}/loans/{loanId}/cards/{cardId}',
  queryKey: ({ personId, loanId, cardId }) => ['people', personId, 'loans', loanId, 'cards', cardId],
}

export const cardUpdate: OperationData<Types.CardUpdate> = {
  method: 'put',
  name: 'cardUpdate',
  summary: 'Update card',
  path: '/people/{personId}/loans/{loanId}/cards/{cardId}',
  queryKey: ({ personId, loanId, cardId }) => ['people', personId, 'loans', loanId, 'cards', cardId],
}

export const internalCardIssuersList: OperationData<Types.InternalCardIssuersList> = {
  method: 'get',
  name: 'internalCardIssuersList',
  summary: 'Get card issuers',
  path: '/companies/{companyId}/card-issuers',
  queryKey: ({ companyId }) => ['companies', companyId, 'card-issuers'],
}

export const internalCardIssuerCreate: OperationData<Types.InternalCardIssuerCreate> = {
  method: 'post',
  name: 'internalCardIssuerCreate',
  summary: 'Create card issuer',
  path: '/companies/{companyId}/card-issuers',
  queryKey: ({ companyId }) => ['companies', companyId, 'card-issuers'],
}

export const internalCardIssuerGet: OperationData<Types.InternalCardIssuerGet> = {
  method: 'get',
  name: 'internalCardIssuerGet',
  summary: 'Get card issuer by ID',
  path: '/companies/{companyId}/card-issuers/{cardIssuerId}',
  queryKey: ({ companyId, cardIssuerId }) => ['companies', companyId, 'card-issuers', cardIssuerId],
}

export const internalCardIssuerUpdate: OperationData<Types.InternalCardIssuerUpdate> = {
  method: 'put',
  name: 'internalCardIssuerUpdate',
  summary: 'Update card issuer',
  path: '/companies/{companyId}/card-issuers/{cardIssuerId}',
  queryKey: ({ companyId, cardIssuerId }) => ['companies', companyId, 'card-issuers', cardIssuerId],
}

export const internalCardIssuerDelete: OperationData<Types.InternalCardIssuerDelete> = {
  method: 'delete',
  name: 'internalCardIssuerDelete',
  summary: 'Delete card issuer',
  path: '/companies/{companyId}/card-issuers/{cardIssuerId}',
  queryKey: ({ companyId, cardIssuerId }) => ['companies', companyId, 'card-issuers', cardIssuerId],
}

export const marqetaReceiveEvent: OperationData<Types.MarqetaReceiveEvent> = {
  method: 'post',
  name: 'marqetaReceiveEvent',
  summary: 'Receive marqeta webhook event',
  path: '/marqeta/{cardIssuerId}/receive-event',
  queryKey: ({ cardIssuerId }) => ['marqeta', cardIssuerId, 'receive-event'],
}

export const receiveJit: OperationData<Types.ReceiveJit> = {
  method: 'post',
  name: 'receiveJit',
  summary: 'Receive marqeta JIT funding request',
  path: '/marqeta/{cardIssuerId}/receive-jit',
  queryKey: ({ cardIssuerId }) => ['marqeta', cardIssuerId, 'receive-jit'],
}

export const lithicReceiveEvent: OperationData<Types.LithicReceiveEvent> = {
  method: 'post',
  name: 'lithicReceiveEvent',
  summary: 'Receive lithic webhook event',
  path: '/lithic/{cardIssuerId}/receive-event',
  queryKey: ({ cardIssuerId }) => ['lithic', cardIssuerId, 'receive-event'],
}

export const receiveAsa: OperationData<Types.ReceiveAsa> = {
  method: 'post',
  name: 'receiveAsa',
  summary: 'Receive lithic ASA event',
  path: '/lithic/{cardIssuerId}/receive-asa',
  queryKey: ({ cardIssuerId }) => ['lithic', cardIssuerId, 'receive-asa'],
}
