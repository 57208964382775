import { lazy } from 'react'

import BorrowerRoutes from './BorrowerRoutes'
import withSuspense from './withSuspense'

const LazyAgentRoutes = lazy(() => import('app/routes/AgentRoutes'))

const AgentRoutes = withSuspense(LazyAgentRoutes)

export { AgentRoutes, BorrowerRoutes }
