import { checkTimestring } from 'core/time/internal/types'
import mtz from 'core/time/internal/utils/mtz'

const makeTimestringToDatestring = (context = {}) => {
  const { timezone } = context

  const timestringToDatestring = (timestring) => {
    checkTimestring(timestring)

    return mtz(timestring, timezone).format('Y-MM-DD')
  }

  return timestringToDatestring
}

export default makeTimestringToDatestring
