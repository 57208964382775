import { SvgIcon } from './SvgIcon'

const SparklesIcon = () => (
  <SvgIcon>
    <path d='m11.095 23.219.423 2.852c.378 2.551 2.177 4.55 4.473 4.97l2.567.47-2.567.47c-2.296.42-4.095 2.42-4.473 4.97l-.423 2.853-.423-2.852c-.378-2.551-2.178-4.55-4.474-4.97l-2.567-.47 2.567-.47c2.296-.42 4.096-2.42 4.474-4.97ZM6.948 0c.13 5.413 3.026 9.757 6.634 9.951-3.608.194-6.504 4.538-6.634 9.951-.13-5.413-3.025-9.757-6.634-9.951C3.924 9.757 6.82 5.413 6.948 0Z' />
    <path d='m24.048.196.736 5.889A15.923 15.923 0 0 0 37.995 19.82l1.69.278-1.69.278a15.921 15.921 0 0 0-13.21 13.735L24.047 40l-.736-5.889a15.922 15.922 0 0 0-13.21-13.735l-1.691-.278 1.69-.278A15.922 15.922 0 0 0 23.312 6.085Z' />
  </SvgIcon>
)

export default SparklesIcon
