import { FC } from 'react'

import AlertDialog, { AlertDialogProps } from 'core/components/lib/AlertDialog/AlertDialog'

const PermissionDeniedDialog: FC<Omit<AlertDialogProps, 'content'>> = ({ title = 'Permission', ...props }) => (
  <AlertDialog
    content='You do not have permission to perform this action. Please contact your supervisor for help.'
    title={title}
    {...props}
  />
)

export default PermissionDeniedDialog
