/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const creditAgenciesList: OperationData<Types.CreditAgenciesList> = {
  method: 'get',
  name: 'creditAgenciesList',
  summary: 'Get credit agencies',
  path: '/companies/{companyId}/credit-agencies',
  queryKey: ({ companyId }) => ['companies', companyId, 'credit-agencies'],
}

export const creditAgencyCreate: OperationData<Types.CreditAgencyCreate> = {
  method: 'post',
  name: 'creditAgencyCreate',
  summary: 'Create credit agency',
  path: '/companies/{companyId}/credit-agencies',
  queryKey: ({ companyId }) => ['companies', companyId, 'credit-agencies'],
}

export const reportedLoans: OperationData<Types.ReportedLoans> = {
  method: 'get',
  name: 'reportedLoans',
  summary: 'Get reported loans',
  path: '/companies/{companyId}/credit-reporting/reported-loans',
  queryKey: ({ companyId }) => ['companies', companyId, 'credit-reporting', 'reported-loans'],
}

export const creditAgencyGet: OperationData<Types.CreditAgencyGet> = {
  method: 'get',
  name: 'creditAgencyGet',
  summary: 'Get credit agency by ID',
  path: '/companies/{companyId}/credit-agencies/{creditAgencyId}',
  queryKey: ({ companyId, creditAgencyId }) => ['companies', companyId, 'credit-agencies', creditAgencyId],
}

export const creditAgencyUpdate: OperationData<Types.CreditAgencyUpdate> = {
  method: 'put',
  name: 'creditAgencyUpdate',
  summary: 'Update credit agency',
  path: '/companies/{companyId}/credit-agencies/{creditAgencyId}',
  queryKey: ({ companyId, creditAgencyId }) => ['companies', companyId, 'credit-agencies', creditAgencyId],
}

export const generateMetro2Files: OperationData<Types.GenerateMetro2Files> = {
  method: 'post',
  name: 'generateMetro2Files',
  summary: 'Generate company credit reports',
  path: '/companies/{companyId}/generate-metro2-files',
  queryKey: ({ companyId }) => ['companies', companyId, 'generate-metro2-files'],
}

export const creditReportingStatusesList: OperationData<Types.CreditReportingStatusesList> = {
  method: 'get',
  name: 'creditReportingStatusesList',
  summary: 'Get credit reporting statuses',
  path: '/people/{personId}/loans/{loanId}/credit-reporting',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-reporting'],
}

export const creditReportingFieldsUpdate: OperationData<Types.CreditReportingFieldsUpdate> = {
  method: 'put',
  name: 'creditReportingFieldsUpdate',
  summary: 'Update reporting fields',
  path: '/people/{personId}/loans/{loanId}/credit-reporting',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-reporting'],
}

export const creditReportingStatusCreate: OperationData<Types.CreditReportingStatusCreate> = {
  method: 'post',
  name: 'creditReportingStatusCreate',
  summary: 'Start credit reporting',
  path: '/people/{personId}/loans/{loanId}/credit-reporting',
  queryKey: ({ personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-reporting'],
}

export const creditReportingStatusDelete: OperationData<Types.CreditReportingStatusDelete> = {
  method: 'post',
  name: 'creditReportingStatusDelete',
  summary: 'Delete from credit reporting',
  path: '/people/{personId}/loans/{loanId}/credit-reporting/{creditAgencyId}/delete',
  queryKey: ({ personId, loanId, creditAgencyId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'credit-reporting',
    creditAgencyId,
    'delete',
  ],
}

export const getMetro2Data: OperationData<Types.GetMetro2Data> = {
  method: 'get',
  name: 'getMetro2Data',
  summary: 'Get metro2 data',
  path: '/people/{personId}/loans/{loanId}/credit-reporting/{agencyName}/metro2-data',
  queryKey: ({ personId, loanId, agencyName }) => [
    'people',
    personId,
    'loans',
    loanId,
    'credit-reporting',
    agencyName,
    'metro2-data',
  ],
}

export const fileCreationRecordCreate: OperationData<Types.FileCreationRecordCreate> = {
  method: 'post',
  name: 'fileCreationRecordCreate',
  summary: 'Create file log record',
  path: '/credit-reporting/create-file-record',
  queryKey: () => ['credit-reporting', 'create-file-record'],
}
