import { checkTimeordate } from 'core/time/internal/types'
import mtz from 'core/time/internal/utils/mtz'

const makeGetDay = (context = {}) => {
  const { timezone }: any = context

  const getDay = (timeordate: string) => {
    checkTimeordate(timeordate)

    return mtz(timeordate, timezone).day()
  }

  return getDay
}

export default makeGetDay
