import { APIendpoint, getStoredToken } from './helpers/tokenHelpers'

export { APIendpoint, getStoredToken }

/** action types */
export const CLEAR_DOCUMENT_DESCRIPTORS = 'CLEAR_DOCUMENT_DESCRIPTORS'
export const CLONE_CONTACT = 'CLONE_CONTACT'
export const CREATE_CONTACT = 'CREATE_CONTACT'
export const CREATE_DOCUMENT_DESCRIPTORS = 'CREATE_DOCUMENT_DESCRIPTORS'
export const DELETE_CONTACTS = 'DELETE_CONTACTS'
export const GET_AUTOPAY_PREVIEW_ERROR = 'GET_AUTOPAY_PREVIEW_ERROR'
export const GET_AUTOPAY_PREVIEW_INTERNAL_ERROR = 'GET_AUTOPAY_PREVIEW_INTERNAL_ERROR'
export const GET_CONSENTS = 'GET_CONSENTS'
export const GET_CONTACT_V2 = 'GET_CONTACT_V2'
export const GET_CONTACTS_V2 = 'GET_CONTACTS_V2'
export const GET_DOCUMENT_DESCRIPTORS = 'GET_DOCUMENT_DESCRIPTORS'
export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const GET_EXPECTED_PAYMENTS_PREVIEW_ERROR = 'GET_EXPECTED_PAYMENTS_PREVIEW_ERROR'
export const GET_EXPECTED_PAYMENTS_PREVIEW_INTERNAL_ERROR = 'GET_EXPECTED_PAYMENTS_PREVIEW_INTERNAL_ERROR'
export const GET_LEGAL_REPRESENTATIVES = 'GET_LEGAL_REPRESENTATIVES'
export const GET_LOANS = 'GET_LOANS'
export const GET_LOANS_NEXT_URL = 'GET_LOANS_NEXT_URL'
export const GET_LOAN_BY_ID = 'GET_LOAN_BY_ID'
export const GET_LOAN_TYPES = 'GET_LOAN_TYPES'
export const GET_PAYMENT_INSTRUMENTS = 'GET_PAYMENT_INSTRUMENTS'
export const GET_PEOPLE = 'GET_PEOPLE'
export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const GET_PERSON = 'GET_PERSON'
export const GET_TEAMS = 'GET_TEAMS'
export const GET_TEAM = 'GET_TEAM'
export const GET_VERIFICATIONS = 'GET_VERIFICATIONS'
export const LOADING = 'LOADING'
export const SEND_COMMUNICATION_FAILED = 'SEND_COMMUNICATION_FAILED'
export const SEND_COMMUNICATION_INTERNAL_SERVER_ERROR = 'SEND_COMMUNICATION_INTERNAL_SERVER_ERROR'
export const SET_TOO_MANY_VERIFICATION_REQUESTS = 'SET_TOO_MANY_VERIFICATION_REQUESTS'
export const SET_UNABLE_TO_VERIFY_BORROWER = 'SET_UNABLE_TO_VERIFY_BORROWER'
export const START_VERIFICATION = 'START_VERIFICATION'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const UPDATE_DOCUMENT_DESCRIPTOR = 'UPDATE_DOCUMENT_DESCRIPTOR'
export const UPDATE_PERSON = 'UPDATE_PERSON'
