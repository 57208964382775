import { APIendpoint, GET_VERIFICATIONS, LOADING, getStoredToken } from 'core/actions/constants'
import { GET } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const getVerifications = ({ key, filters = {} } = { filters: {} }) => {
  const { personId } = filters
  return async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    const endpoint = `${APIendpoint}/people/${personId}/verifications`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: GET_VERIFICATIONS,
        payload: responseBody.data,
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }
}

export default getVerifications
