import { GET_LOANS } from 'core/actions/constants'
import getCollectionWithNextUrl from 'core/actions/helpers/getCollectionWithNextUrl'

const getLoans = ({ key, filters = {} } = {}) => {
  const { personId } = filters

  return async (dispatch) => {
    const url = `/people/${personId}/loans?status=all&limit=100&includeAdditionalBorrowers=true`
    return await getCollectionWithNextUrl({
      dispatch,
      key,
      filters,
      type: GET_LOANS,
      url,
      saveOptions: { personId },
    })
  }
}

export default getLoans
