import _ from 'lodash'

import { GET_PERMISSIONS } from 'core/actions/constants'

const initialState = { byCompanyId: {}, hashByCompanyId: {} }

const permissions = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS: {
      const byCompanyId = action.payload
      const hashByCompanyId = _.mapValues(byCompanyId, (list) => _.fromPairs(_.map(list, (key) => [key, true])))
      return { byCompanyId, hashByCompanyId }
    }
    default:
      return state
  }
}

export default permissions
