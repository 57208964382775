import { useRef, useEffect } from 'react'

import _ from 'lodash'

// this hook is called whenever a value in the deps list changes.
// it does not get called on initial mount

export default function useOnChangeEffect<T extends Array<any>>(onChange: (changes: T) => void, deps: T) {
  const prevListRef = useRef(deps)

  useEffect(() => {
    prevListRef.current = deps
  }, [deps])

  const prevList = prevListRef.current

  useEffect(() => {
    if (!prevList || !deps) return

    // if deps value previous existed (i.e. not initial render)
    // and any of the values have changed,
    const hasChanged = _.some(deps, (item, index) => item !== prevList[index])

    if (hasChanged) {
      onChange(prevList)
    }
  }, [deps, prevList, onChange])
}
