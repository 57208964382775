import mtz from 'core/time/internal/utils/mtz'

const makeIsSameDay = (context = {}) => {
  const { timezone } = context

  const isSameDay = (a, b) => {
    const momentA = mtz(a, timezone)
    const momentB = mtz(b, timezone)
    return momentA.isSame(momentB, 'day')
  }

  return isSameDay
}

export default makeIsSameDay
