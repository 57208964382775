import _ from 'lodash'

import {
  LOADING,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACTS,
  APIendpoint,
  getStoredToken,
} from 'core/actions/constants'
import { GET, POSTwithToken, PUTwithToken, DELETE } from 'core/api/legacyApi'
import { getGlobalValue } from 'core/badGlobalDoNotUse'
import { handleErrors } from 'core/helpers/handleErrors'

export const GET_CONTACTS = 'GET_CONTACTS'
export const SET_PLAID_SAVING = 'SET_PLAID_SAVING'
export const SET_PLAID_INSTRUMENT = 'SET_PLAID_INSTRUMENT'
export const SET_PLAID_FAILED = 'SET_PLAID_FAILED'
export const SET_PLAID_INTERNAL_ERROR = 'SET_PLAID_INTERNAL_ERROR'
export const RESET_PLAID_FLAG = 'RESET_PLAID_FLAG'
export const SET_BANK_INSTRUMENT = 'SET_BANK_INSTRUMENT'
export const SET_BANK_INSTRUMENT_ERROR = 'SET_BANK_INSTRUMENT_ERROR'
export const UPDATE_BANK_INSTRUMENT = 'UPDATE_BANK_INSTRUMENT'
export const DELETE_BANK_INSTRUMENT = 'DELETE_BANK_INSTRUMENT'
export const SET_DEBIT_INSTRUMENT = 'SET_DEBIT_INSTRUMENT'
export const GET_SPECIFIC_BANK_INSTRUMENT = 'GET_SPECIFIC_BANK_INSTRUMENT'
export const UPDATE_CARD_INSTRUMENT = 'UPDATE_CARD_INSTRUMENT'
export const CANCEL_EDIT_MODE = 'CANCEL_EDIT_MODE'
export const CLEAR_PLAID_DATA = 'CLEAR_PLAID_DATA'
export const CLEAR_BANK_DATA = 'CLEAR_BANK_DATA'
export const CLEAR_CARD_DATA = 'CLEAR_CARD_DATA'
export const CLEAR_BANK_ERROR = 'CLEAR_BANK_ERROR'
export const CLEAR_CARD_ERROR = 'CLEAR_CARD_ERROR'
export const VERIFY_ROUTING_NUMBER_SUCCESS = 'VERIFY_ROUTING_NUMBER_SUCCESS'
export const VERIFY_ROUTING_NUMBER_FAILED = 'VERIFY_ROUTING_NUMBER_FAILED'
export const SET_INITIAL_DOCUMENT_DESCRIPTOR = 'SET_INITIAL_DOCUMENT_DESCRIPTOR'
export const FINALIZE_DOCUMENT_DESCRIPTOR = 'FINALIZE_DOCUMENT_DESCRIPTOR'
export const DELETE_DOCUMENT_DESCRIPTOR = 'DELETE_DOCUMENT_DESCRIPTOR'
export const CONVERT_FINAL_DOCUMENT_PDF = 'CONVERT_FINAL_DOCUMENT_PDF'
export const GET_ALL_RELATED_DOCUMENTS = 'GET_ALL_RELATED_DOCUMENTS'
export const SET_DEBIT_INSTRUMENT_ERROR = 'SET_DEBIT_INSTRUMENT_ERROR'
export const CREATE_CONTACTS_SUCCESS = 'CREATE_CONTACTS_SUCCESS'
export const CREATE_CONTACTS_FAILED = 'CREATE_CONTACTS_FAILED'
export const CREATE_CONTACTS_CLEAR_ERROR = 'CREATE_CONTACTS_CLEAR_ERROR'
export const CLEAR_CONTACT_DATA = 'CLEAR_CONTACT_DATA'
export const UPDATE_CONTACTS_FAILED = 'UPDATE_CONTACTS_FAILED'
export const CREATE_CONTACTS_TIMEOUT = 'CREATE_CONTACTS_TIMEOUT'
export const UPDATE_CONTACTS_TIMEOUT = 'UPDATE_CONTACTS_TIMEOUT'
export const CREATE_LEGAL_REP_SUCCESS = 'CREATE_LEGAL_REP_SUCCESS'
export const CREATE_LEGAL_REP_FAILED = 'CREATE_LEGAL_REP_FAILED'
export const GET_LEGAL_REP_SUCCESS = 'GET_LEGAL_REP_SUCCESS'
export const GET_LEGAL_REP_FAILED = 'GET_LEGAL_REP_FAILED'
export const UPDATE_LEGAL_REP_SUCCESS = 'UPDATE_LEGAL_REP_SUCCESS'
export const UPDATE_LEGAL_REP_FAILED = 'UPDATE_LEGAL_REP_FAILED'
export const CLONE_CONTACT = 'CLONE_CONTACT'
export const CLONE_CONTACTS_FAILED = 'CLONE_CONTACTS_FAILED'
export const CLONE_CONTACTS_TIMEOUT = 'CLONE_CONTACTS_TIMEOUT'

const parseMessage = async (respBody) => {
  try {
    const json = await respBody.json()
    return json?.message
  } catch {
    return undefined
  }
}

const getPersonId = () => getGlobalValue('borrowerPersonId')

const getPeopleEndpoint = () => `${APIendpoint}/people/${getPersonId()}`

export const getContacts = () => (dispatch) => {
  const endpoint = `${getPeopleEndpoint()}/contacts`

  return fetch(endpoint, GET(getStoredToken()))
    .then(handleErrors)
    .then((response) => {
      dispatch({
        type: GET_CONTACTS,
        payload: response,
      })
      return response
    })
}

export const createContact =
  ({ confirmationCode, data, key }) =>
  async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    const endpoint =
      confirmationCode ?
        `${getPeopleEndpoint()}/contacts?confirmationCode=${confirmationCode}`
      : `${getPeopleEndpoint()}/contacts`
    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), data))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400
    const timeoutRequest = response.status === 408

    let hasCollision
    let codeMismatch

    if (badRequest) {
      const message = await parseMessage(responseBody)
      hasCollision = message === 'collision detected'
      codeMismatch = message === 'Confirmation code mismatch'
    }

    if (isSuccess) {
      dispatch({
        type: CREATE_CONTACT,
        payload: responseBody.data,
      })
    }

    if (badRequest) {
      dispatch({
        type: CREATE_CONTACTS_FAILED,
        payload:
          hasCollision ? 'The contact information that you are trying to save is not unique.'
          : codeMismatch ? `The verification code you've entered is incorrect.`
          : `Your request couldn't be processed. Please try again later or contact customer support.`,
      })
    }

    if (timeoutRequest) {
      dispatch({
        type: CREATE_CONTACTS_TIMEOUT,
        payload: 'Session has timed out. Resend code.',
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }

export const cloneContact =
  ({ confirmationCode, contactId, data, key }) =>
  async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    const endpoint =
      confirmationCode ?
        `${getPeopleEndpoint()}/contacts/${contactId}/clone?confirmationCode=${confirmationCode}`
      : `${getPeopleEndpoint()}/contacts/${contactId}/clone`

    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), data))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400
    const timeoutRequest = response.status === 408

    let hasCollision
    let codeMismatch

    if (badRequest) {
      const message = await parseMessage(responseBody)
      hasCollision = message === 'collision detected'
      codeMismatch = message === 'Confirmation code mismatch'
    }

    if (isSuccess) {
      dispatch({
        type: CLONE_CONTACT,
        payload: { data: responseBody.data, contactId },
      })
    }

    if (badRequest) {
      dispatch({
        type: CLONE_CONTACTS_FAILED,
        // TODO: we need better error handling messages
        // for this purpose, I will be hardcoding a message that is
        // tailored for confirmationCode validation
        payload:
          hasCollision ? 'The contact information that you are trying to save is not unique.'
          : codeMismatch ? `The verification code you've entered is incorrect.`
          : `Your request couldn't be processed. Please try again later or contact customer support.`,
      })
    }

    if (timeoutRequest) {
      dispatch({
        type: CLONE_CONTACTS_TIMEOUT,
        payload: 'Session has timed out. Resend code.',
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }

export const updateContact =
  ({ confirmationCode, contactId, data, key }) =>
  async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    const endpoint =
      confirmationCode ?
        `${getPeopleEndpoint()}/contacts/${contactId}?confirmationCode=${confirmationCode}`
      : `${getPeopleEndpoint()}/contacts/${contactId}`

    const response = await fetch(endpoint, PUTwithToken(getStoredToken(), data))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400
    const timeoutRequest = response.status === 408

    let hasCollision
    let codeMismatch

    if (badRequest) {
      const message = await parseMessage(responseBody)
      hasCollision = message === 'collision detected'
      codeMismatch = message === 'Confirmation code mismatch'
    }

    if (isSuccess) {
      dispatch({
        type: UPDATE_CONTACT,
        payload: responseBody.data,
      })
    }

    if (badRequest) {
      dispatch({
        type: UPDATE_CONTACTS_FAILED,
        // TODO: we need better error handling messages
        // for this purpose, I will be hardcoding a message that is
        // tailored for confirmationCode validation
        payload:
          hasCollision ? 'The contact information that you are trying to save is not unique.'
          : codeMismatch ? `The verification code you've entered is incorrect.`
          : `Your request couldn't be processed. Please try again later or contact customer support.`,
      })
    }

    if (timeoutRequest) {
      dispatch({
        type: UPDATE_CONTACTS_TIMEOUT,
        payload: 'Session has timed out. Resend code.',
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }

export const deleteContact = (contactId) => (dispatch) => {
  const endpoint = `${getPeopleEndpoint()}/contacts/${contactId}`

  return fetch(endpoint, DELETE(getStoredToken()))
    .then(handleErrors)
    .then((response) => {
      dispatch({
        type: DELETE_CONTACTS,
        payload: contactId,
      })
      return response
    })
}

export const createLegalRep =
  ({ key, data }) =>
  async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    const endpoint = `${getPeopleEndpoint()}/legal-representatives`
    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), data))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400

    if (isSuccess) {
      dispatch({
        type: CREATE_LEGAL_REP_SUCCESS,
        payload: responseBody.data,
      })
    }

    if (badRequest) {
      dispatch({
        type: CREATE_LEGAL_REP_FAILED,
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }

export const updateLegalRep =
  ({ key, repId, data }) =>
  async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }

    const endpoint = `${getPeopleEndpoint()}/legal-representatives/${repId}`
    const response = await fetch(endpoint, PUTwithToken(getStoredToken(), data))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299
    const badRequest = response.status === 400

    if (isSuccess) {
      dispatch({
        type: UPDATE_LEGAL_REP_SUCCESS,
        payload: responseBody.data,
      })
    }

    if (badRequest) {
      dispatch({
        type: UPDATE_LEGAL_REP_FAILED,
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }

export const getLegalRep = () => async (dispatch) => {
  const endpoint = `${getPeopleEndpoint()}/legal-representatives`
  const response = await fetch(endpoint, GET(getStoredToken()))
  const responseBody = await handleErrors(response)
  const isSuccess = response.status >= 200 && response.status <= 299
  const badRequest = response.status === 400

  if (isSuccess) {
    dispatch({
      type: GET_LEGAL_REP_SUCCESS,
      payload: responseBody.data,
    })
  }

  if (badRequest) {
    dispatch({
      type: GET_LEGAL_REP_FAILED,
    })
  }
}

export const clearContactCreationError = () => (dispatch) => {
  dispatch({
    type: CREATE_CONTACTS_CLEAR_ERROR,
    payload: '',
  })
}

export const clearContactData = () => (dispatch) => {
  dispatch({
    type: CLEAR_CONTACT_DATA,
  })
}

export const resetPlaidFlag = () => (dispatch) => {
  dispatch({
    type: RESET_PLAID_FLAG,
  })
}

// connect with Plaid to to get publicToken and verify with bankend api to get bank data
export const setPlaidPaymentInstrument = (publicToken, accountId) => async (dispatch) => {
  const endpoint = `${getPeopleEndpoint()}/payment-instruments`
  const body = {
    instrumentType: 'plaid',
    accessToken: publicToken,
    accountIds: [accountId],
  }
  dispatch({ type: SET_PLAID_SAVING })

  const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
  const responseBody = await handleErrors(response)
  const isSuccess = response.status >= 200 && response.status <= 299
  const badRequest = response.status === 400
  const serverError = response.status === 500

  if (badRequest) {
    const message = await parseMessage(responseBody)
    const isDuplicate = message?.includes('already exists')

    dispatch({
      type: SET_PLAID_FAILED,
      payload:
        isDuplicate ?
          `The account you are trying to add already exists in your list. Try
        selecting another.`
        : `Your request couldn't be processed. Please try again later or contact customer support.`,
    })
  }

  if (serverError) {
    dispatch({
      type: SET_PLAID_INTERNAL_ERROR,
      paylaod: 'There was a problem connecting to your online account. Please try again or add other payment method.',
    })
  }

  if (isSuccess) {
    dispatch({
      type: SET_PLAID_INSTRUMENT,
      payload: responseBody.data[0],
    })
  }
}

export const setBankPaymentInstrument =
  (accountNumber, routingNumber, accountType, accountHolderType, accountHolderName, force) => (dispatch) => {
    const endpoint = `${getPeopleEndpoint()}/payment-instruments?force=${force}`

    const body = {
      instrumentType: 'bankAccount',
      accountNumber: accountNumber,
      routingNumber: routingNumber,
      accountType: accountType,
      accountHolderType: accountHolderType,
      accountHolderName: accountHolderName,
    }

    return fetch(endpoint, POSTwithToken(getStoredToken(), body))
      .then(handleErrors)
      .then((response) => {
        if (response.status === 201) {
          dispatch({
            type: SET_BANK_INSTRUMENT,
            payload: response.data[0],
          })
        } else {
          parseMessage(response)
            .then((message) => {
              dispatch({
                type: SET_BANK_INSTRUMENT_ERROR,
                payload: message || 'Something has gone wrong. Please try again',
              })
            })
            .catch(() => {
              dispatch({
                type: SET_BANK_INSTRUMENT_ERROR,
                payload: 'Something has gone wrong. Please try again',
              })
            })
        }
      })
  }

export const getSpecificBankInstrument = (paymentInstrumentId) => (dispatch) => {
  const endpoint = `${getPeopleEndpoint()}/payment-instruments/${paymentInstrumentId}`

  return fetch(endpoint, GET(getStoredToken()))
    .then(handleErrors)
    .then((response) => {
      dispatch({
        type: GET_SPECIFIC_BANK_INSTRUMENT,
        payload: response.data,
      })
    })
}

export const cancelEditMode = () => (dispatch) => {
  dispatch({
    type: CANCEL_EDIT_MODE,
  })
}

export const clearPlaidData = () => (dispatch) => {
  dispatch({
    type: CLEAR_PLAID_DATA,
  })
}

export const clearBankData = () => (dispatch) => {
  dispatch({
    type: CLEAR_BANK_DATA,
  })
}

export const clearBankError = () => (dispatch) => {
  dispatch({
    type: CLEAR_BANK_ERROR,
  })
}

export const clearCardData = () => (dispatch) => {
  dispatch({
    type: CLEAR_CARD_DATA,
  })
}

export const clearCardError = () => (dispatch) => {
  dispatch({
    type: CLEAR_CARD_ERROR,
  })
}

export const updateBankPaymentInstrument =
  ({ paymentInstrumentId, accountType, accountHolderType, accountHolderName, status }) =>
  (dispatch) => {
    const endpoint = `${getPeopleEndpoint()}/payment-instruments/${paymentInstrumentId}`

    const body = {
      // nickname: 'string',
      accountType: accountType,
      accountHolderType: accountHolderType,
      accountHolderName: accountHolderName,
      status,
    }

    return fetch(endpoint, PUTwithToken(getStoredToken(), body))
      .then(handleErrors)
      .then(() => {
        dispatch({
          type: UPDATE_BANK_INSTRUMENT,
        })
      })
  }

export const updateCardPaymentInstrument =
  (paymentInstrumentId, firstName, lastName, expirationYear, expirationMonth, postalCode) => (dispatch) => {
    const endpoint = `${getPeopleEndpoint()}/payment-instruments/${paymentInstrumentId}`

    const body = {
      expirationYear: expirationYear,
      expirationMonth: expirationMonth,
      address: {
        postalCode: postalCode,
      },
      firstName: firstName,
      lastName: lastName,
    }

    return fetch(endpoint, PUTwithToken(getStoredToken(), body))
      .then(handleErrors)
      .then(() => {
        dispatch({
          type: UPDATE_CARD_INSTRUMENT,
        })
      })
  }

export const deletePaymentInstrument = (paymentInstrumentId) => (dispatch) => {
  const endpoint = `${getPeopleEndpoint()}/payment-instruments/${paymentInstrumentId}`

  return fetch(endpoint, DELETE(getStoredToken()))
    .then(handleErrors)
    .then(() => {
      dispatch({
        type: DELETE_BANK_INSTRUMENT,
      })
    })
}

export const setDebitCardPaymentInstrument =
  (cardNumber, cvv, expirationYear, expirationMonth, postalCode, firstName, lastName, force) => async (dispatch) => {
    const body = {
      instrumentType: 'card',
      cardNumber: cardNumber.split(' ').join(''),
      cvv: cvv,
      expirationYear: expirationYear,
      expirationMonth: expirationMonth,
      address: {
        postalCode: postalCode,
      },
      firstName: firstName,
      lastName: lastName,
    }

    // cardType is required when force === true
    if (force) body.cardType = 'debit'

    const endpoint = `${getPeopleEndpoint()}/payment-instruments?force=${force}`
    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
    const responseBody = await handleErrors(response)

    if (response.ok) {
      dispatch({
        type: SET_DEBIT_INSTRUMENT,
        payload: responseBody.data[0],
      })
    } else {
      const message = await parseMessage(responseBody)
      dispatch({
        type: SET_DEBIT_INSTRUMENT_ERROR,
        payload:
          response.status >= 400 && response.status <= 499 ? message : 'Something has gone wrong. Please try again',
      })
    }
  }

export const verifyRoutingNumber = (routingNumber) => (dispatch) => {
  const endpoint = `${APIendpoint}/routing-numbers/${routingNumber}`

  return fetch(endpoint, GET(getStoredToken()))
    .then(handleErrors)
    .then((response) => {
      if (response.status >= 400) {
        dispatch({
          type: VERIFY_ROUTING_NUMBER_FAILED,
          payload: {
            validRoutingNumber: false,
            errorMessage: response.message,
          },
        })
      } else {
        const hasNewRoutingNumber = routingNumber !== response.data.routingNumber
        dispatch({
          type: VERIFY_ROUTING_NUMBER_SUCCESS,
          payload: {
            validRoutingNumber: true,
            data: response.data,
            hasNewRoutingNumber: hasNewRoutingNumber,
          },
        })
      }
    })
}

export const setInitialDocumentDescriptor =
  (type, loanId = null, fileName, key) =>
  async (dispatch) => {
    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: 'loading',
      })
    }
    const body = {
      type: type,
      status: 'draft',
      loanId: loanId,
      fileName: fileName,
    }
    const endpoint = `${getPeopleEndpoint()}/documents`
    const response = await fetch(endpoint, POSTwithToken(getStoredToken(), body))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: SET_INITIAL_DOCUMENT_DESCRIPTOR,
        payload: responseBody?.data ?? responseBody,
      })
    }

    if (key) {
      dispatch({
        type: LOADING,
        key,
        status: isSuccess ? 'success' : 'error',
      })
    }
  }

export const finalizeDocumentDescriptor = (documentId) => (dispatch) => {
  const endpoint = `${getPeopleEndpoint()}/documents/${documentId}`

  const body = {
    status: 'accepted',
  }

  return fetch(endpoint, PUTwithToken(getStoredToken(), body))
    .then(handleErrors)
    .then((response) => {
      dispatch({
        type: FINALIZE_DOCUMENT_DESCRIPTOR,
        payload: response?.data ?? response,
      })
      return response?.data ?? response
    })
    .then((previous) => {
      dispatch(archiveDocument(previous.id))
      return previous
    })
    .then((re) => dispatch(convertFinalizedDocumentToPDF(re.id)))
  // then sign digitally sign new PDF document ID when ready
}

export const archiveDocument = (documentId) => (dispatch) => {
  const endpoint = `${getPeopleEndpoint()}/documents/${documentId}`

  const body = {
    archived: true,
  }

  return fetch(endpoint, PUTwithToken(getStoredToken(), body))
    .then(handleErrors)
    .then(() => {
      dispatch({
        type: 'ARCHIVED_DOCUMENT',
      })
    })
}

export const convertFinalizedDocumentToPDF = (finalizedDocumentId) => (dispatch) => {
  const endpoint = `${getPeopleEndpoint()}/documents/${finalizedDocumentId}/convert?format=pdf`

  return fetch(endpoint, POSTwithToken(getStoredToken()))
    .then(handleErrors)
    .then((response) => {
      dispatch({
        type: CONVERT_FINAL_DOCUMENT_PDF,
        payload: response.data ?? response,
      })
    })
}

const SHOWCASE_DOCUMENT_TYPES = [
  'loanTermsAgreementOnly',
  'loanTILDisclosureOnly',
  'loanTermsAndTILCombined',
  'otherStatement',
  'privacyPolicyLender',
  'privacyPolicyServicer',
  'loanAutopayAgreement',
  'loanTermsChangeAgreement',
  'termsOfServiceServicer',
  'termsOfServiceLender',
]

const types = SHOWCASE_DOCUMENT_TYPES.join(',')

export const getAllShowcaseDocuments =
  ({ loanId, key }) =>
  async (dispatch) => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }

    const endpoint = `${getPeopleEndpoint()}/documents?loanId=${loanId}&type=${types}&includeArchived=true`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      const acceptedDocuments = _.filter(responseBody?.data ?? responseBody, { status: 'accepted' })

      dispatch({ type: GET_ALL_RELATED_DOCUMENTS, payload: acceptedDocuments })
    }

    if (key) {
      dispatch({ type: LOADING, key, status: isSuccess ? 'success' : 'error' })
    }
  }
