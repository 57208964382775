import { FC } from 'react'

import { styled } from 'styled-components'

const Span = styled.span<{ outlined: boolean }>`
  font-size: inherit;
  font-variation-settings: 'FILL' ${(p) => (p.outlined ? '0' : '1')};
  user-select: none;
`

type MaterialIconRoundedProps = {
  name: string
  outlined?: boolean
}

const MaterialIconRounded: FC<MaterialIconRoundedProps> = ({ name, outlined = false }) => (
  <Span className='material-symbols-rounded' outlined={outlined}>
    {name}
  </Span>
)

export default MaterialIconRounded
