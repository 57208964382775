import { FC, useState } from 'react'

import { styled } from 'styled-components'

import Icon from 'core/components/Icon'
import Button from 'core/components/lib/Button'
import { Tippy } from 'core/components/lib/Tooltip'
import { variables } from 'core/styles'

type ClipboardCopyButtonProps = {
  children?: string
  value?: string
  copyMessage?: string
  confirmedMessage?: string
}

export const ClipboardCopyButton: FC<ClipboardCopyButtonProps> = ({
  children,
  value,
  copyMessage,
  confirmedMessage = 'Copied!',
}) => {
  const [visible, setVisible] = useState(false)
  const [clicked, setClicked] = useState(false)

  async function handleClick() {
    if (!clicked) {
      await navigator.clipboard.writeText(String(value))

      setClicked(true)
      setVisible(true)
      setTimeout(() => setVisible(false), 1000)
    }
  }

  function handleMouseEnter() {
    if (copyMessage && !clicked && !visible) {
      setVisible(true)
    }
  }

  function handleMouseLeave() {
    if (!clicked && visible) {
      setVisible(false)
    }
  }

  return (
    <Tippy
      className='tooltip'
      content={clicked ? confirmedMessage : copyMessage}
      onHidden={() => setClicked(false)}
      placement='bottom'
      theme='light'
      visible={visible}
    >
      <StyledButton
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        secondary
        type='button'
      >
        <Icon color={variables.colorBluePrimary} fontSize='26px' name='content_copy' />
        {children}
      </StyledButton>
    </Tippy>
  )
}

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`
