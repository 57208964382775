/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const caseSummarizerGeneratePromptHash: OperationData<Types.CaseSummarizerGeneratePromptHash> = {
  method: 'post',
  name: 'caseSummarizerGeneratePromptHash',
  summary: 'Generate prompt hash',
  path: '/ai/case-summarizer/prompt-hash',
  queryKey: () => ['ai', 'case-summarizer', 'prompt-hash'],
}

export const caseSummarizerResultsCreate: OperationData<Types.CaseSummarizerResultsCreate> = {
  method: 'post',
  name: 'caseSummarizerResultsCreate',
  summary: 'Start case summarizer',
  path: '/ai/case-summarizer/results',
  queryKey: () => ['ai', 'case-summarizer', 'results'],
}

export const caseSummarizerResultsGet: OperationData<Types.CaseSummarizerResultsGet> = {
  method: 'get',
  name: 'caseSummarizerResultsGet',
  summary: 'Get case summarizer result',
  path: '/ai/case-summarizer/results/{aICaseSummarizerResultId}',
  queryKey: ({ aICaseSummarizerResultId }) => ['ai', 'case-summarizer', 'results', aICaseSummarizerResultId],
}

export const caseSummarizerResultsUserFeedbackGet: OperationData<Types.CaseSummarizerResultsUserFeedbackGet> = {
  method: 'get',
  name: 'caseSummarizerResultsUserFeedbackGet',
  summary: 'Set feedback',
  path: '/ai/case-summarizer/results/{aICaseSummarizerResultId}/user-feedback',
  queryKey: ({ aICaseSummarizerResultId }) => [
    'ai',
    'case-summarizer',
    'results',
    aICaseSummarizerResultId,
    'user-feedback',
  ],
}

export const caseSummarizerResultsUserFeedbackSet: OperationData<Types.CaseSummarizerResultsUserFeedbackSet> = {
  method: 'put',
  name: 'caseSummarizerResultsUserFeedbackSet',
  summary: 'Set feedback',
  path: '/ai/case-summarizer/results/{aICaseSummarizerResultId}/user-feedback',
  queryKey: ({ aICaseSummarizerResultId }) => [
    'ai',
    'case-summarizer',
    'results',
    aICaseSummarizerResultId,
    'user-feedback',
  ],
}

export const caseSummarizerResultsUserFeedbackDelete: OperationData<Types.CaseSummarizerResultsUserFeedbackDelete> = {
  method: 'delete',
  name: 'caseSummarizerResultsUserFeedbackDelete',
  summary: 'Delete feedback',
  path: '/ai/case-summarizer/results/{aICaseSummarizerResultId}/user-feedback',
  queryKey: ({ aICaseSummarizerResultId }) => [
    'ai',
    'case-summarizer',
    'results',
    aICaseSummarizerResultId,
    'user-feedback',
  ],
}
