import { PropsWithChildren } from 'react'

import { Root } from '@radix-ui/react-radio-group'
import { styled } from 'styled-components'

import { Field } from 'core/hooks/useForm'

type RadioGroupProps<T extends string> = Field<T> &
  PropsWithChildren<{
    className?: string
    ariaLabel?: string
  }>

/** A column of `RadioItem`s. */
const RadioGroup = <T extends string>({
  className,
  ariaLabel,
  children,
  value,
  onChange,
  disabled,
  name,
}: RadioGroupProps<T>) => (
  <StyledRoot
    aria-label={ariaLabel}
    className={className}
    disabled={disabled}
    name={name}
    onValueChange={onChange}
    value={value}
  >
    {children}
  </StyledRoot>
)

export default RadioGroup

const StyledRoot = styled(Root)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
