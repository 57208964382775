import { APIendpoint, GET_LOAN_TYPES, LOADING, getStoredToken } from 'core/actions/constants'
import { GET } from 'core/api/legacyApi'
import { handleErrors } from 'core/helpers/handleErrors'

const getLoanTypes =
  ({ companyId, key }) =>
  async (dispatch) => {
    if (key) {
      dispatch({ type: LOADING, key, status: 'loading' })
    }
    const endpoint = `${APIendpoint}/companies/${companyId}/loan-types?limit=100`
    const response = await fetch(endpoint, GET(getStoredToken()))
    const responseBody = await handleErrors(response)
    const isSuccess = response.status >= 200 && response.status <= 299

    if (isSuccess) {
      dispatch({
        type: GET_LOAN_TYPES,
        payload: responseBody.data,
        companyId,
      })
    }

    if (key) {
      dispatch({ type: LOADING, key, status: isSuccess ? 'success' : 'error' })
    }
  }

export default getLoanTypes
