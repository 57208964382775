import PropTypes from 'prop-types'
import { styled } from 'styled-components'

const ErrorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
`

const LoginErrorMessage = ({ children }) => (
  <ErrorDiv>{children || 'Something went wrong. Please try refreshing the page.'}</ErrorDiv>
)

LoginErrorMessage.propTypes = {
  children: PropTypes.node,
}

export default LoginErrorMessage
