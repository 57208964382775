import { styled } from 'styled-components'

import { isLoggedIn } from 'core/actions/helpers/tokenHelpers'
import { Clickable } from 'core/components'
import { R } from 'core/helpers'
import { useAccountOptions, useBorrowerBaseUrl } from 'core/hooks'
import { Option } from 'core/hooks/useAccountOptions'

const canDisplayLink = (option?: Option): boolean => {
  const { action, href, label, disabled, hide } = option ?? {}

  return disabled !== true && hide !== true && !!label && (!!action || !!href)
}

const FooterLinks = () => {
  const baseUrl = useBorrowerBaseUrl()

  const options = useAccountOptions({ isMobile: false })

  const helpOption = R.find(options ?? [], (option) => option.icon === 'help')

  const isloggedIn = isLoggedIn()

  return (
    <Links>
      {!isloggedIn ?
        <>
          <Clickable to={`${baseUrl}/login`} variant='link'>
            Login
          </Clickable>

          {!R.isNil(helpOption) && canDisplayLink(helpOption) && (
            <Clickable href={helpOption.href} variant='link'>
              {helpOption?.label}
            </Clickable>
          )}
        </>
      : <>
          {R.pipe(
            options ?? [],
            R.filter(canDisplayLink),
            R.map((option) => (
              <Clickable
                href={option?.href}
                key={option?.label}
                to={option?.action ? `${baseUrl}${option.action}` : undefined}
                variant='link'
              >
                {option?.label}
              </Clickable>
            )),
          )}
        </>
      }
    </Links>
  )
}

export default FooterLinks

const Links = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
`
