import mtz from 'core/time/internal/utils/mtz'

/** Note that getNow doesn't need timezone from context because
 * it's just returning a string in UTC time.
 */
const makeGetNow = () => {
  const getNow = () => mtz().toISOString()

  return getNow
}

export default makeGetNow
