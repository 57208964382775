import { useState, useEffect } from 'react'

import { styled } from 'styled-components'

import { UPLOAD_FAIL, UPLOAD_SUCCESS, UPLOAD_TAKING_LONG } from 'contexts/FileUploadContext'

import Icon from 'core/components/Icon'
import Button from 'core/components/lib/Button'
import { useLoadingStatus } from 'core/hooks'
import { variables } from 'core/styles'

const Container = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 1px ${variables.colorBlack20};
  background: white;
  width: 476px;
  overflow: hidden;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 3px;
  right: 20px;
  color: rgb(0 0 0 / 56%);
  font-size: 24px;
`

const TitleAndActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`

const Title = styled.h2`
  padding: 0 16px;
  color: ${variables.colorBlack90};
  font-size: 22px;
`

const ButtonActions = styled.div`
  display: flex;
  padding-right: 24px;
`

const ProgressBarContainer = styled.div`
  position: relative;
  padding: 0 16px;
  height: 32px;
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: ${(p) => p.background};
  width: ${(p) => p.progress}%;
`

const Message = styled.div`
  position: relative;
  top: 5px;
  color: ${(p) => p.color};
  font-size: 14px;
`

const FileUploadModal = ({
  elapsedTime,
  error: requirementError,
  onCancelUpload,
  onOpenFileSelector,
  onRetryUpload,
  progress,
  setShowUploadStatusModal,
}) => {
  const [message, setMessage] = useState('')
  const [progressTextColor, setProgressTextColor] = useState(null)
  const [progressBackgroundColor, setProgressBackgroundColor] = useState(null)

  const elapsedTimeToRetry = 10 // in seconds

  // ----------------------------------
  // Loading status: Document descriptor & file upload
  const documentDescriptorsLoadingStatus = useLoadingStatus('CreateDocumentDescriptors')
  const uploadFileLoadingStatus = useLoadingStatus('UploadFiles')
  const error = documentDescriptorsLoadingStatus === 'error' || uploadFileLoadingStatus === 'error'

  // Loading state
  useEffect(() => {
    if (uploadFileLoadingStatus === 'loading') {
      setMessage(null)
      if (elapsedTime > elapsedTimeToRetry) {
        setMessage(UPLOAD_TAKING_LONG)
        setProgressTextColor(variables.colorOrange)
        setProgressBackgroundColor(variables.colorOrangeLighten)
      } else {
        setProgressTextColor(null)
        setProgressBackgroundColor(variables.colorGreenLighten)
      }
    }
  }, [uploadFileLoadingStatus, elapsedTime, elapsedTimeToRetry])

  // Success state
  useEffect(() => {
    if (uploadFileLoadingStatus === 'success') {
      setMessage(UPLOAD_SUCCESS)
      setProgressTextColor(variables.colorGreen)
      setProgressBackgroundColor(variables.colorGreenLighten)
    }
  }, [uploadFileLoadingStatus])

  // Error state
  useEffect(() => {
    if (requirementError) {
      setMessage(requirementError)
      setProgressTextColor(variables.colorRed)
      setProgressBackgroundColor(variables.colorRedLighten)
    } else if (error) {
      setMessage(UPLOAD_FAIL)
      setProgressTextColor(variables.colorRed)
      setProgressBackgroundColor(variables.colorRedLighten)
    }
  }, [error, requirementError, uploadFileLoadingStatus, documentDescriptorsLoadingStatus])

  return (
    <Container>
      <TitleAndActions>
        <Title>Uploading document</Title>
        <ButtonActions>
          {uploadFileLoadingStatus !== 'success' && (error || elapsedTime > elapsedTimeToRetry) ?
            <>
              <Button margin='0 40px 0 0' onClick={onRetryUpload} secondary>
                Retry
              </Button>
              <Button onClick={onCancelUpload} secondary>
                Cancel upload
              </Button>
            </>
          : uploadFileLoadingStatus === 'success' ?
            <Button
              data-testid='button-upload'
              onClick={() =>
                onOpenFileSelector({
                  acceptedFileTypes: '.jpg, .doc, .docx, .pdf, .txt, .png',
                  autoUpload: true,
                  allowMultiFileUpload: false,
                })
              }
              secondary
            >
              Upload another document
            </Button>
          : <Button onClick={onCancelUpload} secondary>
              Cancel upload
            </Button>
          }
        </ButtonActions>
      </TitleAndActions>
      <ProgressBarContainer>
        <ProgressBar
          background={progressBackgroundColor}
          progress={
            error ? 100
            : progress ?
              Math.floor((progress.loaded / progress.total) * 100)
            : 0
          }
        />
        <Message color={progressTextColor}>{message}</Message>
        <CloseButton data-testid='button-close' onClick={() => setShowUploadStatusModal(false)}>
          <Icon fontSize='inherit' name='clear' />
        </CloseButton>
      </ProgressBarContainer>
    </Container>
  )
}

export { Message, ProgressBar }
export default FileUploadModal
