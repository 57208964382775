import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

// ? https://www.radix-ui.com/docs/primitives/components/dropdown-menu

const DropdownMenu = DropdownMenuPrimitive.Root
export default DropdownMenu

export const DropdownMenuButton = DropdownMenuPrimitive.Trigger
export const DropdownMenuPortal = DropdownMenuPrimitive.Portal
export const DropdownMenuContent = DropdownMenuPrimitive.Content
export const DropdownMenuItem = DropdownMenuPrimitive.Item
export const DropdownMenuDivider = DropdownMenuPrimitive.Separator
