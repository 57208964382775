/* istanbul ignore file */

import { ReactNode } from 'react'

import { styled } from 'styled-components'

import { errorBannerCss, successBannerCss, warningBannerCss } from 'core/components/lib/Banner'

type MessageProps = {
  paddingLeft: string
}

const ErrorMessage = styled.div<MessageProps>`
  ${errorBannerCss};
  display: flex;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-left: ${(p) => p.paddingLeft};
`

const SuccessMessage = styled.div<MessageProps>`
  ${successBannerCss};
  display: flex;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-left: ${(p) => p.paddingLeft};
`

const WarningMessage = styled.div<MessageProps>`
  ${warningBannerCss};
  display: flex;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-left: ${(p) => p.paddingLeft};
`

type CardSectionMessageProps = {
  children: ReactNode
  paddingLeft?: string
  type: 'error' | 'success' | 'warning'
}

export default function CardSectionMessage({ children, paddingLeft = '16px', type }: CardSectionMessageProps) {
  switch (type) {
    case 'error':
      return <ErrorMessage paddingLeft={paddingLeft}>{children}</ErrorMessage>
    case 'success':
      return <SuccessMessage paddingLeft={paddingLeft}>{children}</SuccessMessage>
    default:
      return <WarningMessage paddingLeft={paddingLeft}>{children}</WarningMessage>
  }
}
