import { useRef, useMemo, useEffect } from 'react'

const useStableCallback = (func) => {
  const funcRef = useRef(func)

  const stableFunc = useMemo(
    () =>
      (...args) =>
        funcRef.current?.(...args),
    [],
  )

  useEffect(() => {
    funcRef.current = func
    return () => {
      funcRef.current = undefined
    }
  }, [func])

  return stableFunc
}

export default useStableCallback
