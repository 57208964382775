import { useState, useEffect, useCallback, useMemo } from 'react'

import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

import RouteLeaveGuard from 'core/components/RouteLeaveGuard'

import { DraftContext } from './useDraft'

const GENERIC_ALERT_CRM = {
  title: 'Are you sure you want to exit this case?',
  message: 'You have multiple edits that will be lost, if you do.',
}

const GENERIC_ALERT_BORROWER_PORTAL = {
  title: '',
  message: '',
}

const DraftContextProvider = ({ children }) => {
  const [drafts, setDrafts] = useState({})
  const [alert, setAlert] = useState({})
  const [ignoreAppRouteChange, setIgnoreAppRouteChange] = useState(false)
  const history = useHistory()
  const location = useLocation()

  // Add draft ------------------------
  const addDraft = useCallback((name, value) => {
    setDrafts((prevState) => {
      const newDrafts = { ...prevState, [name]: value }
      return newDrafts
    })
  }, [])

  // Remove draft ---------------------
  const removeDraft = useCallback((name) => {
    setDrafts((prevState) => {
      const newDrafts = prevState
      return delete newDrafts[name]
    })
  }, [])

  // Clear draft ----------------------
  const clearDrafts = useCallback(() => setDrafts({}), [])

  // Set alert message ----------------
  useEffect(() => {
    if (isEmpty(drafts)) {
      setAlert({})
    } else if (Object.keys(drafts).length === 1 && Object.values(drafts)[0].ignoreAppRouteChange !== true) {
      setAlert(Object.entries(drafts)[0][1])
    } else {
      // Show a CRM specific generic alert message
      if (location.pathname.includes('crm')) {
        setAlert(GENERIC_ALERT_CRM)
      } else {
        // Show a borrower portal specific generic alert message
        setAlert(GENERIC_ALERT_BORROWER_PORTAL)
      }
    }
  }, [drafts, location.pathname])

  // Set ignore react route change ----
  useEffect(() => {
    let hasReactRouteChangeDraft = true
    if (!isEmpty(drafts)) {
      hasReactRouteChangeDraft = Object.values(drafts).some((draft) => draft.ignoreAppRouteChange !== true)
    }

    if (hasReactRouteChangeDraft) {
      setIgnoreAppRouteChange(false)
    } else {
      setIgnoreAppRouteChange(true)
    }
  }, [drafts])

  window.onbeforeunload = (e) => {
    if (!isEmpty(drafts)) {
      e.preventDefault()
      e.returnValue = ''
    }
  }
  const draftValue = useMemo(
    () => ({ addDraft, removeDraft, clearDrafts, alert }),
    [addDraft, removeDraft, clearDrafts, alert],
  )

  return (
    <DraftContext.Provider value={draftValue}>
      <RouteLeaveGuard navigate={(path) => history.push(path)} when={!isEmpty(drafts) && !ignoreAppRouteChange} />
      {children}
    </DraftContext.Provider>
  )
}

DraftContextProvider.propTypes = {
  children: PropTypes.node,
}

export default DraftContextProvider

export { default as useDraft } from './useDraft'
