import { useEffect } from 'react'

/** @deprecated use `react-use/useMount` instead */
const useOnMount = (fn) => {
  useEffect(() => {
    fn()
  }, []) // eslint-disable-line
}

export default useOnMount
