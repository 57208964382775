/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import type { OperationData } from 'core/types/makeMutationHook'

import * as Types from './types'

export const internalListContacts: OperationData<Types.InternalListContacts> = {
  method: 'get',
  name: 'internalListContacts',
  summary: 'List contacts',
  path: '/companies/{companyId}/contacts',
  queryKey: ({ companyId }) => ['companies', companyId, 'contacts'],
}

export const internalListContactsForBorrower: OperationData<Types.InternalListContactsForBorrower> = {
  method: 'get',
  name: 'internalListContactsForBorrower',
  summary: 'List contacts of borrower',
  path: '/companies/{companyId}/people/{personId}/contacts',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'contacts'],
}

export const internalCreateContactForBorrower: OperationData<Types.InternalCreateContactForBorrower> = {
  method: 'post',
  name: 'internalCreateContactForBorrower',
  summary: 'Create contact for borrower',
  path: '/companies/{companyId}/people/{personId}/contacts',
  queryKey: ({ companyId, personId }) => ['companies', companyId, 'people', personId, 'contacts'],
}

export const internalGetContactForBorrower: OperationData<Types.InternalGetContactForBorrower> = {
  method: 'get',
  name: 'internalGetContactForBorrower',
  summary: 'Get contact of borrower',
  path: '/companies/{companyId}/people/{personId}/contacts/{contactId}',
  queryKey: ({ companyId, personId, contactId }) => ['companies', companyId, 'people', personId, 'contacts', contactId],
}

export const internalUpdateContactForBorrower: OperationData<Types.InternalUpdateContactForBorrower> = {
  method: 'put',
  name: 'internalUpdateContactForBorrower',
  summary: 'Update contact',
  path: '/companies/{companyId}/people/{personId}/contacts/{contactId}',
  queryKey: ({ companyId, personId, contactId }) => ['companies', companyId, 'people', personId, 'contacts', contactId],
}

export const internalDeleteContact: OperationData<Types.InternalDeleteContact> = {
  method: 'delete',
  name: 'internalDeleteContact',
  summary: 'Delete contact',
  path: '/companies/{companyId}/people/{personId}/contacts/{contactId}',
  queryKey: ({ companyId, personId, contactId }) => ['companies', companyId, 'people', personId, 'contacts', contactId],
}

export const internalCloneContact: OperationData<Types.InternalCloneContact> = {
  method: 'post',
  name: 'internalCloneContact',
  summary: 'Clone contact',
  path: '/companies/{companyId}/people/{personId}/contacts/{contactId}/clone',
  queryKey: ({ companyId, personId, contactId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'contacts',
    contactId,
    'clone',
  ],
}

export const listContactsForBorrower: OperationData<Types.ListContactsForBorrower> = {
  method: 'get',
  name: 'listContactsForBorrower',
  summary: 'List contact for borrower',
  path: '/people/{personId}/contacts',
  queryKey: ({ personId }) => ['people', personId, 'contacts'],
}

export const createContactForBorrower: OperationData<Types.CreateContactForBorrower> = {
  method: 'post',
  name: 'createContactForBorrower',
  summary: 'Create contact',
  path: '/people/{personId}/contacts',
  queryKey: ({ personId }) => ['people', personId, 'contacts'],
}

export const getContactForBorrower: OperationData<Types.GetContactForBorrower> = {
  method: 'get',
  name: 'getContactForBorrower',
  summary: 'Get contact by ID',
  path: '/people/{personId}/contacts/{contactId}',
  queryKey: ({ personId, contactId }) => ['people', personId, 'contacts', contactId],
}

export const updateContactForBorrower: OperationData<Types.UpdateContactForBorrower> = {
  method: 'put',
  name: 'updateContactForBorrower',
  summary: 'Update contact',
  path: '/people/{personId}/contacts/{contactId}',
  queryKey: ({ personId, contactId }) => ['people', personId, 'contacts', contactId],
}

export const deleteContact: OperationData<Types.DeleteContact> = {
  method: 'delete',
  name: 'deleteContact',
  summary: 'Delete contact',
  path: '/people/{personId}/contacts/{contactId}',
  queryKey: ({ personId, contactId }) => ['people', personId, 'contacts', contactId],
}

export const cloneContact: OperationData<Types.CloneContact> = {
  method: 'post',
  name: 'cloneContact',
  summary: 'Clone contact',
  path: '/people/{personId}/contacts/{contactId}/clone',
  queryKey: ({ personId, contactId }) => ['people', personId, 'contacts', contactId, 'clone'],
}
