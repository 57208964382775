import { FC } from 'react'

import { styled } from 'styled-components'

import { FallbackProps } from 'core/components/lib/ErrorBoundary/defaultErrorBoundaries'

export const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

const MaintenanceFallback: FC<FallbackProps> = ({ className }) => (
  <ErrorBox className={className}>This site is briefly undergoing maintenance. We appreciate your patience.</ErrorBox>
)

export default MaintenanceFallback
