/** This can take any isThing function that returns true if the one arg
 * passed in passes some kind of test, and create a robust, custom prop
 * type out of it, including with the .isRequired syntax. Make sure
 * `isThing` is a function that takes `allowNull` as a second arg, defaulting
 * to true, and if true it means that `null` or `undefined` is okay.
 * Note that this function is a copy of the same in our set of helper functions
 * for the app, outside of the time library. We don't want to be importing
 * it here because we don't want the time library to have dependencies to the
 * rest of our codebase.
 */
const makePropType = (isThing, error) => {
  const corePropType = (props, propName, componentName, allowNull) => {
    const thing = props[propName]
    if (!isThing(thing, allowNull)) {
      return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. ${error}`)
    }
  }

  const nullablePropType = (props, propName, componentName) => corePropType(props, propName, componentName, true)
  nullablePropType.isRequired = (props, propName, componentName) => corePropType(props, propName, componentName, false)

  return nullablePropType
}

/**************************************
 * datestring checkers and prop type
 *************************************/

const datestringError =
  'Must be a string in iso format without a timestamp, assuming the timezone established by the context passed in.'

/** Use this if you want to check without throwing an error if false. */
export const isDatestring = (thing, allowNull = true) => {
  if (allowNull && (thing === null || thing === undefined)) {
    return true
  }
  return typeof thing === 'string' && /\d{4}-\d{2}-\d{2}/.test(thing)
}

/** The datestring prop type. Usage:
 * MyComponent.propTypes = {
 *   startDate: datestring.isRequired,
 *   endDate: datestring
 * }
 */
export const datestring = makePropType(isDatestring, datestringError)

/**************************************
 * timestring checkers and prop type
 *************************************/

const timestringError = 'Must be a string in iso format with a timestamp and explicitly in UTC.'

/** Use this if you want to check without throwing an error if false. */
export const isTimestring = (thing, allowNull = true) => {
  if (allowNull && (thing === null || thing === undefined)) {
    return true
  }
  return (
    typeof thing === 'string' &&
    /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.[0-9]+)?([zZ]|[+-]00:00)/.test(thing)
  )
}

/** Use this if you want to throw an error if it doesn't check out. */
export const checkTimestring = (thing, allowNull = true) => {
  if (!isTimestring(thing, allowNull)) {
    throw new Error(timestringError)
  }
}

/** The timestring prop type. Usage:
 * MyComponent.propTypes = {
 *   startedAt: timestring.isRequired,
 *   endedAt: timestring
 * }
 */
export const timestring = makePropType(isTimestring, timestringError)

/**************************************
 * timeordate checkers and prop type
 *************************************/

const timeordateError =
  'Must be an iso string, either without timestamp (timezone implied by context) or with timestamp explicitly in UTC.'

/** Use this if you want to check without throwing an error if false. */
export const isTimeordate = (thing, allowNull = true) =>
  isTimestring(thing, allowNull) || isDatestring(thing, allowNull)

/** Use this if you want to throw an error if it doesn't check out. */
export const checkTimeordate = (thing, allowNull = true) => {
  if (!isTimeordate(thing, allowNull)) {
    throw new Error(timeordateError)
  }
}

/** The timeordate prop type. Usage:
 * MyComponent.propTypes = {
 *   startedAt: timeordate.isRequired,
 *   endedAt: timeordate
 * }
 */

export const timeordate = makePropType(isTimeordate, timeordateError)

const TimePropTypes = { timestring, datestring, timeordate }

export { TimePropTypes }
