const _defaultComparator = (a, b) => {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

const insertSorted = ({ item, list = [], comparator = _defaultComparator, allowDuplicates = false }) => {
  const index = list.findIndex((listItem) => comparator(listItem, item) >= 0)
  if (index === -1) {
    return [...list, item]
  } else if (!allowDuplicates && comparator(list[index], item) === 0) {
    return [...list]
  } else {
    return [...list.slice(0, index), item, ...list.slice(index)]
  }
}

export default insertSorted
