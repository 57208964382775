import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import type { AppState } from 'core/reducers'

type TConversationStatus = 'connected' | 'closed'

type TConversation = {
  id: string
  interactionId: string
  status: TConversationStatus
}

const conversationsAdapter = createEntityAdapter<TConversation>()

const { actions, reducer } = createSlice({
  name: 'conversations',
  initialState: conversationsAdapter.getInitialState(),
  reducers: {
    conversationAdded: conversationsAdapter.upsertOne,
    conversationUpdated: conversationsAdapter.updateOne,
  },
})

export default reducer
export const { conversationAdded, conversationUpdated } = actions

const { selectById, selectAll: selectAllConversations } = conversationsAdapter.getSelectors<AppState>(
  (state) => state.crm.conversations,
)

const selectConversationById = selectById as (state: AppState, id: TConversation['id']) => TConversation | undefined

export { selectAllConversations, selectConversationById }
