import { getStoredToken, renewSession, isTokenValid } from 'core/actions/helpers/tokenHelpers'
import { useOnMount } from 'core/hooks'

import useOnActivity from './useOnActivity'

const useActivityRenewal = () => {
  const onActivity = () => {
    const token = getStoredToken()

    if (isTokenValid(token)) void renewSession()
  }

  // renew onces when the page loads
  useOnMount(() => onActivity())

  // renew every 5 minutes if there is activity
  useOnActivity(onActivity, { delayInMinutes: 5 })
}

export default useActivityRenewal
