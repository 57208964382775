import { useState, createContext, useContext, useEffect } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'

const defaultTitle = {
  title: '🍑 Peach',
  backUrl: '/',
  url: '/',
}

const MobileTitleContext = createContext(defaultTitle)

const MobileTitleInternalContext = createContext(() => {})

const MobileTitleProvider = ({ children }) => {
  const [titles, setTitles] = useState([])

  const sortedTitles = _.sortBy(titles, ({ url }) => _.size((url || '').split('/'))).reverse()

  const titleInfo = _.first(sortedTitles) || defaultTitle

  return (
    <MobileTitleContext.Provider value={titleInfo}>
      <MobileTitleInternalContext.Provider value={setTitles}>{children}</MobileTitleInternalContext.Provider>
    </MobileTitleContext.Provider>
  )
}

MobileTitleProvider.propTypes = {
  children: PropTypes.node,
}

const useSetMobileTitle = ({ title, url, backUrl } = {}) => {
  const setTitles = useContext(MobileTitleInternalContext)
  const [id] = useState(() => _.uniqueId())
  useEffect(() => {
    setTitles((titles) => {
      const titleObj = { id, title, url, backUrl }
      return _.reject(titles, { id }).concat(titleObj)
    })
    return () => setTitles((titles) => _.reject(titles, { id }))
  }, [id, setTitles, title, url, backUrl])
}

const useMobileTitle = () => useContext(MobileTitleContext)

export { MobileTitleProvider, useSetMobileTitle, useMobileTitle }
