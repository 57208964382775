import { Fragment, JSX } from 'react'

import { styled } from 'styled-components'

import { variables } from 'core/styles'

import Link from 'admin/components/Link' // TODO why is there an admin component in core?

import { TCrumb } from './useBreadcrumbs'

const Container = styled.div<{ margin?: string }>`
  margin: ${(p) => (p.margin ? p.margin : '0')};
`

const DisabledCrumb = styled.span`
  color: ${variables.colorBlack100};
  font-size: 16px;
  font-weight: 400;
`
const Slash = styled.span`
  margin: 0 8px;
`

type TProps = {
  crumbs: Array<TCrumb>
  margin?: string
}
const Breadcrumbs = ({ crumbs, margin }: TProps): JSX.Element | null => (
  <Container aria-label='Breadcrumb' margin={margin}>
    {crumbs && crumbs.length > 1 ?
      crumbs.map((crumb) => {
        if (crumb.disabled) {
          return (
            <DisabledCrumb aria-current='page' key={crumb.pathname}>
              {crumb.name}
            </DisabledCrumb>
          )
        } else {
          return (
            <Fragment key={crumb.pathname}>
              <Link to={crumb.pathname}>{crumb.name}</Link>
              <Slash>/</Slash>
            </Fragment>
          )
        }
      })
    : <span data-testid='empty-breadcrumbs'>&nbsp;</span>}
  </Container>
)

export default Breadcrumbs
