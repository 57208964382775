import { FC, PropsWithChildren, ReactChild, useState } from 'react'

import * as ToastPrimitive from '@radix-ui/react-toast'
import { nanoid } from '@reduxjs/toolkit'
import { styled } from 'styled-components'

import { R } from 'core/helpers'
import { ToastContext } from 'core/hooks/useToast'

import Toast, { ToastData } from './Toast'

const ToastProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [toasts, setToasts] = useState<Array<ToastData>>([])

  const addToast = (message: ReactChild, type: ToastData['type']) =>
    setToasts([...toasts, { id: nanoid(), message, type }])

  const removeToast = (id: string) => setToasts(R.reject(toasts, (t) => t.id === id))

  return (
    <ToastContext.Provider
      value={{
        error: (message: ReactChild) => addToast(message, 'error'),
        warning: (message: ReactChild) => addToast(message, 'warning'),
        success: (message: ReactChild) => addToast(message, 'success'),
      }}
    >
      {children}

      <ToastPrimitive.Provider duration={7000}>
        {R.map(toasts, (toast) => (
          <Toast key={toast.id} {...toast} onOpenChange={(isOpen) => !isOpen && removeToast(toast.id)} />
        ))}

        <ToastPrimitive.Viewport asChild>
          <StyledToaster />
        </ToastPrimitive.Viewport>
      </ToastPrimitive.Provider>
    </ToastContext.Provider>
  )
}

export default ToastProvider

const StyledToaster = styled.ol`
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 20;
  margin: 0;
  padding: 0;
  list-style: none;
`
