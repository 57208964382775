const white = '#FFFFFF'

const grey50 = '#FAFAFA'
const grey100 = '#F2F2F2'
const grey200 = '#E3E3E3'
// const grey300 = '#D3D3D3'
const grey400 = '#BDBDBD'
// const grey500 = '#88888B'
// const grey600 = '#6F6F72'
const grey700 = '#565659'
// const grey800 = '#3E3E40'
const grey900 = '#252528'

// const blue50 = '#DFF2FF'
const blue100 = '#B2D6FF'
// const blue200 = '#84BBFA'
// const blue300 = '#55A0F5'
const blue400 = '#2885F1'
// const blue500 = '#0E6BD7'
const blue800 = '#00244C'

const green100 = '#BAF3E0'
const green500 = '#28BD8C'
const green800 = '#00402E'

const red100 = '#FFB5BD'
const red400 = '#F1273C'
const red800 = '#4B0008'

const orange100 = '#FFCDB0'
const orange400 = '#FD6E1D'
const orange800 = '#4E1B00'

export const yellow400 = '#FECC1F'

// Aliases
export const primary = blue400
export const onPrimary = white
export const primaryContainer = blue100
export const onPrimaryContainer = blue800

export const success = green500
export const onSuccess = white
export const successContainer = green100
export const onSuccessContainer = green800

export const error = red400
export const onError = white
export const errorContainer = red100
export const onErrorContainer = red800

export const warning = orange400
export const warningContainer = orange100
export const onWarningContainer = orange800

export const outline = grey200

export const surface = grey50
export const onSurface = grey900
export const onSurfaceVariant = grey700
export const surfaceDisabled = grey100
export const surfaceDim = grey100
export const onSurfaceDisabled = grey400
export const surfaceBright = white
export const surfaceBrightHover = grey100
export const surfaceBrightPressed = grey200
