import { FC, PropsWithChildren } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ShouldForwardProp, StyleSheetManager, ThemeProvider } from 'styled-components'

import store from 'core/reducers'
import { useBootstrapStore } from 'core/stores'
import { variables } from 'core/styles'
import { CompanyConfigBrandAssets } from 'core/types'

const isValidColor = (color: string | undefined) => {
  if (!color) return false

  const style = new Option().style
  style.color = color
  return !!style.color
}

// styled-components v6 forwards all props by default. This resores the default behaviour of v5. Moving forward we
// should use transient props (see https://styled-components.com/docs/api#transient-props)
const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) =>
  typeof target !== 'string' || isPropValid(propName)

const CommonProviders: FC<PropsWithChildren<{}>> = ({ children }) => {
  const theme = useBootstrapStore((data) => {
    // handle previous deeply nested config setup.
    // can remove once that is fully not in use
    const { primary, secondary, tertiary, lighten } =
      ((data?.company?.brandAssets?.colors as any)?.theme as CompanyConfigBrandAssets['colors']) ??
      data?.company?.brandAssets?.colors ??
      {}

    return {
      primary: isValidColor(primary) ? primary : variables.colorBluePrimary,
      secondary: isValidColor(secondary) ? secondary : variables.colorBlueSecondary,
      tertiary: isValidColor(tertiary) ? tertiary : variables.colorBlueTertiary,
      lighten: isValidColor(lighten) ? lighten : variables.colorBlueLighten,
    }
  })

  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StyleSheetManager>
      </ReduxProvider>
    </BrowserRouter>
  )
}

export default CommonProviders
