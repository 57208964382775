import { FC, useEffect, useState } from 'react'

import { EventBus } from 'pdfjs-dist/web/pdf_viewer.mjs'
import { styled } from 'styled-components'

import IconButton from 'core/components/lib/IconButton'
import { Popover, PopoverAnchor, PopoverContent, PopoverTrigger } from 'core/components/lib/Popover'
import TextInput from 'core/components/lib/TextInput'
import { variables } from 'core/styles'

type FindPopoverProps = {
  eventBus: EventBus
}

const FindPopover: FC<FindPopoverProps> = ({ eventBus }) => {
  const [isOpen, onOpenChange] = useState(false)
  const [state, setState] = useState({
    query: '',
    caseSensitive: false,
    entireWord: false,
    highlightAll: false,
    matchDiacritics: false,
    findPrevious: false,
  })
  const [matchesCount, setMatchesCount] = useState<{ current: number; total: number } | null>(null)

  useEffect(() => {
    eventBus.on('updatefindmatchescount', (data) => setMatchesCount(data.matchesCount))
    eventBus.on('updatefindcontrolstate', (data) => setMatchesCount(data.matchesCount))
  }, [eventBus])

  return (
    <Popover onOpenChange={onOpenChange} open={isOpen}>
      <PopoverAnchor asChild>
        <PopoverTrigger asChild>
          <IconButton checked={isOpen} color='inherit' fontSize='18px' name='search' />
        </PopoverTrigger>
      </PopoverAnchor>
      <StyledPopoverContent align='start' sideOffset={16}>
        <FindForm
          onSubmit={(e) => {
            e.preventDefault()
            eventBus.dispatch('find', { type: 'again', ...state })
          }}
        >
          <TextInput
            onChange={(query) => setState((find) => ({ ...find, query }))}
            postfix={
              !matchesCount ? null
              : matchesCount.total === 0 ?
                'No matches'
              : `${matchesCount.current}/${matchesCount.total}`
            }
            value={state.query}
            width='100%'
          />

          <IconButton
            checked={state.caseSensitive}
            color='inherit'
            fontSize='18px'
            name='match_case'
            onClick={() =>
              setState((oldState) => {
                const state = { ...oldState, caseSensitive: !oldState.caseSensitive }
                eventBus.dispatch('find', { type: 'casesensitivitychange', ...state })

                return state
              })
            }
          />
          <IconButton
            checked={state.entireWord}
            color='inherit'
            fontSize='18px'
            name='match_word'
            onClick={() =>
              setState((oldState) => {
                const state = { ...oldState, entireWord: !oldState.entireWord }
                eventBus.dispatch('find', { type: 'entirewordchange', ...state })

                return state
              })
            }
          />
          <IconButton
            checked={state.highlightAll}
            color='inherit'
            fontSize='16px'
            name='format_ink_highlighter'
            onClick={() =>
              setState((oldState) => {
                const state = { ...oldState, highlightAll: !oldState.highlightAll }
                eventBus.dispatch('find', { type: 'highlightallchange', ...state })

                return state
              })
            }
          />
          <IconButton
            color='inherit'
            fontSize='18px'
            name='keyboard_arrow_left'
            onClick={() => eventBus.dispatch('find', { type: 'again', ...state, findPrevious: true })}
          />
          <IconButton color='inherit' fontSize='18px' name='keyboard_arrow_right' type='submit' />
        </FindForm>
      </StyledPopoverContent>
    </Popover>
  )
}

export default FindPopover

const StyledPopoverContent = styled(PopoverContent)`
  background-color: ${variables.colorBlack80};
`

const FindForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: start;
  width: 220px;
`
