import { useEffect, useState, useRef } from 'react'

import PropTypes from 'prop-types'
import { styled } from 'styled-components'

import { useOnMount, usePrevious } from 'core/hooks'
import variables from 'core/styles/variables'

import CodeSentStatus from './CodeSentStatus'

const CodeInput = styled.input`
  margin-top: 40px;
  border: none;
  background-color: transparent;
  padding: 4px;
  width: 200px;
  text-align: center;
  line-height: 1;
  color: ${variables.colorBlack60};
  font-family: 'Roboto Mono', monospace;
  font-size: 28px;

  /* Hide Arrows From Input Number */

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:disabled {
    background-color: ${variables.colorBlack10};
    color: ${variables.colorBlack40};
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
  }
`
const Subtitle = styled.div`
  margin-top: 64px;
  text-align: center;
  color: ${variables.colorBlack90};
  font-size: 14px;
`
const Text = styled.div`
  margin-top: ${(p) => p.marginTop};
  margin-bottom: ${(p) => p.marginBottom};
  color: ${variables.colorBlack50};
  font-size: 12px;
  font-weight: 400;
`

const Actions = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

const Action = styled.button`
  margin-left: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 4px 8px;
  color: ${variables.colorBluePrimary};
  font-size: 14px;
  font-weight: 500;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`

const OneTimeCodeForm = (props) => {
  const {
    onOneTimeCode,
    sending,
    subtitle,
    onTextMe,
    onEmailMe,
    onCallMe,
    sentMessage,
    onTryAnotherWay,
    tryAnotherWayLabel,
  } = props

  const [isMobile] = useState(() => 'ontouchstart' in window)

  const [code, setCode] = useState('')

  const previousCode = usePrevious(code)

  useEffect(() => {
    if (previousCode !== code && code.length === 6 && !sending) {
      onOneTimeCode(code)
    }
  }, [code, previousCode, sending, onOneTimeCode])

  const inputRef = useRef()

  useOnMount(() => {
    inputRef.current.focus()
  })

  const handleChange = (val = '') => {
    // strip non-alphanumeric characters and trim to 6 places
    setCode(val.replace(/[^0-9]/gi, '').slice(0, 6))
  }

  const handleTextMe = () => {
    setCode('')
    if (onTextMe) onTextMe()
  }

  const handleCallme = () => {
    setCode('')
    if (onCallMe) onCallMe()
  }

  const handleEmailMe = () => {
    setCode('')
    if (onEmailMe) onEmailMe()
  }

  return (
    <>
      <Subtitle>{subtitle}</Subtitle>
      <CodeInput
        disabled={sending}
        inputmode='numeric'
        onChange={(event) => handleChange(event.target.value)}
        pattern='[0-9]*'
        placeholder='000000'
        ref={inputRef}
        type={isMobile ? 'number' : 'text'}
        value={code}
      />
      <Text marginTop='80px'>Didn't get the code?</Text>
      <Actions>
        {onTextMe && <Action onClick={handleTextMe}>Text me</Action>}
        {onCallMe && <Action onClick={handleCallme}>Call me</Action>}
        {onEmailMe && <Action onClick={handleEmailMe}>Email me</Action>}
      </Actions>

      <CodeSentStatus label={sentMessage} sending={sending} />

      {!!(onTryAnotherWay && tryAnotherWayLabel) && (
        <Actions>
          <Action onClick={onTryAnotherWay}>{tryAnotherWayLabel}</Action>
        </Actions>
      )}
    </>
  )
}

OneTimeCodeForm.propTypes = {
  onOneTimeCode: PropTypes.func,
  subtitle: PropTypes.string,
  sending: PropTypes.bool,
  onTextMe: PropTypes.func,
  onEmailMe: PropTypes.func,
  onCallMe: PropTypes.func,
  onTryAnotherWay: PropTypes.func,
  tryAnotherWayLabel: PropTypes.node,
  sentMessage: PropTypes.node,
}

export default OneTimeCodeForm
