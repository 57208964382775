const prettyMap = {
  PST: 'Pacific Time',
  PDT: 'Pacific Time',
  MST: 'Mountain Time',
  MDT: 'Mountain Time',
  CST: 'Central Time',
  CDT: 'Central Time',
  EST: 'Eastern Time',
  EDT: 'Eastern Time',
  'US/Central': 'Central Time',
  'US/Mountain': 'Mountain Time',
  'US/Eastern': 'Eastern Time',
  'US/Pacific': 'Pacific Time',
} as { [index: string]: string }

export { prettyMap }
